import { TimelineProvider } from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/TimelineProvider";
import CampaignWrapper from "../CampaignWrapper/CampaignWrapper";
import styles from "./Campaign.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "src/store/store";
import {
  fetchCustomerData,
  setCustomerId,
} from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import CampaignSelect from "../CampaignSelect/CampaignSelect";
import CernThread from "src/routes/Ticket/children/MainChat/children/CernThread/CernThread";
import { useCampaignData } from "../hooks/useCampaignData";
import { flushSync } from "react-dom";
import useDebounce from "src/hooks/useDebounce";
import CampaignTimeline from "../CampaignTimeline/CampaignTimeline";

const Campaign = () => {
  const [showTimeline, setShowTimeline] = useState(true);
  const dispatch = useAppDispatch();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const { data: campaignData, isLoading, error } = useCampaignData();

  const [isInitialScroll, setIsInitialScroll] = useState(true);
  const isInitialScrollDebounce = useDebounce(isInitialScroll, 0); // Debouncing the search term
  const [scrollEnded, setScrollEnded] = useState(false);

  const handleResetScroll = useCallback(() => {
    if (scrollDivRef.current) {
      flushSync(() => {
        setIsInitialScroll(true);
      });
      const scrollAmount = scrollDivRef.current.clientWidth;
      scrollDivRef.current.scrollLeft = scrollAmount; // Scroll right 100% of width
      setTimeout(() => {
        if (scrollDivRef.current) {
          scrollDivRef.current.style.scrollBehavior = "smooth";
          scrollDivRef.current.scrollLeft = 0;
        }
      }, 0);
    }
  }, []);

  const handleScrollLeft = useCallback(() => {
    if (scrollDivRef.current) {
      let scrollAmount = scrollDivRef.current.clientWidth / 3;
      if (scrollDivRef.current.scrollLeft <= 10) {
        handleResetScroll();
      } else {
        scrollDivRef.current.style.scrollBehavior = "smooth";
        scrollDivRef.current.scrollLeft -= scrollAmount;
      }
    }
  }, [handleResetScroll]);

  const handleScrollRight = useCallback(() => {
    if (scrollDivRef.current) {
      if (isInitialScroll) {
        scrollDivRef.current.style.scrollBehavior = "smooth";
        const scrollAmount = scrollDivRef.current.clientWidth / 3;
        scrollDivRef.current.scrollLeft += scrollAmount; // Scroll right by 1/3 of width
        setIsInitialScroll(false);
      } else {
        scrollDivRef.current.style.scrollBehavior = "smooth";
        const scrollAmount = scrollDivRef.current.clientWidth / 3;
        scrollDivRef.current.scrollLeft += scrollAmount; // Scroll right by 1/3 of width
      }
    }
  }, [isInitialScroll]);

  const hideFirstHeader = useMemo(() => {
    if (!isInitialScrollDebounce && !isInitialScroll) {
      if (scrollDivRef.current) {
        scrollDivRef.current.style.scrollBehavior = "unset";
        scrollDivRef.current.scrollLeft = 0;
      }
      return true;
    }
    return false;
  }, [isInitialScrollDebounce, isInitialScroll]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollDivRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollDivRef.current;
        if (scrollLeft + clientWidth >= scrollWidth) {
          setScrollEnded(true);
        } else {
          setScrollEnded(false);
        }
      }
    };

    const currentScrollDiv = scrollDivRef.current;
    if (currentScrollDiv) {
      currentScrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentScrollDiv) {
        currentScrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // useEffect(() => {
  //   dispatch(setCustomerId({ id: 1 }));
  //   dispatch(
  //     fetchCustomerData({
  //       includeTimeline: true,
  //     }),
  //   );
  // }, [dispatch]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading campaign data</div>;

  return (
    // <Provider value={[state, dispatch]}>
    <div className={`d-flex ${styles.campaignWrapper}`}>
      <div className={`${styles.leftSection} position-relative`}>
        <TimelineProvider
          referenceId={1}
          referenceType="customer"
        >
          <div className="d-flex align-items-center px-3">
            <CampaignSelect />
            <div
              className={`d-flex align-items-center`}
              style={{
                width: "calc(100% - 117px)",
                marginTop: "10px",
              }}
            >
              <div
                className={`mx-2 ${styles.iconBox} ${
                  hideFirstHeader ? "cursor-pointer" : styles.btnDisabled
                }`}
                onClick={() => {
                  if (hideFirstHeader) {
                    handleScrollLeft();
                  }
                }}
              >
                <i className={`fa-solid fa-chevron-left`}></i>
              </div>
              <div
                ref={scrollDivRef}
                className={`${styles.headerContent}`}
              >
                <CampaignTimeline
                  showTimeline={showTimeline}
                  timelineRef={timelineRef}
                />
              </div>
              <div className="mx-4">
                <span
                  className={`cursor-pointer ms-2 ${styles.toggleBtn}`}
                  onClick={() => setShowTimeline(!showTimeline)}
                >
                  <i
                    className={`fa-solid fa-chevron-${
                      showTimeline ? "up" : "down"
                    }`}
                  ></i>
                </span>
              </div>
              <div
                className={`mx-2 ${styles.iconBox} ${
                  scrollEnded ? styles.btnDisabled : "cursor-pointer"
                }`}
                onClick={handleScrollRight}
              >
                <i className={`fa-solid fa-chevron-right`}></i>
              </div>
            </div>
          </div>
        </TimelineProvider>
        {/* Header */}
        <div className="d-flex align-items-center">{/* Heading */}</div>
        {campaignData && (
          <CampaignWrapper
            id={campaignData.id}
            name={campaignData.name}
            campaignMessages={campaignData.campaignMessages}
          />
        )}
      </div>

      <div className={`${styles.cernThread}`}>
        {campaignData && (
          <CernThread
            isCampaign={true}
            ticketId={campaignData.id + ""}
          />
        )}
      </div>
    </div>
  );
};

export default Campaign;
