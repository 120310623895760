import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "src/store/store";
import {
  TimelineActionTypes,
  useTimeline,
} from "../TimelineInfo/TimelineProvider/TimelineProvider";
import TimelineInfo from "../TimelineInfo/TimelineInfo";

const CustomerTimeline = ({
  showTimeline,
  timelineRef,
}: {
  showTimeline: boolean;
  timelineRef?: React.RefObject<HTMLDivElement>;
}) => {
  const { timelineInfo, id } = useAppSelector(
    (state) => state.shopifySidebar.customerData,
  );
  const customerDataAJAXStatus = useAppSelector(
    (state) => state.shopifySidebar.customerDataStatus,
  );

  const showLoader = useMemo(() => {
    if (timelineInfo) {
      return false;
    }
    return customerDataAJAXStatus === "pending" ? true : false;
  }, [customerDataAJAXStatus, timelineInfo]);

  const { dispatch } = useTimeline();

  useEffect(() => {
    if (timelineInfo) {
      dispatch({
        type: TimelineActionTypes.SET_TIMELINE_DATA,
        payload: timelineInfo,
      });
    }
  }, [timelineInfo, dispatch]);

  useEffect(() => {
    dispatch({
      type: TimelineActionTypes.SET_LOADING,
      payload: showLoader,
    });
  }, [showLoader, dispatch]);

  return (
    <TimelineInfo
      timelineRef={timelineRef}
      showTimeline={showTimeline}
    />
  );
};

export default CustomerTimeline;
