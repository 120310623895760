import { useState } from "react";
import styles from "./SendingOption.module.scss";
import Select, { ActionMeta, SingleValue } from "react-select";
import timelineImg from "src/assets/images/timelineImg.png";
import { ClearIndicator } from "react-select/dist/declarations/src/components/indicators";
// Define the type for option items
interface OptionType {
  label: string;
  value: string;
}

// Dummy data for the select options with images
const options: OptionType[] = [
  {
    label: "Via email",
    value: "email",
  },
  {
    label: "Via live chat",
    value: "live-chat",
  },
];
const customStyles = {
  control: (styles: any) => ({
    ...styles,
    background: "#f5f5f5",
    minWidth: "105px",
    minHeight: "29px",
    display: "flex",
    alignItems: "center",
    border: "none",
    borderRadius: "4px",
    marginRight: "2px",
    font: "normal normal normal 12px/18px Poppins",
    color: "#000",
    cursor: "pointer",
  }),
  menu: (provided: any) => ({
    ...provided,

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    border: "none",
    font: "normal normal normal 12px/18px Poppins",
    boxShadow: "0px 0px 16px #00000029",
    color: "#000",
    padding: "4px 0",
    zIndex: 9999,
    minWidth: "min-content",
    // width: "205px",
  }),
  option: (styles: any, { data, isFocused }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    color: isFocused ? "#fff" : "#000",
    backgroundColor: isFocused ? "#0B68BB" : undefined,
    cursor: "pointer",
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    color: "#000000",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: "0",
    cursor: "pointer",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // Add custom styles for valueContainer here
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 8px", // Custom padding
    display: "flex",
    alignItems: "center",
    height: "24px", // Adjust the height if needed
    fontSize: "12px", // Custom font size
    fontFamily: "Poppins, sans-serif", // Font family
    fontWeight: "normal", // Font weight
  }),
  menuList: (provided: any) => ({
    ...provided,
  }),
  menuPortal: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    font: "normal normal normal 12px/18px Poppins",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
};
// Custom Option component to display both image and text
const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      className={`px-1 ${styles.customOption}`}
    >
      <span className={`${styles.optionLabel}`}>
        {data.label}

        {/* <span className="ps-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.462"
            height="18.462"
            viewBox="0 0 18.462 18.462"
          >
            <g
              id="Group_17960"
              data-name="Group 17960"
              transform="translate(-96.171 -4)"
            >
              <rect
                id="Rectangle_22586"
                data-name="Rectangle 22586"
                width="13.054"
                height="13.054"
                rx="3"
                transform="translate(105.401 4) rotate(45)"
                fill="#ff0101"
              />
              <text
                id="_"
                data-name="!"
                transform="translate(103.885 16.73)"
                fill="#fff"
                font-size="9"
                font-family="Poppins-SemiBold, Poppins"
                font-weight="600"
              >
                <tspan
                  x="0"
                  y="0"
                >
                  !
                </tspan>
              </text>
            </g>
          </svg>
          <span className={`${styles.configureText}`}>Configure live chat</span>
        </span> */}
      </span>
    </div>
  );
};
// Custom SingleValue component to display both image and text in selected value
const CustomSingleValue = (props: any) => {
  const { data } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <span className={`${styles.optionLabel}`}>{data.label}</span>
    </div>
  );
};
const CustomIndicator = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className={`pe-2 ${styles.dropdownIndicator}`}>
        <i className="fa-solid fa-chevron-down"></i>
      </span>{" "}
      {/* Custom indicator icon */}
    </div>
  );
};

const SendingOption = ({ value }: { value: string }) => {
  const [selectedOption, setSelectedOption] = useState<OptionType | undefined>(
    options.find((v) => v.value === value),
  );

  // Handle dropdown change
  const handleDropdownChange = (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    if (newValue) {
      setSelectedOption(newValue); // Set the new value as the selected option
    }
  };
  return (
    <div>
      <Select
        value={selectedOption}
        onChange={handleDropdownChange}
        options={options}
        placeholder=""
        isSearchable={false}
        styles={customStyles}
        isClearable={true} // Enables the clear indicator (X icon)
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: CustomIndicator,
          Option: CustomOption, // Use custom Option component
          SingleValue: CustomSingleValue, // Use custom SingleValue component
        }}
      />
    </div>
  );
};

export default SendingOption;
