import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import group from "src/assets/images/group.svg";
import { SegmentColumn } from "src/services/CustomerSegments/getSegmentColumns.service";
import { useSegmentView } from "../../hooks/useSegmentView";
import AddNewAttribute from "./children/AddNewAttribute/AddNewAttribute";
import styles from "./TableHeader.module.scss";

const TableHeader = () => {
  const { segmentView, activeSegmentDetails } = useSegmentView();
  const [showModal, setShowModal] = useState(false);

  const columns = useMemo(() => {
    const columns: SegmentColumn[] = [];
    segmentView.allColumnKeys.forEach((key) => {
      const column = segmentView.allColumns[key];
      if (column?.selected || column?.required) {
        columns.push(column);
      }
    });
    return columns;
  }, [segmentView]);

  return (
    <div className={` mb-2 position-relative ${styles.tableHeader}`}>
      {columns.map((column, index) => {
        return (
          <div
            className={`${styles.headerElement} ${
              index === 0 ? styles.firstEle : ""
            } ${index === columns.length - 1 ? styles.lastEle : ""}`}
            key={column.key}
          >
            <div>
              {index === 0 ? (
                <img
                  src={group}
                  alt="group"
                  className="pe-1"
                />
              ) : (
                ""
              )}

              <span className={`${styles.headerText}`}>{column.name}</span>
              {/* use below icon to sort as per xd  */}
              <span className={`ps-1 ${styles.arrows} d-none`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.735"
                  height="3.821"
                  viewBox="0 0 6.735 3.821"
                >
                  <path
                    id="Icon_awesome-caret-down"
                    data-name="Icon awesome-caret-down"
                    d="M.453,0H6.282A.452.452,0,0,1,6.6.773L3.688,3.689a.454.454,0,0,1-.641,0L.134.773A.452.452,0,0,1,.453,0Z"
                    transform="translate(6.735 3.821) rotate(180)"
                    fill="#0b68bb"
                  />
                </svg>
              </span>
            </div>
            {index === 0 && (
              <div
                className={`${styles.addAttributeIcon} d-flex align-items-center justify-content-center cursor-pointer me-2`}
                onClick={() => setShowModal(true)}
              >
                <span>
                  <i className="fa-solid fa-plus"></i>
                </span>
              </div>
            )}
          </div>
        );
      })}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <AddNewAttribute
          onHide={() => setShowModal(false)}
          segmentType={activeSegmentDetails.activeSegmentType ?? ""}
          segmentId={activeSegmentDetails.activeSegmentId ?? undefined}
        />
      </Modal>
    </div>
  );
};

export default TableHeader;
