import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import getAllCustomerNoteService, {
  GetAllCustomerNoteResponse,
  ICustomerNote,
} from "src/services/CustomerSegments/CustomerNote/getAllCustomerNote.service";

const LIMIT = 6;
const sortBy = "desc";

type NotePageData = GetAllCustomerNoteResponse & {
  page: number;
};

const useUserNote = ({ customerId }: { customerId: string }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => ["getAllCustomerNoteService", customerId],
    [customerId],
  );

  const {
    data,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetching,
  } = useInfiniteQuery<NotePageData>({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getAllCustomerNoteService({
        customerId,
        limit: LIMIT,
        start: (pageParam - 1) * LIMIT,
        sortBy,
      });
      return { ...res, page: pageParam };
    },
    getNextPageParam: (lastPage) => {
      const { page } = lastPage;
      const totalPages = Math.ceil(lastPage.metaData.totalCount / LIMIT);
      return page < totalPages ? page + 1 : undefined;
    },
    getPreviousPageParam: (firstPage) => {
      const { page } = firstPage;
      return page > 1 ? page - 1 : undefined;
    },
    enabled: !!customerId, // Enable only if customerId is available
    keepPreviousData: true, // Keep previous data while fetching new data
  });

  const totalPages = useMemo(() => {
    return Math.ceil(
      (data?.pages[data.pages.length - 1]?.metaData.totalCount ?? 0) / LIMIT,
    );
  }, [data?.pages]);

  const { currentNotes, currentNoteIds } = useMemo(() => {
    if (!data) {
      return { currentNotes: {}, currentNoteIds: [] };
    }

    const currentPageData = data.pages.find((p) => p.page === currentPage);

    return {
      currentNotes: currentPageData?.allNotes ?? {},
      currentNoteIds: currentPageData?.allNoteIds ?? [],
    };
  }, [currentPage, data]);

  const { allNotes, allNoteIds } = useMemo(() => {
    if (!data) {
      return { allNotes: {}, allNoteIds: [] };
    }

    const allNotes: Record<string, ICustomerNote> = {};
    const allNoteIds: string[] = [];

    data.pages.forEach((page) => {
      // Collect all notes from each page
      page.allNoteIds.forEach((noteId) => {
        if (!allNotes[noteId]) {
          allNotes[noteId] = page.allNotes[noteId];
          allNoteIds.push(noteId); // Push noteId only once
        }
      });
    });

    return {
      allNotes,
      allNoteIds,
    };
  }, [data]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage > currentPage) {
        if (hasNextPage) {
          fetchNextPage();
        }
      } else if (newPage < currentPage) {
        if (hasPreviousPage) {
          fetchPreviousPage();
        }
      }
      setCurrentPage(newPage);
    },
    [
      currentPage,
      fetchNextPage,
      fetchPreviousPage,
      hasNextPage,
      hasPreviousPage,
    ],
  );

  const onAddNote = useCallback(
    (newNote: ICustomerNote) => {
      queryClient.setQueryData<InfiniteData<NotePageData> | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData) return oldData;

          // Clone the old data to avoid mutating the cache directly
          const newData = { ...oldData };
          newData.pages = oldData.pages.map((page) => ({
            ...page,
            allNotes: { ...page.allNotes },
            allNoteIds: [...page.allNoteIds],
          }));

          // Insert the new note into the first page
          newData.pages[0].allNotes[newNote.id] = newNote;
          newData.pages[0].allNoteIds.unshift(newNote.id);

          // Shift notes to maintain limit per page
          for (let i = 0; i < newData.pages.length; i++) {
            const currentPage = newData.pages[i];
            if (currentPage.allNoteIds.length > LIMIT) {
              // Get the last note ID of the current page
              const overflowNoteId = currentPage.allNoteIds.pop();
              if (overflowNoteId && newData.pages[i + 1]) {
                const nextPage = newData.pages[i + 1];
                nextPage.allNoteIds.unshift(overflowNoteId);
                nextPage.allNotes[overflowNoteId] =
                  currentPage.allNotes[overflowNoteId];
                delete currentPage.allNotes[overflowNoteId];
              }
            }
          }

          // Update the total count in metadata
          newData.pages = newData.pages.map((page) => ({
            ...page,
            metaData: {
              ...page.metaData,
              totalCount: page.metaData.totalCount + 1,
            },
          }));

          return newData;
        },
      );
    },
    [queryClient, queryKey],
  );

  const fetchMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  return {
    currentNotes,
    currentNoteIds,
    allNotes,
    allNoteIds,
    totalPages,
    currentPage,
    setCurrentPage: handlePageChange,
    isFetching,
    onAddNote,
    fetchNextPage: fetchMore,
    hasNextPage,
  };
};

export default useUserNote;
