import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "src/store/store";
import {
  TimelineActionTypes,
  useTimeline,
} from "../../../Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/TimelineProvider";
import TimelineInfo from "../../../Ticket/children/TicketHeader/Children/TimelineInfo/TimelineInfo";
const timelineData: any = {
  timeline: [
    {
      year: "2024",
      label: "Jan 2024",
      events: {
        minor: [
          {
            month: "01-08",
            label: "01-08",
            logins: 0,
            details: [],
          },
          {
            month: "09-16",
            label: "09-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-24",
            label: "17-24",
            logins: 0,
            details: [],
          },
          {
            month: "25-31",
            label: "25-31",
            logins: 0,
            details: [],
          },
        ],
        major: [
          {
            month: "01-16",
            label: "01-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-31",
            label: "17-31",
            logins: 0,
            details: [],
          },
        ],
        attribute: [
          {
            month: "01-12",
            label: "01-12",
            logins: 0,
            details: [],
          },
        ],
      },
      bgColor: "#FC8A27",
      position: "top",
    },
    {
      year: "2024",
      label: "Feb 2024",
      events: {
        minor: [
          {
            month: "01-08",
            label: "01-08",
            logins: 0,
            details: [],
          },
          {
            month: "09-16",
            label: "09-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-24",
            label: "17-24",
            logins: 0,
            details: [],
          },
          {
            month: "25-29",
            label: "25-29",
            logins: 0,
            details: [],
          },
        ],
        major: [
          {
            month: "01-15",
            label: "01-15",
            logins: 0,
            details: [],
          },
          {
            month: "16-29",
            label: "16-29",
            logins: 0,
            details: [],
          },
        ],
        attribute: [
          {
            month: "01-12",
            label: "01-12",
            logins: 0,
            details: [],
          },
        ],
      },
      bgColor: "#9EBD59",
      position: "bottom",
    },
    {
      year: "2024",
      label: "Mar 2024",
      events: {
        minor: [
          {
            month: "01-08",
            label: "01-08",
            logins: 0,
            details: [],
          },
          {
            month: "09-16",
            label: "09-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-24",
            label: "17-24",
            logins: 0,
            details: [],
          },
          {
            month: "25-31",
            label: "25-31",
            logins: 0,
            details: [],
          },
        ],
        major: [
          {
            month: "01-16",
            label: "01-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-31",
            label: "17-31",
            logins: 0,
            details: [],
          },
        ],
        attribute: [
          {
            month: "01-12",
            label: "01-12",
            logins: 0,
            details: [],
          },
        ],
      },
      bgColor: "#467EEB",
      position: "top",
    },
    {
      year: "2024",
      label: "Apr 2024",
      events: {
        minor: [
          {
            month: "01-08",
            label: "01-08",
            logins: 0,
            details: [],
          },
          {
            month: "09-16",
            label: "09-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-24",
            label: "17-24",
            logins: 0,
            details: [],
          },
          {
            month: "25-30",
            label: "25-30",
            logins: 0,
            details: [],
          },
        ],
        major: [
          {
            month: "01-15",
            label: "01-15",
            logins: 0,
            details: [],
          },
          {
            month: "16-30",
            label: "16-30",
            logins: 0,
            details: [],
          },
        ],
        attribute: [
          {
            month: "01-12",
            label: "01-12",
            logins: 0,
            details: [],
          },
        ],
      },
      bgColor: "#E84C3D",
      position: "bottom",
    },
    {
      year: "2024",
      label: "May 2024",
      events: {
        minor: [
          {
            month: "01-08",
            label: "01-08",
            logins: 0,
            details: [],
          },
          {
            month: "09-16",
            label: "09-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-24",
            label: "17-24",
            logins: 0,
            details: [],
          },
          {
            month: "25-31",
            label: "25-31",
            logins: 0,
            details: [],
          },
        ],
        major: [
          {
            month: "01-16",
            label: "01-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-31",
            label: "17-31",
            logins: 0,
            details: [],
          },
        ],
        attribute: [
          {
            month: "01-12",
            label: "01-12",
            logins: 0,
            details: [],
          },
        ],
      },
      bgColor: "#588BED",
      position: "top",
    },
    {
      year: "2024",
      label: "Jun 2024",
      events: {
        minor: [
          {
            month: "01-08",
            label: "01-08",
            logins: 0,
            details: [],
          },
          {
            month: "09-16",
            label: "09-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-24",
            label: "17-24",
            logins: 0,
            details: [],
          },
          {
            month: "25-30",
            label: "25-30",
            logins: 0,
            details: [],
          },
        ],
        major: [
          {
            month: "01-15",
            label: "01-15",
            logins: 0,
            details: [],
          },
          {
            month: "16-30",
            label: "16-30",
            logins: 0,
            details: [],
          },
        ],
        attribute: [
          {
            month: "01-30",
            label: "01-30",
            logins: 0,
            details: [],
          },
          {
            month: "01-12",
            label: "01-12",
            logins: 0,
            details: [],
          },
        ],
      },
      bgColor: "#B1E3FF",
      position: "bottom",
    },
    {
      year: "2024",
      label: "Jul 2024",
      events: {
        minor: [
          {
            month: "01-08",
            label: "01-08",
            logins: 0,
            details: [],
          },
          {
            month: "09-16",
            label: "09-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-24",
            label: "17-24",
            logins: 0,
            details: [],
          },
          {
            month: "25-31",
            label: "25-31",
            logins: 0,
            details: [],
          },
        ],
        major: [
          {
            month: "01-16",
            label: "01-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-31",
            label: "17-31",
            logins: 0,
            details: [],
          },
        ],
        attribute: [
          {
            month: "01-12",
            label: "01-12",
            logins: 0,
            details: [],
          },
        ],
      },
      bgColor: "#FC8A27",
      position: "top",
    },
    {
      year: "2024",
      label: "Aug 2024",
      events: {
        minor: [
          {
            month: "01-08",
            label: "01-08",
            logins: 0,
            details: [],
          },
          {
            month: "09-16",
            label: "09-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-24",
            label: "17-24",
            logins: 0,
            details: [],
          },
          {
            month: "25-31",
            label: "25-31",
            logins: 0,
            details: [],
          },
        ],
        major: [
          {
            month: "01-16",
            label: "Aug 2024",
            logins: 0,
            details: [],
          },
          {
            month: "17-31",
            label: "17-31",
            logins: 0,
            details: [],
          },
        ],
        attribute: [],
      },
      bgColor: "#E84C3D",
      position: "bottom",
    },
    {
      year: "2024",
      label: "Sep 2024",
      events: {
        minor: [
          {
            month: "01-08",
            label: "01-08",
            logins: 0,
            details: [],
          },
          {
            month: "09-16",
            label: "09-16",
            logins: 0,
            details: [],
          },
          {
            month: "17-24",
            label: "17-24",
            logins: 0,
            details: [],
          },
          {
            month: "25-30",
            label: "25-30",
            logins: 0,
            details: [],
          },
        ],
        major: [
          {
            month: "01-15",
            label: "01-15",
            logins: 0,
            details: [],
          },
          {
            month: "16-30",
            label: "16-30",
            logins: 0,
            details: [],
          },
        ],
        attribute: [
          {
            month: "01-12",
            label: "01-12",
            logins: 0,
            details: [],
          },
        ],
      },
      bgColor: "#588BED",
      position: "top",
    },
  ],
  last_updated: "2024-10-08 13:29:46",
};
const CampaignTimeline = ({
  showTimeline,
  timelineRef,
}: {
  showTimeline: boolean;
  timelineRef?: React.RefObject<HTMLDivElement>;
}) => {
  // const { timelineInfo, id } = useAppSelector(
  //   (state) => state.shopifySidebar.customerData,
  // );
  // const customerDataAJAXStatus = useAppSelector(
  //   (state) => state.shopifySidebar.customerDataStatus,
  // );

  // const showLoader = useMemo(() => {
  //   if (timelineInfo) {
  //     return false;
  //   }
  //   return customerDataAJAXStatus === "pending" ? true : false;
  // }, [customerDataAJAXStatus, timelineInfo]);

  const { dispatch } = useTimeline();

  useEffect(() => {
    if (timelineData) {
      dispatch({
        type: TimelineActionTypes.SET_TIMELINE_DATA,
        payload: timelineData,
      });
      dispatch({
        type: TimelineActionTypes.SET_LOADING,
        payload: false,
      });
    }
  }, [timelineData, dispatch]);

  // useEffect(() => {
  //   dispatch({
  //     type: TimelineActionTypes.SET_LOADING,
  //     payload: showLoader,
  //   });
  // }, [showLoader, dispatch]);

  return (
    <TimelineInfo
      timelineRef={timelineRef}
      showTimeline={showTimeline}
    />
  );
};

export default CampaignTimeline;
