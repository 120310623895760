import React, { useState } from "react";
import styles from "./CampaignWrapper.module.scss";
import CampaignBox from "./Children/CampaignBox/CampaignBox";
import ConfigureEmailTemplate from "./Children/ConfigureEmailTemplate/ConfigureEmailTemplate";
import { IVariableB } from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";
import { CampaignData, EmailTemplateData } from "../hooks/useCampaignData";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "src/containers/SideBar/SideBar";
import customerSegmentsUtils from "src/routes/CustomerSegments/utils/customerSegments.utils";

export interface Campaign {
  id: number;
  description: string;
  header: string;
  triggerAfter: number;
  channel: string;
  emailTemplateData?: EmailTemplateData;
}

interface Props extends CampaignData {
  name: string;
}
const CampaignWrapper = ({ id, name, campaignMessages }: Props) => {
  const [activeCampaign, setActiveCampaign] = useState<Campaign | null>(null);
  const [campaignName, setCampaignName] = useState(name);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  // Define common variables using the IVariableB format
  const commonVariablesList: { [x: string]: IVariableB } = {
    tempVar1: {
      id: "1",
      variableName: "First Name",
      example: "John",
      variableKey: "{first_name}",
    },
    tempVar2: {
      id: "2",
      variableName: "acme",
      example: "acme",
      variableKey: "{brand}",
    },
  };

  const commonVariableIdsList: string[] = ["tempVar1", "tempVar2"];

  const handleCampaignClick = (campaign: Campaign) => {
    setActiveCampaign(campaign);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignName(event.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className={`mt-3 ${styles.campaignWrapper}`}>
      <div className="mb-3">
        <h5 className={`d-flex align-items-center ${styles.campaignName}`}>
          <span className="pe-1"> Campaign name - </span>
          {isEditing ? (
            <input
              type="text"
              value={campaignName}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              className={`form-control px-2 py-1 ${styles.editInput}`}
            />
          ) : (
            <>
              <span>{campaignName}</span>
              <span
                className={`${styles.edit} ms-1`}
                onClick={handleEditClick}
              >
                Edit
              </span>
            </>
          )}
        </h5>
        {/* <h6 className={`${styles.campaignSegment}`}>
          User Segment -<span className={`${styles.type}`}> Cohort 1 </span>
          <span
            className={`${styles.edit} cursor-pointer`}
            onClick={() =>
              navigate(
                customerSegmentsUtils.getNavigation("userSegment", "17"),
                { state: { showModal: true } },
              )
            }
          >
            Edit
          </span>
        </h6> */}
      </div>
      <div>
        {activeCampaign ? (
          <ConfigureEmailTemplate
            onHide={() => setActiveCampaign(null)}
            referenceId={activeCampaign.id}
            data={
              activeCampaign.emailTemplateData || {
                salutation: "",
                introduction: "",
                body: "",
                heading: "",
                questionRegardingResolution: "",
              }
            }
            handleEmailModalDataChange={(key, value) => {
              if (activeCampaign && activeCampaign.emailTemplateData) {
                setActiveCampaign({
                  ...activeCampaign,
                  emailTemplateData: {
                    ...activeCampaign.emailTemplateData,
                    [key]: value,
                  },
                });
              }
            }}
            variablesList={commonVariablesList}
            variableIdsList={commonVariableIdsList}
            companyName="Your Company Name"
          />
        ) : (
          campaignMessages.map((campaign, index) => (
            <div key={campaign.id}>
              <CampaignBox
                key={index}
                handleCampaignClick={() => handleCampaignClick(campaign)}
                {...campaign}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CampaignWrapper;
