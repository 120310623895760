import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getMessageTypesService from "src/services/CustomerSegments/NewMessage/getMessageTypes.service";

const useFetchMessageTypes = () => {
  const { data, status } = useQuery(["getMessageTypesService"], {
    queryFn: getMessageTypesService,
    cacheTime: Infinity,
    staleTime: Infinity,
    networkMode: "always",
  });

  const { variables, messageTypes } = useMemo(
    () => ({
      messageTypes: data?.messageTypes ?? [],
      variables: data?.variables ?? [],
    }),
    [data?.messageTypes, data?.variables],
  );

  return {
    variables,
    messageTypes,
    messageTypesFetchStatus:
      status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : ("rejected" as AJAXSTATUS),
  };
};

export default useFetchMessageTypes;
