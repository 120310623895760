import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import upArrow from "src/assets/images/blueUpArrow.png";
import donwArrow from "src/assets/images/greyDownArrow.png";
import SearchBar from "src/components/SearchBar/SearchBar";
import TableHeader from "../../../TableHeader/TableHeader";
import styles from "../../SendMessageModal.module.scss";

const SelectSegments = () => {
  const [accordion, setAccordion] = useState(false);
  const [toggleState, setToggleState] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);

  return (
    <div className={`${styles.segmentAccordion} mb-4`}>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => setAccordion(!accordion)}
      >
        <div className="d-flex align-items-center">
          <span className={`${styles.accordHead} pe-3`}>User segment</span>
          <div className={`px-2 ${styles.addedItem}`}>
            <span>All users</span>
            <span className="ps-1">
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>
        <div>
          {accordion ? (
            <img
              src={upArrow}
              alt="down"
              width={10}
              className="cursor-pointer"
            />
          ) : (
            <img
              src={donwArrow}
              alt="down"
              width={10}
              className="cursor-pointer"
            />
          )}
        </div>
      </div>
      {accordion ? (
        <div className="mt-3">
          <Dropdown
            onToggle={(isOpen) => setToggleState(isOpen)}
            show={toggleState}
            align="start"
            id="segmentSelect"
            onClick={() => setToggleState(!toggleState)} // Ensure toggle on click
            bsPrefix="mb-2"
          >
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              as="div"
              bsPrefix={`px-2 ${styles.dropBtn}`}
            >
              <div className="d-flex justify-content-between align-items-center w-100">
                <span>1 user segment selected</span>
                <span className={`${styles.arrowBtn}`}>
                  <i
                    className={`fa-solid fa-caret-${
                      toggleState ? "up" : "down"
                    } ms-2`}
                  />
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              bsPrefix={`p-2 ${styles.dropMenu} ${
                toggleState ? "d-block" : "d-none"
              }`}
            >
              <SearchBar
                className={`${styles.search} px-2 mb-3`}
                inputClassName={`${styles.input}`}
                placeholder={`Search...`}
              />
              <div className={`d-flex align-items-center mb-2`}>
                <div className={`${styles.addBtn}`}>
                  <span>
                    <i className="fa-solid fa-plus"></i>
                  </span>
                </div>
                <span className={`ps-2 ${styles.addText}`}>
                  Add new segment
                </span>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.inputCheck}`}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ps-2 ${styles.checkLabel}`}
                  htmlFor="flexCheckDefault"
                >
                  All users
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.inputCheck}`}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ps-2 ${styles.checkLabel}`}
                  htmlFor="flexCheckDefault"
                >
                  All active users
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.inputCheck}`}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ps-2 ${styles.checkLabel}`}
                  htmlFor="flexCheckDefault"
                >
                  All leads
                </label>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <div className={`px-2 ${styles.addedItem}`}>
            <span>All users</span>
            <span className="ps-1">
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
          <div className={`${styles.tableAccordion} mt-3`}>
            <div
              className={`d-flex justify-content-between align-items-center px-2 py-2`}
              onClick={() => setShowTable(!showTable)}
            >
              <span className={`mb-2 ${styles.tableAccordionHead}`}>
                Preview 7 people who are in your audience right now
              </span>
              <span
                className={`${
                  showTable ? styles.activeToggleArrow : styles.toggleArrow
                }`}
              >
                <i
                  className={`fa-solid fa-sort-${showTable ? "up" : "down"}`}
                ></i>
              </span>
            </div>
            <div>
              {showTable ? (
                <div className="overflow-auto">
                  <TableHeader />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectSegments;
