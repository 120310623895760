import { useCallback, useMemo } from "react";
import CSSideBar from "./children/CSSideBar/CSSideBar";
import SegmentView from "./children/SegmentView/SegmentView";
import { useCustomerSegmentsCreate } from "./hooks/useCustomerSegments";
import { RouteNames } from "src/containers/SideBar/SideBar";
import { useLocation } from "react-router-dom";
import SegmentSettings from "./children/SegmentSettings/SegmentSettings";

function CustomerSegments() {
  const [state, dispatch, Provider] = useCustomerSegmentsCreate();
  const location = useLocation();

  const isSettingsPage = useMemo(() => {
    return location.pathname === `${RouteNames.customerSegments}/settings`;
  }, [location.pathname]);

  const onHide = useCallback(() => {
    dispatch("removeUpdateSegmentDetails");
  }, [dispatch]);

  return (
    <Provider value={[state, dispatch]}>
      <CSSideBar />
      {isSettingsPage ? (
        <SegmentSettings />
      ) : (
        <SegmentView
          data={state.segmentDataForUpdate}
          onHide={onHide}
        />
      )}
    </Provider>
  );
}

export default CustomerSegments;
