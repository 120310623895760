import { useCallback } from "react";
import Lottie from "lottie-light-react";
import LoaderAnimation from "src/assets/animation/white-loader.json";
import { useSendMessage } from "../../Hooks/useSendMessageContext";
import styles from "../../SendMessageModal.module.scss";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import sendNewMessageService from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import { generateAndValidatePayload } from "../../Hooks/sendMessage.helpers";

const ActionButtons = ({ onHide }: { onHide: () => void }) => {
  const { state, dispatch } = useSendMessage();

  const onSend = useCallback(async () => {
    const payload = generateAndValidatePayload(state);
    if (!payload) {
      dispatch("updateState", { showErrors: true });
      return;
    }

    try {
      dispatch("updateState", { sendMessageStatus: "pending" });

      await sendNewMessageService(payload);

      dispatch("updateState", { sendMessageStatus: "fulfilled" });
    } catch (e) {
      const err = e as Error;

      dispatch("updateState", {
        showErrors: true,
        sendMessageStatus: "rejected",
      });

      pushTheToast({
        position: "top-right",
        text: typeof err === "string" ? err : err.message,
        type: "danger",
      });
    }
  }, [dispatch, state]);

  return (
    <div
      className={`d-flex align-items-center justify-content-end px-2 ${styles.buttonWrapper}`}
    >
      <div className="d-flex align-items-center">
        {/* Discard button  */}
        <button
          className={`me-2 px-2 ${styles.discardBtn}`}
          id="sendMessageCancelBtn"
          onClick={onHide}
        >
          Cancel
        </button>
        {/* Save button  */}
        <button
          className={`${styles.saveBtn}`}
          id="sendMessageSendBtn"
          onClick={onSend}
          disabled={state.sendMessageStatus === "pending"}
        >
          {state.sendMessageStatus === "pending" ? (
            <div className={`${styles.loader}`}>
              <span className="me-1">Sending</span>
              <Lottie animationData={LoaderAnimation} />
            </div>
          ) : state.sendMessageStatus === "fulfilled" ? (
            "Message sent"
          ) : state.sendMessageStatus === "rejected" ? (
            "Sending Failed!"
          ) : (
            "Send"
          )}
        </button>
      </div>

      {/* Error message  */}
      {state.showErrors && (
        <div className={`d-flex mb-2 mt-1 ${styles.errText}`}>
          Sending failed. Please review the errors
        </div>
      )}
    </div>
  );
};

export default ActionButtons;
