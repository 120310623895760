/* eslint-disable no-throw-literal */
import { axiosJSON } from "src/globals/axiosEndPoints";
import { ISendMessageService } from "../SendMessageService";

interface SendCampaignMessageParams
  extends Omit<ISendMessageService, "ticketId"> {
  campaignId: number | string;
  ticketId?: number | string;
}

export async function sendCernThreadMessage(params: ISendMessageService) {
  const messageData: SendCampaignMessageParams = {
    ...params,
    campaignId: params.ticketId,
  };

  delete messageData.ticketId;

  const ccArr = (messageData.cc + "")
    .split(",")
    .filter((value) => value !== "undefined");
  const bccArr = (messageData.bcc + "")
    .split(",")
    .filter((value) => value !== "undefined" && !ccArr.includes(value));
  messageData.cc = ccArr.join(",");
  messageData.bcc = bccArr.join(",");
  if (messageData.cc?.trim() === "" || messageData.messageType === "Note") {
    delete messageData.cc;
  }
  if (messageData.bcc?.trim() === "" || messageData.messageType === "Note") {
    delete messageData.bcc;
  }
  if (messageData.to?.trim() === "" || messageData.messageType === "Note") {
    delete messageData.to;
  }

  if (messageData.from?.trim() === "" || messageData.messageType === "Note") {
    delete messageData.from;
  }

  if (
    messageData.message.length === 0 &&
    messageData.attachmentBatchNumber?.length === 0
  ) {
    throw "Description or attachment is required!";
  }

  if (messageData.attachmentBatchNumber?.length === 0) {
    delete messageData.attachmentBatchNumber;
  }

  const { data } = await axiosJSON.post(
    `/api/message/sendMessage`,
    messageData,
  );

  // Check if there is an error in the response data
  if (data.err) {
    // Check specific cases for errors
    if (data.statusCode) {
      // If the attachment size limit is exceeded, throw a specific error
      if (data.statusCode === "attachmentSizeLimitExceeded") {
        throw {
          statusCode: "attachmentSizeLimitExceeded",
          message: "Attachment file size exceeded",
        };
      } else if (data.statusCode === "integrationFailure") {
        throw {
          statusCode: "integrationFailure",
          message: data.msg ?? data.message,
          failureMetaData: data.failureMetaData,
        };
      } else if (data.statusCode) {
        throw {
          statusCode: data.statusCode,
          message: data.msg ?? data.message,
        };
      }
    } else {
      // Otherwise, throw a general error
      throw new Error("Something went wrong!");
    }
  }

  // Return the data if no errors occurred
  return data;
}
