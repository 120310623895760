import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export interface EmailTemplateData {
  salutation: string;
  introduction: string;
  questionRegardingResolution: string;
  body: string;
  heading: string;
}

interface CampaignMessage {
  id: number;
  description: string;
  header: string;
  triggerAfter: number;
  channel: string;
  emailTemplateData: EmailTemplateData;
}

export interface CampaignData {
  id: number;
  name: string;
  campaignMessages: CampaignMessage[];
}

const fetchCampaignData = async (): Promise<CampaignData> => {
  // Replace with your actual API endpoint
  const response = await axios.get("/api/campaignData");
  return response.data;
};

export const useCampaignData = () => {
  return useQuery<CampaignData, Error>(["campaignData"], fetchCampaignData, {
    initialData: {
      id: 1,
      name: "Journey type 1",
      campaignMessages: [
        {
          id: 1,
          header: "Hello this is other mail",
          description:
            "In retail and online shopping, the returns process starts when a customer wants to return a product and ends when the business collects. Now customers can choose two main return methods: shop online – return in store, or return online with shipping method integration. For online return ........!",
          triggerAfter: 1,
          channel: "email",
          emailTemplateData: {
            salutation: "Hello this is other mail",
            introduction:
              "In retail and online shopping, the returns process starts when a customer wants to return a product and ends when the business collects. Now customers can choose two main return methods: shop online – return in store, or return online with shipping method integration. For online return ........!",
            questionRegardingResolution: "Do you have any questions?",
            heading: "",
            body: "",
          },
        },
        {
          id: 2,
          header: "Hello this is other mail",
          description:
            "In retail and online shopping, the returns process starts when a customer wants to return a product and ends when the business collects. Now customers can choose two main return methods: shop online – return in store, or return online with shipping method integration. For online return ........!",
          triggerAfter: 2,
          channel: "email",
          emailTemplateData: {
            salutation: "Hello this is other mail",
            introduction:
              "In retail and online shopping, the returns process starts when a customer wants to return a product and ends when the business collects. Now customers can choose two main return methods: shop online – return in store, or return online with shipping method integration. For online return ........!",
            questionRegardingResolution: "Do you have any questions?",
            heading: "",
            body: "",
          },
        },
        {
          id: 3,
          header: "Hello this is other mail",
          description:
            "In retail and online shopping, the returns process starts when a customer wants to return a product and ends when the business collects. Now customers can choose two main return methods: shop online – return in store, or return online with shipping method integration. For online return ........!",

          triggerAfter: 3,
          channel: "email",
          emailTemplateData: {
            salutation: "Hello this is other mail",
            introduction:
              "In retail and online shopping, the returns process starts when a customer wants to return a product and ends when the business collects. Now customers can choose two main return methods: shop online – return in store, or return online with shipping method integration. For online return ........!",
            questionRegardingResolution: "Do you have any questions?",
            heading: "",
            body: "",
          },
        },
        {
          id: 4,
          header: "Hello this is other mail",
          description:
            "In retail and online shopping, the returns process starts when a customer wants to return a product and ends when the business collects. Now customers can choose two main return methods: shop online – return in store, or return online with shipping method integration. For online return ........!",

          triggerAfter: 4,
          channel: "email",
          emailTemplateData: {
            salutation: "Hello this is other mail",
            introduction:
              "In retail and online shopping, the returns process starts when a customer wants to return a product and ends when the business collects. Now customers can choose two main return methods: shop online – return in store, or return online with shipping method integration. For online return ........!",
            questionRegardingResolution: "Do you have any questions?",
            heading: "",
            body: "",
          },
        },
      ],
    },
  });
};
