import Lottie from "lottie-light-react";
import styles from "./SuccessMsg.module.scss";
import celebration from "src/assets/images/celebration.json";
import { useParams } from "react-router-dom";
import { useUploadNewCsvModalContext } from "../Hooks/useUploadNewCsvModalContext";

const SuccessMsg = () => {
  const { integrationId } = useParams();
  const { state } = useUploadNewCsvModalContext();

  return (
    <div>
      <div className={`text-center mt-5 ${styles.successWrapper}`}>
        <Lottie
          loop={false}
          className={`${styles.celebrationAnimation}`}
          animationData={celebration}
        ></Lottie>
        <span className={`mx-auto mb-3 ${styles.successIcon}`}>
          <i className="fa-solid fa-check"></i>
        </span>
        <p className={`mb-2 ${styles.successHead}`}>
          CSV file uploaded successful
        </p>
        <span className={`d-block ${styles.successSpan}`}>
          {state.uploadCSV?.uploadedFileName ?? ""}
        </span>
        <span className={`${styles.successDesc}`}>
          {/* Total of {data?.totalVarients ?? 0} products and{" "}
            {data?.totalVarients ?? 0} SKU were imported */}
        </span>
      </div>
    </div>
  );
};

export default SuccessMsg;
