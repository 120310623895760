import { useState } from "react";
import styles from "./CampaignSelect.module.scss";
import Select, { ActionMeta, SingleValue } from "react-select";
import timelineImg from "src/assets/images/timelineImg.png";
import { ClearIndicator } from "react-select/dist/declarations/src/components/indicators";
// Define the type for option items
interface OptionType {
  label: string;
  value: string;
  imageUrl: string; // Add an image URL property for the icon
}

// Dummy data for the select options with images
const options: OptionType[] = [
  {
    label: "Journey type 1",
    value: "email",
    imageUrl: timelineImg,
  },
];
const customStyles = {
  control: (styles: any) => ({
    ...styles,
    background: "#f5f5f5",
    minWidth: "137px",
    minHeight: "24px",
    display: "flex",
    alignItems: "center",
    border: "none",
    borderRadius: "2px",
    marginRight: "2px",
    font: "normal normal normal 11px/17px Poppins",
    color: "#000",
    cursor: "pointer",
  }),
  menu: (provided: any) => ({
    ...provided,

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    border: "none",
    font: "normal normal normal 11px/17px Poppins",
    boxShadow: "0px 0px 16px #00000029",
    color: "#000",
    padding: "8px",
    width: "200px",
  }),
  option: (styles: any, { data, isFocused }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    color: isFocused ? "#000" : "#000",
    backgroundColor: isFocused ? "#F0F0F0" : undefined,
    cursor: "pointer",
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    color: "#000000",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: "0",
    cursor: "pointer",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // Add custom styles for valueContainer here
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 8px", // Custom padding
    display: "flex",
    alignItems: "center",
    height: "24px", // Adjust the height if needed
    fontSize: "11px", // Custom font size
    fontFamily: "Poppins, sans-serif", // Font family
    fontWeight: "normal", // Font weight
  }),
  menuList: (provided: any) => ({
    ...provided,
  }),
  menuPortal: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    font: "normal normal normal 11px/17px Poppins",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
};
// Custom Option component to display both image and text
const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      className={`px-2 py-1 mb-1 ${styles.customOption}`}
    >
      <img
        src={data.imageUrl}
        alt={data.label}
        style={{ width: "29px", height: "15px", marginRight: "8px" }}
      />
      <span className={`${styles.optionLabel}`}>{data.label}</span>
    </div>
  );
};
// Custom SingleValue component to display both image and text in selected value
const CustomSingleValue = (props: any) => {
  const { data } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <img
        src={data.imageUrl}
        alt={data.label}
        style={{ width: "29px", height: "15px", marginRight: "8px" }}
      />
      <span className={`${styles.selectedoptionLabel}`}>{data.label}</span>
    </div>
  );
};
const CustomIndicator = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className={`pe-2 ${styles.dropdownIndicator}`}>
        <i className="fa-solid fa-chevron-down"></i>
      </span>{" "}
      {/* Custom indicator icon */}
    </div>
  );
};

const CampaignSelect = () => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>({
    label: "Journey type 1",
    value: "email",
    imageUrl: timelineImg,
  });

  // Handle dropdown change
  const handleDropdownChange = (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    setSelectedOption(newValue); // Set the new value as the selected option
  };
  return (
    <div>
      <Select
        value={selectedOption}
        onChange={handleDropdownChange}
        options={options}
        placeholder=""
        isSearchable={false}
        styles={customStyles}
        isClearable={true} // Enables the clear indicator (X icon)
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: CustomIndicator,
          Option: CustomOption, // Use custom Option component
          SingleValue: CustomSingleValue, // Use custom SingleValue component
        }}
      />
    </div>
  );
};

export default CampaignSelect;
