import { useState } from "react";
import templateIcon from "src/assets/images/tempNote.png";
import Variables from "src/components/AutomationComponents/ContentSection/VariableSection";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import EditEmail from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";
import { SegmentMessageType } from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import styles from "./MessageTabs.module.scss";
import EmailPreview from "../EmailPreview/EmailPreview";
import EmailPreviewText from "../EmailPreviewText/EmailPreviewText";
import Upload from "../Upload/Upload";

const MessageTabs = () => {
  const [activeTab, setActiveTab] = useState<SegmentMessageType | null>(null);

  return (
    <div className={`${styles.viaChatWrapper}`}>
      <div className="d-flex ms-2">
        <button
          className={`px-2 ${styles.viaBtn} ${
            activeTab === SegmentMessageType.Chat ? styles.active : ""
          }`}
          onClick={() => setActiveTab(SegmentMessageType.Chat)}
        >
          Via Chat
        </button>
        <button
          className={`px-2 ${styles.viaBtn} ${
            activeTab === SegmentMessageType.Email ? styles.active : ""
          }`}
          onClick={() => setActiveTab(SegmentMessageType.Email)}
        >
          Via Email
        </button>
      </div>

      {activeTab === SegmentMessageType.Chat && (
        <div className={`${styles.viaBox}`}>
          <h5 className={`${styles.viaHeading}`}>
            Via chat:{" "}
            <span>Message will appear when the user logs in next</span>
          </h5>
          <div className={`${styles.msgBox}`}>
            <button className={`px-2 mb-2 ${styles.templateBtn}`}>
              <img
                src={templateIcon}
                alt="template"
                width={12}
              />
              <span className="ps-1">View templates</span>
            </button>
            <div className={`${styles.editorBox}`}>
              <TinyMCEContainer
                value={""}
                onChange={function (value: string): void {
                  throw new Error("Function not implemented.");
                }}
                uniqueID={""}
              />
            </div>
            <Variables />
          </div>
        </div>
      )}

      {activeTab === SegmentMessageType.Email && (
        <div className={`${styles.viaBox}`}>
          <h5 className={`${styles.viaHeading}`}>
            Via email: <span>This email will be sent as a company email</span>
          </h5>
          <div className={`${styles.msgBox} mb-2`}>
            <button className={`px-2 mb-2 ${styles.templateBtn}`}>
              <img
                src={templateIcon}
                alt="template"
                width={12}
              />
              <span className="ps-1">View templates</span>
            </button>
            <div>
              <div className="mb-3">
                <label
                  htmlFor="emailSubject"
                  className={`form-label mb-1 ${styles.emailSubject}`}
                >
                  Email subject
                </label>
                <input
                  type="email"
                  className={`form-control px-2 ${styles.emailInput}`}
                  id="emailSubject"
                  placeholder="Write email subject"
                />
              </div>
            </div>
          </div>
          <div className={`${styles.configureEmail}`}>
            <h5 className={`${styles.configureHead}`}>
              Configure email design:
            </h5>
            <div className={`${styles.emailDesignWrap} d-flex`}>
              <div className={`${styles.leftSection}`}>
                <h6 className={`${styles.heading}`}>Edit email</h6>
                <Upload />
                <EmailPreviewText
                  labelText="Email subject:"
                  placeholder=""
                />
                <EmailPreviewText
                  labelText="Email heading:"
                  placeholder=""
                />
                <EditEmail
                  heading="Email body:"
                  placeholder=""
                  initialValue={""}
                  onChange={function (value: string): void {
                    throw new Error("Function not implemented.");
                  }}
                  showErrors={false}
                />
              </div>
              <div className={`${styles.rightSection} text-center`}>
                <EmailPreview />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageTabs;
