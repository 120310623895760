import { useCallback } from "react";
import { useUploadNewCsvModalContext } from "../Hooks/useUploadNewCsvModalContext";
import styles from "./ConfigureType.module.scss";
import { useParams } from "react-router-dom";

/**
 * Component that renders the first step in the upload new csv modal
 * Handles the quetion value changes
 */
const ConfigureType = () => {
  const { state, updateState, showErrors } = useUploadNewCsvModalContext();
  const { integrationId } = useParams();

  /**
   * Function to update the question Value
   */
  const onChangeHandler = useCallback(
    (optionKey: "importUsers" | "importLeads") => {
      // Update the state
      updateState({
        configureType: optionKey,
      });
    },
    [state.configureType, integrationId],
  );

  return (
    <div>
      <h4 className={`${styles.heading} mb-4`}>Configure type</h4>
      <div>
        <h5 className={`${styles.subHeading} mb-2`}>
          Please select the type for which you want to import the CSV?
        </h5>
        <div>
          <div className="form-check mb-2">
            <input
              className={`form-check-input ${
                showErrors && !state.configureType ? "border-danger" : ""
              }`}
              type="radio"
              name={"selectType"}
              id={"importUsers"}
              checked={state.configureType === "importUsers"}
              style={{ width: "14px", height: "14px" }}
              onChange={() => onChangeHandler("importUsers")}
            />
            <label
              className={`form-check-label ${styles.radioLabel}`}
              htmlFor={"importUsers"}
            >
              Import users
              <div className={styles.subText}>
                People who are already your customers or have existing user
                accounts.
              </div>
            </label>
          </div>
          <div className="form-check">
            <input
              className={`form-check-input ${
                showErrors && !state.configureType ? "border-danger" : ""
              }`}
              type="radio"
              name={"selectType"}
              id={"importLeads"}
              checked={state.configureType === "importLeads"}
              style={{ width: "14px", height: "14px" }}
              onChange={() => onChangeHandler("importLeads")}
              disabled
            />
            <label
              className={`form-check-label ${styles.radioLabel}`}
              htmlFor={"importLeads"}
            >
              import leads
              <div className={styles.subText}>
                A visitor becomes a lead as soon as they start a conversation or
                reply to an outbound message.
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureType;
