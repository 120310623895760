import { axiosJSON } from "src/globals/axiosEndPoints";

export interface AddSegmentUserPayload {
  // segmentType: string;
  name: string;
  email: string;
  userId: string;
}

interface AddSegmentUserResponse {
  statusCode?: "duplicate_email" | "duplicate_user_id";
  message?: string;
  segmentUserId: string | null;
  segmentId: string | null;
}

const addSegmentUserService = async (payload: AddSegmentUserPayload) => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/addSegmentUser`,
    payload,
  );

  if (res.error || res.err) {
    if (res.statusCode === "duplicate_email") {
      return {
        statusCode: res.statusCode,
        message: res.msg ?? res.message ?? "Duplicate email found",
        segmentUserId: null,
        segmentId: null,
      } as AddSegmentUserResponse;
    } else if (res.statusCode === "duplicate_user_id") {
      return {
        statusCode: res.statusCode,
        message: res.msg ?? res.message ?? "Duplicate user id found",
        segmentUserId: null,
        segmentId: null,
      } as AddSegmentUserResponse;
    } else {
      throw new Error(
        res.msg ?? res.message ?? "Something Unexpected Occured!",
      );
    }
  }

  return {
    segmentUserId: res.data.customerSegmentationId,
    segmentId: res.data.segmentId,
  } as AddSegmentUserResponse;
};

export default addSegmentUserService;
