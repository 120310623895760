import { useMemo } from "react";
import ParseHTML from "src/components/ParseHTML";
import styles from "./DesktopView.module.scss";
import { replaceKeyWithVariableName } from "src/utils/utils";

const DesktopView = ({
  companyName,
  data,
  variableList,
  view,
}: {
  companyName: string;
  data: any;
  variableList?: any;
  view: "Desktop" | "Mobile";
}) => {
  const salutation = useMemo(
    () => replaceKeyWithVariableName(data?.salutation, variableList),
    [data?.salutation, variableList],
  );

  const introduction = useMemo(
    () => replaceKeyWithVariableName(data?.introduction, variableList),
    [data?.introduction, variableList],
  );

  return (
    <div className={`${styles.deskTopView}`}>
      <div className={`py-5 ${styles.emailWrap}`}>
        <p className={`${styles.msg}`}>
          <ParseHTML
            html={salutation}
            showReadMoreLess={false}
          />
          <ParseHTML
            html={introduction}
            showReadMoreLess={false}
          />
        </p>
        <div className={`${styles.resBox} mb-3 `}>
          Cern's response will be here
        </div>
        <p className={`${styles.msg}`}>
          Best Regards,
          <br /> Team {companyName}
        </p>
        <div className="d-flex flex-wrap justify-content-between">
          <h4 className={`${styles.queryRes}`}>
            <ParseHTML
              html={data?.questionRegardingResolution}
              showReadMoreLess={false}
              className={`${styles.queryRes}`}
            />
          </h4>
          <span className={`${styles.answerSource}`}>
            Answer Source
            <span className="ps-1">
              <i className="fa-solid fa-up-right-from-square"></i>
            </span>
          </span>
        </div>
        <span
          className={`${styles.resDetail} ${
            view === "Mobile" ? "text-start" : "text-center"
          }`}
        >
          This response is created and sent by an AI bot, and responses may not
          be accurate. Please rate this response, and a human will get in touch
          with you soon to provide a resolution.
        </span>
        <div
          className={`d-flex flex-wrap justify-content-center mt-3 ${
            view === "Mobile" && styles.mobileBtnWrapper
          }`}
        >
          <button className={`me-1 mb-1 ${styles.resolveBtn}`}>
            This resolves my enquiry
          </button>
          <button className={`mb-1 ${styles.connectBtn}`}>
            Connect me to a human
          </button>
        </div>
      </div>
      <div className="pb-3">
        <hr />
        {/* <p className={`${styles.templateString}`}>
          To stop receiving Update emails, unsubscribe.
        </p> */}
        <span className={`${styles.templateString}`}>
          Copyright © {new Date().getFullYear()} {companyName} <br /> All
          rights reserved.
        </span>
      </div>
    </div>
  );
};

export default DesktopView;
