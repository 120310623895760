import { createContext, ReactNode, useContext, useReducer } from "react";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

export type TimelineReferenceType = "customer";
export interface TimelineProviderProps {
  children: ReactNode;
  referenceId: string | number;
  referenceType: TimelineReferenceType;
}
export interface TimelineState {
  timelineInfo: TimelineData | null;
  loading: boolean;
  error: string | null;
  referenceId: string | number;
  referenceType: TimelineReferenceType;
}

export type TimelineAction =
  | {
      type: TimelineActionTypes.SET_TIMELINE_DATA;
      payload: TimelineState["timelineInfo"];
    }
  | { type: TimelineActionTypes.SET_LOADING; payload: boolean }
  | { type: TimelineActionTypes.SET_ERROR; payload: string | null };

export interface TimelineContextProps {
  state: TimelineState;
  dispatch: React.Dispatch<TimelineAction>;
}

// Define an enum for action types
export enum TimelineActionTypes {
  SET_TIMELINE_DATA = "SET_TIMELINE_DATA",
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
}

// Create the context with a default value
const TimelineContext = createContext<TimelineContextProps | undefined>(
  undefined,
);

// Define the reducer
const timelineReducer = (state: TimelineState, action: TimelineAction) => {
  switch (action.type) {
    case TimelineActionTypes.SET_TIMELINE_DATA:
      return { ...state, timelineInfo: action.payload };
    case TimelineActionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case TimelineActionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

// Context provider component
export const TimelineProvider = ({ children }: TimelineProviderProps) => {
  const [state, dispatch] = useReducer(timelineReducer, {
    timelineInfo: null,
    loading: true,
    error: null,
    referenceId: 0,
    referenceType: "customer",
  });

  return (
    <TimelineContext.Provider value={{ state, dispatch }}>
      {children}
    </TimelineContext.Provider>
  );
};

// Custom hook to use the Timeline context
export const useTimeline = () => {
  const context = useContext(TimelineContext);
  if (!context) {
    throw new Error("useTimeline must be used within a TimelineProvider");
  }
  return context;
};
