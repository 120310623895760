import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import SearchBar from "src/components/SearchBar/SearchBar";
import {
  SegmentDataForUpdateI,
  SegmentTypes,
  useCustomerSegments,
} from "../../hooks/useCustomerSegments";
import customerSegmentsUtils from "../../utils/customerSegments.utils";
import UpdateSegmentDetails from "../UpdateSegmentDetails/UpdateSegmentDetails";
import UserProfile from "../UserProfile/UserProfile";
import CustomFilters from "./Children/CustomFilters/CustomFilters";
import Filters from "./Children/Filters/Filters";
import MoreCols from "./Children/MoreCols/MoreCols";
import TableHeader from "./Children/TableHeader/TableHeader";
import TableRow from "./Children/TableRow/TableRow";
import styles from "./SegmentView.module.scss";
import { useSegmentView, useSegmentViewCreate } from "./hooks/useSegmentView";
import UserModal from "./Children/UserModal/UserModal";
import SendMessageModal from "./Children/SendMessageModal/SendMessageModal";
import UploadNewCsvModal from "./Children/UploadCSVModal/UploadNewCsvModal";

const getSegmentCountName = (
  segmentType: SegmentTypes | null,
  isMulti = false,
) => {
  switch (segmentType) {
    case "companySegment":
      return isMulti ? `companies` : `company`;
    case "userSegment":
      return isMulti ? `users` : `user`;
    default:
      return "";
  }
};

const SegmentTable = () => {
  const {
    segmentView,
    activeSegmentDetails,
    dispatch,
    customerDispatch,
    fetchMoreSegmentView,
  } = useSegmentView();

  const [searchValueText, setSearchValueText] = useState(
    segmentView.searchText,
  );

  const searchInit = useRef(false);

  const totalCount = useMemo(() => {
    return `${segmentView.totalSegmentValues} ${getSegmentCountName(
      activeSegmentDetails.activeSegmentType,
      segmentView.totalSegmentValues > 1,
    )}`;
  }, [segmentView, activeSegmentDetails]);

  const onSearch = useCallback(
    (value: string) => {
      if (searchInit.current) {
        dispatch("setSegmentView", { searchText: value });

        // Need to put it in set time out because untill this function will stop execution the state will be stale.
        setTimeout(() => {
          fetchMoreSegmentView();
        }, 0);
      } else {
        searchInit.current = true;
      }
    },
    [dispatch, fetchMoreSegmentView],
  );

  const [msgModal, setMgModal] = useState(false);

  return (
    <div>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
        <div className="d-flex align-items-md-center flex-column flex-md-row">
          <h3 className={`saufter_h4 mb-0 me-2 ${styles.companyHead}`}>
            {segmentView.segmentValuesFetching === "pending" ? (
              <Spinner size="sm" />
            ) : (
              `${totalCount}`
            )}
          </h3>
          <MoreCols />
          {activeSegmentDetails.isCustomSegment ? (
            <CustomFilters />
          ) : (
            <Filters />
          )}
          {/* <button
            className={`mx-md-1 ${styles.msgBtn}`}
            id="newMsgBtn"
            onClick={() => setMgModal(true)}
          >
            <span className="me-2">
              <i className="fa-solid fa-paper-plane"></i>
            </span>
            <span>New message</span>
          </button> */}
          <Modal
            show={msgModal}
            onHide={() => setMgModal(false)}
            dialogClassName={`${styles.msgModalDialog}`}
            contentClassName={`${styles.msgModalContent}`}
          >
            <SendMessageModal onHide={() => setMgModal(false)} />
          </Modal>
        </div>
        <div>
          {activeSegmentDetails.segmentTypeName !== "All" && (
            <button
              className={`mt-2 mt-md-0 ${styles.segmentBtn}`}
              id={`segmentBtn`}
              onClick={() => {
                if (
                  activeSegmentDetails.activeSegmentId &&
                  activeSegmentDetails.activeSegmentType
                ) {
                  customerDispatch("setUpdateSegmentDetails", {
                    action: activeSegmentDetails.isCustomSegment
                      ? "update"
                      : "duplicate",
                    segmentId: activeSegmentDetails.activeSegmentId,
                    segmentType: activeSegmentDetails.activeSegmentType,
                  });
                }
              }}
            >
              <span className="pe-1">
                <i className="fa-solid fa-chart-pie"></i>
              </span>
              <span>
                {activeSegmentDetails.isCustomSegment
                  ? "Edit segment"
                  : "Duplicate segment"}
              </span>
            </button>
          )}
        </div>
      </div>
      <SearchBar
        className={`${styles.search} px-2 mt-2 mb-3`}
        inputClassName={`${styles.input}`}
        placeholder={`search by customer name , E-mail and any other keyword`}
        value={searchValueText}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setSearchValueText(e.target.value)
        }
        onSearch={onSearch}
      />

      <div className={`${styles.segementTable}`}>
        <div className={`${styles.ticketHeader}`}>
          <TableHeader />
        </div>
        {segmentView.segmentValuesFetching === "rejected" &&
        segmentView.segmentValueIds.length === 0 ? (
          <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
            Error Loading Content...
          </div>
        ) : segmentView.segmentValuesFetching === "pending" &&
          segmentView.localSegmentFetching !== "pending" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center">
            <Loader />
          </div>
        ) : segmentView.segmentValueIds.length === 0 ? (
          <div
            className={`mt-3 d-flex align-items-center justify-content-center ${styles.noChatFound}`}
          >
            <span> No Items Found</span>
          </div>
        ) : (
          <InfiniteScroll
            loadMoreFromBottom={fetchMoreSegmentView}
            hasMoreBottom={segmentView.hasMoreSegmentView}
            initialDataLoaded={true}
            className={`${styles.segmentsWrapper}`}
            infiniteLoader={
              segmentView.localSegmentFetching === "fulfilled" ? (
                // Rendering the load button conditionally.
                <div
                  className={`${styles.loaderText}`}
                  onClick={fetchMoreSegmentView}
                >
                  <span>Load More</span>
                  <i className="ms-1 fa-solid fa-rotate-right"></i>
                </div>
              ) : segmentView.localSegmentFetching === "pending" ? (
                // Rendering the load text conditionally.
                <div className={`${styles.loadingText}`}>
                  <span>Loading...</span>
                  <div>
                    <Spinner
                      className={`ms-1 ${styles.spinner}`}
                      size="sm"
                      animation="border"
                    />
                  </div>
                </div>
              ) : undefined
            }
          >
            {segmentView.segmentValueIds.map((id) => {
              return (
                <TableRow
                  key={id}
                  id={id}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const SegmentViewComponent = () => {
  const { segmentView, activeSegmentDetails, fetchMoreSegmentView } =
    useSegmentView();
  const navigate = useNavigate();

  useMemo(fetchMoreSegmentView, [
    `${activeSegmentDetails.activeSegmentId}::${activeSegmentDetails.activeSegmentType}::${segmentView.moreColsFetching}`,
  ]);
  const [userModal, setUserModal] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);
  return (
    <div
      className={`${styles.customerWrap}`}
      id="customerWrapper"
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-start w-100">
          {activeSegmentDetails.activeSegmentUserId && (
            <div
              className={`me-3 cursor-pointer ${styles.backbtn}`}
              id="goBckBtn"
              onClick={() =>
                navigate(
                  customerSegmentsUtils.getNavigation(
                    activeSegmentDetails.activeSegmentType ?? "",
                    activeSegmentDetails.activeSegmentId ?? "",
                  ),
                )
              }
            >
              <span>
                <i className="fa-solid fa-arrow-left"></i>
              </span>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <h1 className={`saufter_h1 mb-0 ${styles.heading}`}>
                {activeSegmentDetails.activeSegmentUserId
                  ? "User profile"
                  : `${activeSegmentDetails.segmentTypeName}/
              ${activeSegmentDetails.segmentName}`}
              </h1>
              {activeSegmentDetails.segmentDescription &&
                !activeSegmentDetails.activeSegmentUserId && (
                  <span className={`${styles.description}`}>
                    {activeSegmentDetails.segmentDescription}
                  </span>
                )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <button
                className={`d-flex align-items-center px-2  me-2 ${styles.addUserBtn} ${styles.uploadBtn}`}
                onClick={() => setShowCSVModal(true)}
              >
                <span>Upload CSV</span>
              </button>
              {!activeSegmentDetails.activeSegmentUserId && (
                <button
                  className={`d-flex align-items-center px-2 ${styles.addUserBtn}`}
                  onClick={() => setUserModal(true)}
                >
                  <span className="pe-1">
                    <i className="fa-solid fa-plus"></i>
                  </span>
                  <span>Add New User</span>
                </button>
              )}
            </div>
          </div>
          <Modal
            show={userModal}
            onHide={() => setUserModal(false)}
            dialogClassName={`${styles.userModalDialog}`}
            contentClassName={`${styles.userModalContent}`}
            centered={true}
          >
            <UserModal onHide={() => setUserModal(false)} />
          </Modal>
          <Modal
            show={showCSVModal}
            onHide={() => setShowCSVModal(false)}
            dialogClassName={`${styles.uploadModalDialog}`}
            contentClassName={`${styles.uploadModalContent}`}
            centered={true}
          >
            <UploadNewCsvModal onHide={() => setShowCSVModal(false)} />
          </Modal>
        </div>
      </div>

      <div
        className={`${styles.companyDetails} ${
          activeSegmentDetails.activeSegmentUserId ? "mt-3" : "p-4 mt-4"
        }`}
      >
        {segmentView.moreColsFetching === "rejected" ||
        activeSegmentDetails.allSegmentsLoading === "rejected" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
            Error Loading Content...
          </div>
        ) : segmentView.moreColsFetching === "pending" &&
          activeSegmentDetails.allSegmentsLoading !== "fulfilled" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center">
            <Loader />
          </div>
        ) : activeSegmentDetails.activeSegmentUserId ? (
          <UserProfile
            segmentUserId={activeSegmentDetails.activeSegmentUserId}
          />
        ) : (
          <SegmentTable />
        )}
      </div>
    </div>
  );
};

const SegmentView = ({
  onHide,
  data,
}: {
  onHide: () => void;
  data: SegmentDataForUpdateI | null;
}) => {
  const [state, dispatch, Provider] = useSegmentViewCreate();
  const { activeSegmentDetails, dispatch: csDispatch } = useCustomerSegments();
  const location = useLocation();
  const showModal = useMemo(
    () => location.state?.showModal || false,
    [location.state?.showModal],
  );

  const handleOpenModal = useCallback(() => {
    if (
      activeSegmentDetails.activeSegmentId &&
      activeSegmentDetails.activeSegmentType
    ) {
      csDispatch("setUpdateSegmentDetails", {
        action: "update",
        segmentId: activeSegmentDetails.activeSegmentId,
        segmentType: activeSegmentDetails.activeSegmentType,
      });
    }
  }, [
    activeSegmentDetails.activeSegmentId,
    activeSegmentDetails.activeSegmentType,
    csDispatch,
  ]);

  useEffect(() => {
    if (showModal) {
      handleOpenModal();
    }
  }, [handleOpenModal, showModal]);

  return (
    <Provider value={[state, dispatch]}>
      <SegmentViewComponent />

      {/* Create Segment Modal */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={data !== null ? true : false}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
      >
        <UpdateSegmentDetails />
      </Modal>
    </Provider>
  );
};

export default SegmentView;
