import TinyMCEContainer, {
  variableSpanGenerator,
} from "src/components/TinyMCEContainer";
import styles from "./TextBox.module.scss";
import { useCallback, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import useReturnWorkflowVariables from "src/features/ReturnAutoWorkFlow/hooks/useReturnWorkflowVariables";
import { isHTMLEmpty, trimPDIVBR } from "src/utils/utils";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import SearchBar from "src/components/SearchBar/SearchBar";
import { VariableTextData } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";

const variableStyles =
  "background: #dff0ff; border-radius: 5px; color: #0b68bb; padding: 1px;";

const AddVariableModal = ({
  onHide,
  addVariable,
  insertVariable,
  isLoading,
}: {
  onHide: () => void;
  addVariable: (
    variableName: string,
    example: string,
  ) => Promise<boolean | null>;
  insertVariable: () => void;
  isLoading: boolean;
}) => {
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [showErrors, setShowErrors] = useState(false);

  const onSubmit = useCallback(async () => {
    if (name.trim() === "" || message.trim() === "") {
      setShowErrors(true);
      return;
    }

    const res = await addVariable(name, message);

    if (res === true) {
      onHide();
    }
  }, [addVariable, message, name, onHide]);

  const onSubmitAndInsert = useCallback(() => {}, []);

  return (
    <div className={`position-relative py-3 ${styles.contentWrapper}`}>
      <div className={`d-flex justify-content-between px-3`}>
        <div className={`d-flex align-items-center`}>
          <span
            className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.backArrow}`}
            onClick={onHide}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </span>
          <span className={`ps-3 ${styles.addVariable}`}>Add Variables</span>
        </div>
        <div>
          <span
            className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.backArrow}`}
            onClick={onHide}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
      <div className={`mt-3 px-3`}>
        <div>
          <div className="mb-3">
            <label
              htmlFor="variableName"
              className={`form-label ${styles.formName}`}
            >
              Name:
            </label>
            <input
              type="text"
              className={`form-control ${styles.formInput} ${
                showErrors && name.trim() === "" ? styles.errBorder : ""
              }`}
              id="variableName"
              placeholder="name@example.com"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setShowErrors(false);
              }}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="variableText"
              className={`form-label ${styles.formName}`}
            >
              Message for the customer:
            </label>
            <textarea
              className={`form-control ${styles.formInput} ${
                showErrors && message.trim() === "" ? styles.errBorder : ""
              }`}
              id="variableText"
              rows={4}
              placeholder="write your message..."
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setShowErrors(false);
              }}
            ></textarea>
          </div>
        </div>
      </div>
      <div
        className={`d-flex justify-content-end py-2 pe-3 w-100 ${styles.actionBox}`}
      >
        <button
          className={`btn me-lg-2 px-2 py-2 ${styles.saveBtn}`}
          onClick={onSubmit}
        >
          {isLoading ? <Spinner size="sm" /> : "Save"}
        </button>
        <button
          className={`btn px-2 py-2 d-none ${styles.insertBtn}`}
          onClick={onSubmitAndInsert}
        >
          Save & Insert
        </button>
      </div>
    </div>
  );
};

const Variables = ({
  variablesState,
  placeholderRef,
  allowAddVariable,
}: {
  variablesState: ReturnType<typeof useReturnWorkflowVariables>;
  placeholderRef: React.MutableRefObject<any>;
  allowAddVariable: boolean;
}) => {
  const { allVariables, variableIds, addVariable, isAddingVariable } =
    variablesState;
  const [searchText, setSearchText] = useState<string>("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const filteredIds = useMemo(() => {
    if (searchText && searchText.trim().length > 0) {
      const filteredIds = variableIds.filter(
        (id) =>
          allVariables[id]?.variableName
            .toLowerCase()
            .includes(searchText?.toLowerCase()),
      );
      return filteredIds;
    }

    return variableIds;
  }, [allVariables, searchText, variableIds]);

  const setDropdown = useCallback(() => {
    if (showDropdown) {
      setSearchText("");
    }
    setShowDropdown(!showDropdown);
  }, [showDropdown]);

  /**
   * Example Variable for showing example
   */
  const exampleVariable = useMemo(() => {
    if (variableIds.length === 0) return "";
    const variableKey = allVariables[variableIds[0]]?.variableKey ?? "";
    return `${variableKey} Eg: ${allVariables[variableIds[0]].example}`;
  }, [allVariables, variableIds]);

  return (
    <div>
      <div className={`${styles.variableHeading}`}>Available variables:</div>
      <div className={`d-flex align-items-center flex-wrap`}>
        {allVariables && variableIds && variableIds.length !== 0 && (
          <div
            className={`me-2 p-2 mb-2 ${styles.variableText}`}
            role="button"
            onClick={(e) => {
              e.preventDefault();
              if (variableIds && variableIds.length !== 0) {
                addVariableOnClick(
                  allVariables[variableIds[0]],
                  placeholderRef,
                );
              }
            }}
          >
            {" "}
            <code> {exampleVariable}</code>{" "}
          </div>
        )}{" "}
        <Dropdown
          drop="up"
          show={showDropdown}
          onToggle={setDropdown}
        >
          <Dropdown.Toggle
            className={`dropdown-toggle ${styles.showBtn}`}
            as={"div"}
            variant="success"
            onClick={setDropdown}
          >
            <span className={`cursor-pointer ${styles.seeAll}`}> See all</span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            bsPrefix={`dropdown-menu pt-0 border-0 ${styles.variBox}`}
          >
            <div>
              <div
                className={`px-2 d-flex justify-content-between align-items-center py-2 ${styles.headerCol}`}
              >
                <span className={`${styles.leadName}`}>All variables</span>
                <div className={`d-flex align-items-center`}>
                  {allowAddVariable && (
                    <button
                      className={`btn btn-primary ${styles.addBtn}`}
                      onClick={() => setShowModal(true)}
                    >
                      + Add
                    </button>
                  )}
                  <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    dialogClassName={`${styles.modalDialog}`}
                    contentClassName={`${styles.modalContent}`}
                    centered={true}
                  >
                    <AddVariableModal
                      onHide={() => setShowModal(false)}
                      addVariable={addVariable}
                      isLoading={isAddingVariable}
                      insertVariable={() => {}}
                    />
                  </Modal>
                  <span
                    className={`cursor-pointer ps-2  ${styles.closeDrop}`}
                    onClick={setDropdown}
                  >
                    {" "}
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                </div>
              </div>
              <div className={`py-2 ${styles.variHeight}`}>
                {allVariables && filteredIds && filteredIds.length !== 0
                  ? filteredIds.map((id, idx) => {
                      const variable = allVariables[id];

                      if (!variable) {
                        return "";
                      }

                      return (
                        <li
                          key={idx}
                          className="m-1"
                        >
                          <span
                            className={`dropdown-item ${styles.dropDown}`}
                            onClick={() => {
                              addVariableOnClick(variable, placeholderRef);
                              setDropdown();
                            }}
                          >
                            <p className={`mb-0 ${styles.varibleHead}`}>
                              {variable?.variableName}
                            </p>
                            <span className={`${styles.varibleContent}`}>
                              {" "}
                              Eg: {variable?.example}
                            </span>
                          </span>
                        </li>
                      );
                    })
                  : ""}
              </div>
              <div className={`mx-1`}>
                <SearchBar
                  className={`${styles.search} form-control px-2`}
                  inputClassName={`${styles.input}`}
                  placeholder={`Search`}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                  isSearchIcon={false}
                />
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const TextBox = ({
  value,
  onChange,
  showErrors,
  allowAddVariable,
  editorVariableKey,
  placeholder = "Enter text...",
}: {
  value: string;
  editorVariableKey: string | null;
  allowAddVariable: boolean;
  onChange: (val: string) => void;
  showErrors: boolean;
  placeholder?: string;
}) => {
  const variablesState = useReturnWorkflowVariables(editorVariableKey);
  const allVariables = useMemo(
    () => variablesState.allVariables,
    [variablesState],
  );

  const placeholderRef = useRef(null as any);

  // Both useEffects below do same thing, they set the updated value from backend in the `htmlValue`
  // This one is for updating the value in middle on value change.
  const htmlValue = useMemo(() => {
    if (editorVariableKey === null) {
      return value;
    }

    if (!variablesState.isLoadingVariables) {
      // Initialize the data by replacing Variable Key if any present with proper styling
      const valueUpdated = replaceVariableKeysWithHtml(value, allVariables);
      return valueUpdated;
    } else {
      return null;
    }
  }, [
    value,
    variablesState.isLoadingVariables,
    allVariables,
    editorVariableKey,
  ]);

  /**
   * Change handler for setting values
   */
  const onChangeHandler = useCallback(
    (val: string) => {
      if (!val) {
        val = "";
      }
      onChange(replaceHtmlWithVariableKeys(val));
    },
    [onChange],
  );

  return (
    <div>
      {htmlValue !== null ? (
        <div className="w-100 mb-3 mt-3">
          <TinyMCEContainer
            className={`border ${styles.textBox} ${
              showErrors && isHTMLEmpty(trimPDIVBR(value.trim()))
                ? styles.errBorder
                : ""
            }`}
            value={htmlValue}
            onChange={onChangeHandler}
            options={{
              placeholder: placeholder,
            }}
            uniqueID={uuid()}
            showAttachmentOverlay={false}
            showToolbar={false}
            getMentionRef={(data) => {
              if (placeholderRef) {
                placeholderRef.current = data;
              }
            }}
            hideAllControls={true}
          />
          {showErrors && isHTMLEmpty(trimPDIVBR(value.trim())) ? (
            <p className={`${styles.errorStyle}`}>Please fill this field</p>
          ) : null}
        </div>
      ) : (
        ""
      )}
      {/* Variable Section  */}
      {editorVariableKey !== null && (
        <Variables
          variablesState={variablesState}
          placeholderRef={placeholderRef}
          allowAddVariable={allowAddVariable}
        />
      )}
    </div>
  );
};

const addVariableOnClick = (
  variable: VariableTextData,
  ref: React.MutableRefObject<any>,
) => {
  // Check if we have a new mention
  if (ref.current.addMentionRef?.current?.addMention) {
    // Adds a span with the new mention
    const variableKey = variable.variableKey;
    const name = `${variableKey} Eg: ${variable.example}`;
    ref.current.addMentionRef.current.addMention({
      id: variable.id, // Used to identify the variable
      variableKey,
      name, // Used to render the text inside the span
      inlineStyles: variableStyles,
    });
  }
};

// Replaces Variable key with tiny mce span
const replaceVariableKeysWithHtml = (
  value: string,
  variables: Record<string, VariableTextData>,
) => {
  let newString = value;
  // Iterate over all variables
  Object.values(variables).forEach((variable) => {
    const variableKey = variable.variableKey;
    const name = `${variableKey} Eg: ${variable.example}`;
    // Replace the variable key with the span tag and styles applied
    newString = newString.replaceAll(
      variable.variableKey,
      variableSpanGenerator({
        id: variable.id, // Used to identify the variable
        variableKey,
        name, // Used to render the text inside the span
        inlineStyles: variableStyles,
      }),
    );
  });

  if (!newString.includes("</p>")) {
    newString = `<p>${newString}</p>`;
  }

  return newString;
};

// Replaces Variable key with tiny mce span
const replaceHtmlWithVariableKeys = (value: string) => {
  let newString = value;
  const div = document.createElement("div");
  div.innerHTML = newString;

  const variables = div.querySelectorAll(`[data-variable-key]`);

  const allKeys: Array<{ key: string; label: string }> = [];

  variables.forEach((ele) => {
    const variableKey = ele.getAttribute("data-variable-key");
    if (variableKey) {
      allKeys.push({ key: variableKey, label: ele.outerHTML });
    }
  });

  for (let i = 0; i < allKeys.length; i++) {
    const element = allKeys[i];
    newString = newString.replace(element.label, element.key);
  }

  return newString;
};

export default TextBox;
