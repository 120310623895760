import ActionButtons from "./Children/ActionButtons/ActionButtons";
import MessageTabs from "./Children/MessageTabs/MessageTabs";
import SelectMessageType from "./Children/SelectMessageType/SelectMessageType";
import SelectSegments from "./Children/SelectSegments/SelectSegments";
import { useCreateSendMessageProvider } from "./Hooks/useSendMessageContext";
import styles from "./SendMessageModal.module.scss";

const Header = ({ onHide }: { onHide: () => void }) => {
  return (
    <div className="d-flex">
      <div
        className={`${styles.closeModal} flex-shrink-0`}
        id="msgModalHide"
        onClick={onHide}
      >
        <span className="cursor-pointer">
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div className="ms-3">
        <h2 className={`mb-0 ${styles.modalHead}`}>New Message</h2>
        <p className={`${styles.modalSubHead}`}>
          Select your message type and craft a communication for your selected
          segment.
        </p>
      </div>
    </div>
  );
};

const SendMessageModal = ({ onHide }: { onHide: () => void }) => {
  const [state, dispatch, Provider] = useCreateSendMessageProvider();

  return (
    <Provider value={[state, dispatch]}>
      <Header onHide={onHide} />
      <div className={`${styles.modalContent}`}>
        <div className={`px-3 ${styles.contentWraper}`}>
          <SelectMessageType />
          <SelectSegments />
          <MessageTabs />
        </div>
        <ActionButtons onHide={onHide} />
      </div>
    </Provider>
  );
};

export default SendMessageModal;
