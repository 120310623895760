import React, { useState } from "react";
import styles from "./EmailPreviewText.module.scss";

// Define the interface for the props
interface EmailPreviewTextProps {
  labelText: string;
  placeholder: string;
  onTextChange?: (text: string) => void; // Optional callback prop
}

const EmailPreviewText: React.FC<EmailPreviewTextProps> = ({
  labelText,
  placeholder,
  onTextChange,
}) => {
  const [inputText, setInputText] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setInputText(text);
    if (onTextChange) {
      onTextChange(text);
    }
  };

  return (
    <div className={`${styles.uploadWrapper}`}>
      <div className="mb-3">
        <label
          htmlFor="emailSubject"
          className={`form-label ${styles.label}`}
        >
          {labelText}
        </label>
        <input
          type="text"
          className={`form-control ${styles.inputBox}`}
          id="emailSubject"
          placeholder={placeholder}
          value={inputText}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default EmailPreviewText;
