import log from "loglevel";
import { EChannel } from "src/enums/EChannel";
import { EChannelIcon } from "src/enums/EChannelIcon";
import { Column } from "src/hooks/useTicketViews/ticketViewsConfig";
import base64 from "base-64";
import strReplaceAll from "string-replace-all";
import { PASSWORD__oUCoLCoSCoNeL } from "./regExps";
import { BrandAndSignature } from "src/services/Settings/EmailSignature/getBrandSignature";
import { IShowAttachment } from "src/services/Attachment/showAttachment";
import { v4 as uuid } from "uuid";
import helpLogo from "src/assets/images/helplama.png";
import saufterLogo from "src/assets/images/saufter.png";
import { BrandSignaturePlaceholder } from "src/services/Settings/Brands/getBrandSignaturePlaceholders";
import quillPlaceholderStyles from "../components/QuillEditor/QuillEditor.module.css";
import DMNotificationSound from "src/assets/audio/chat/DMNotificationSound.mp3";
import NewChatSound from "src/assets/audio/chat/NewChatSound.mp3";
import NewMessageSound from "src/assets/audio/chat/NewMessageSound.mp3";
import { IVariable } from "src/services/UiAutomation/ReturnExchange/Configuration/Variables/getAllVariables";
import { IVariableB } from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";
import { AllAutomationContexts } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";

const logo = window.location.hostname.includes(
  process.env.REACT_APP_LOGO_HOST_NAME + "",
)
  ? saufterLogo
  : helpLogo;

/**
 * Retrieves the version from local storage.
 * @returns {string | null} The version retrieved from local storage.
 */
export const getVersionFromLocalStorage = () => {
  return localStorage.getItem("HELPDESK_VERSION");
};

/**
 * Sets the version to local storage.
 * @param {string} version - The version to be set in local storage.
 */
export const setVersionToLocalStorage = (version: string) => {
  return localStorage.setItem("HELPDESK_VERSION", version);
};

/**
 * getBrandingName - Retrieves the branding name based on the current window location hostname.
 *
 * @returns {string} - The branding name, which can be "Saufter" or "Helplama".
 */
export function getBrandingName() {
  // Check if the window location hostname includes the specified logo host name from environment variables.
  // If it does, return "Saufter"; otherwise, return "Helplama".
  return window.location.hostname.includes(
    process.env.REACT_APP_LOGO_HOST_NAME + "",
  )
    ? "Saufter"
    : "Helplama";
}

export function passwordValidate(password: string) {
  return PASSWORD__oUCoLCoSCoNeL.test(password);
}

export const isObjOrStrEmpty = (value: any) =>
  value === undefined ||
  deepCheckObjIsNull(value) ||
  Object.keys(value).length === 0;

export function formatDate(date: Date) {
  // var d = new Date(date),
  let month = "" + (date.getMonth() + 1),
    day = "" + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  // log.debug({ formattedDate: [year, month, day].join("-") });
  return [year, month, day].join("-");
}

export function formatTime(date: Date) {
  let hh = "" + date.getHours(),
    mm = "" + date.getMinutes(),
    ss = "" + date.getSeconds();

  if (hh.length < 2) hh = "0" + hh;
  if (mm.length < 2) mm = "0" + mm;
  if (ss.length < 2) ss = "0" + ss;

  return [hh, mm, ss].join(":");
}

export function deepCheckObjIsNull(obj: {}): boolean {
  let objIsNull = false;
  JSON.stringify(obj, (key, val) => {
    if (val === null) {
      objIsNull = true;
    }
    return val;
  });
  return objIsNull;
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email));
}
export function findGreatest(array: Array<number>) {
  let ret = array[0];
  for (let i = 1; i < array.length; i++) {
    if (array[i] > ret) {
      ret = array[i];
    }
  }
  return ret;
}

export function setLogConfig(nodeEnv: any, logObject: any, silent = false) {
  // persistence is used to save the logs locally in local storage of browser or in the cookies
  const persistence = false;
  if (nodeEnv === "production") {
    logObject.setLevel("error", persistence);
  }

  if (nodeEnv === "development") {
    logObject.setLevel("debug", persistence);
  }

  if (silent === true) {
    logObject.setLevel("error", persistence);
  }
}

export const objectMap = (obj: any, fn: any) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export function selectFile(
  extensions: Array<string>,
  multiple?: boolean,
  sizeLimitMB = 20,
) {
  return new Promise((res, rej) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", extensions.map((ext) => `.${ext}`).join(","));
    if (multiple) {
      input.setAttribute("multiple", "true");
    }
    const BEH = (e: any) => {
      window.removeEventListener("focus", BEH);
      setTimeout(() => {
        if (input.files === null || input.files.length === 0) {
          rej({ msg: "Cancelled!", status: "cancelled" });
        }
      }, 1500);
    };
    input.addEventListener("change", (e: any) => {
      Object.values(e.target.files).forEach((file: any) => {
        if (
          !extensions.includes(getTypeFromFileName(file.name).toLowerCase())
        ) {
          rej({
            msg: "File type is not supported.",
            status: "extensionsupport",
            fileName: file.name,
          });
        } else if (file.size > sizeLimitMB * 1024 * 1024) {
          rej({
            msg: `File size should be less than ${sizeLimitMB} MB.`,
            status: "sizelimit",
            fileName: file.name,
          });
        }
      });
      res(e.target.files);
    });
    window.addEventListener("focus", BEH);
    input.click();
  }) as Promise<Array<File>>;
}

export function getJSONSearchParam(param: string | null) {
  let ret: any = null;
  if (param) {
    try {
      ret = JSON.parse(param);
    } catch {
      ret = null;
    }
  }
  return ret;
}

export function upperCaseEachFirst(text: string) {
  return text.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
}

export const getChannelFAIcon = (
  channel?: string,
  giveNoteAsDefault = false,
): string => {
  switch (channel?.toLocaleLowerCase()) {
    case EChannel.facebookMessenger:
      return EChannelIcon.facebookMessenger.toString();
    case EChannel.email:
      return EChannelIcon.email.toString();
    case EChannel.instagram:
      return EChannelIcon.instagram.toString();
    case EChannel.twitter:
      return EChannelIcon.twitter.toString();
    case EChannel.note:
      return EChannelIcon.note.toString();
    case EChannel.whatsapp:
      return EChannelIcon.whatsapp.toString();
    case EChannel.slack.toString():
      return EChannelIcon.slack.toString();
    default: {
      if (giveNoteAsDefault) {
        return "fa-solid fa-note-sticky";
      } else {
        return "";
      }
    }
  }
};

export function getActiveColKeysFromCols(
  columns: Column[],
  tableType?: string, // Optional string to specify the type of table
) {
  let columnKeys: string[] = []; // Initialize an empty array to store the active column keys

  // Iterate over each column in the columns array
  columns.forEach((data) => {
    if (data.show) {
      // Check if the column should be shown
      if (
        tableType && // Check if tableType is provided
        data?.onlyRequiredIfTableType && // Check if there are specific conditions for this column based on table type
        data?.onlyRequiredIfTableType[tableType] === undefined // Check if this column is not required for the given table type
      ) {
        // If the column is conditionally required and not needed for the current table type:
        const keys = Object.values(data.onlyRequiredIfTableType).reduce(
          (acc, currentValue) => acc.concat(currentValue), // Flatten the array of keys from onlyRequiredIfTableType
          [],
        );
        columnKeys.push(
          ...[...data.keys.filter((value) => !keys.includes(value))], // Filter out the keys that are not required and add them to columnKeys
        );
      } else {
        // If no specific conditions or conditions are met, add all keys from this column to columnKeys
        columnKeys.push(...data.keys);
      }
    }
  });
  columnKeys = [...new Set(columnKeys)]; // Remove duplicates from columnKeys
  return columnKeys;
}

export function arraysAreEqual(a1: any, a2: any): boolean {
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  return JSON.stringify(a1) === JSON.stringify(a2);
}

/**
 * Checks for duplicates in an array, with optional case insensitivity for strings.
 * @param array - The array to check for duplicates.
 * @param caseInsensitive - Whether to treat strings in a case-insensitive manner (default is false).
 * @returns True if there are duplicates, false otherwise.
 * 
 * @example
 * ```
// Case-insensitive check
const mixedArray1: (string | number)[] = ["Apple", "Banana", "apple", "Orange", 42, 42, "banana"];
const hasDuplicateMixedArray1 = hasDuplicates(mixedArray1, true);
console.log(hasDuplicateMixedArray1); // Output: true

// Case-sensitive check
const mixedArray2: (string | number)[] = ["Apple", "Banana", "apple", "Orange", 42, 42, "banana"];
const hasDuplicateMixedArray2 = hasDuplicates(mixedArray2, false);
console.log(hasDuplicateMixedArray2); // Output: true

// Number array (case-insensitivity has no effect)
const numberArray: number[] = [1, 2, 3, 4, 5];
const hasDuplicateNumberArray = hasDuplicates(numberArray);
console.log(hasDuplicateNumberArray); // Output: false
 * ```
 */
export function hasDuplicates<T extends string | number>(
  array: T[],
  caseInsensitive: boolean = false,
): boolean {
  const normalizedArray = array.map((item) =>
    typeof item === "string" && caseInsensitive ? item.toLowerCase() : item,
  );
  const uniqueItems = new Set(normalizedArray);
  return uniqueItems.size !== array.length;
}

export function combineArrayOfObjectsBasedOnKey(
  arrayOfArrays: Array<Array<any>>,
  key: number | string,
  key2?: number | string,
) {
  const combo: { [key: string]: any } = {};
  arrayOfArrays.forEach((array) => {
    array.forEach((element) => {
      if (key2 !== undefined) {
        if (combo[element[key][key2]] === undefined) {
          combo[element[key][key2]] = element;
        }
      } else {
        if (combo[element[key]] === undefined) {
          combo[element[key]] = element;
        }
      }
    });
  });
  return Object.values(combo);
}
export function uniqueArray(
  array: Array<string | number>,
  keepInEnd: boolean = false,
) {
  const o: any = {};
  array.forEach((element) => {
    if (o["num_" + element] === undefined || keepInEnd) {
      o["num_" + element] = element;
    }
  });
  return Object.values(o);
}

export const getDraftTextColor = (draftStatus: string) => {
  switch (draftStatus.toLowerCase()) {
    case "pending to draft":
      return "var(--pending_draft_text)";
    case "drafted":
      return "var(--drafted_text)";
    case "edited":
      return "var(--edited_text)";
    case "draft again":
      return "var(--draft_again_text)";
    case "approved(don't send)":
      return "var(--appr_dont_send_text)";
    case "approved":
      return "var(--approved_text)";
    case "sent":
      return "var(--sent_text)";
    default:
      return "var(--pending_draft_text)";
  }
};

export const getDraftBGColor = (draftStatus: string) => {
  switch (draftStatus.toLowerCase()) {
    case "pending to draft":
      return "var(--pending_draft_bg)";
    case "drafted":
      return "var(--drafted_bg)";
    case "edited":
      return "var(--edited_bg)";
    case "draft again":
      return "var(--draft_again_bg)";
    case "approved(don't send)":
      return "var(--appr_dont_send_bg)";
    case "approved":
      return "var(--approved_bg)";
    case "sent":
      return "var(--sent_bg)";
    default:
      return "var(--pending_draft_bg)";
  }
};

export function base64UrlDecode(input: string) {
  const str = strReplaceAll(
    strReplaceAll(strReplaceAll(input, ".", "+"), "_", "/"),
    "-",
    "=",
  );
  return base64.decode(str);
}

// function to remove html tags from string
export function removeHtmlTags(input: string) {
  const regex = /(<([^>]+)>)/gi;
  const textWithoutTags = input.replace(regex, "");
  // if the input string contain only html tags, then we will get an empty string, so return NA
  if (textWithoutTags.length === 0) {
    return "NA";
  }
  return textWithoutTags;
}

export function findAndReplaceCID(msg: string) {
  let ret = msg;
  try {
    const ele = document.createElement("div");
    ele.innerHTML = msg;
    let cids = ele.querySelectorAll("img[content-id]");
    cids.forEach((cid) => {
      try {
        let contentId = cid.getAttribute("content-id");
        cid.removeAttribute("content-id");
        cid.setAttribute("src", `cid:${contentId}`);
        cid.removeAttribute("id");
      } catch (e) {
        console.error(e);
      }
    });
    ret = ele.innerHTML;
    // // console.log(msg, ele, ret);
  } catch (e) {
    console.error(e);
    ret = msg;
  }
  return ret;
}

export function convertHTMLToText(html: string) {
  const div = document.createElement("div");
  div.innerHTML = html;
  let text = "";
  [...div.children].forEach((ele: any) => {
    text += ele.innerText + " \n";
  });
  return text;
}

export const removeSignatureFromText = (text: string) => {
  const h = document.createElement("div");
  h.innerHTML = text;
  h.querySelectorAll(".email-signature").forEach((ele: any) => {
    if (ele?.parentElement) {
      ele.parentElement.removeChild(ele);
    }
  });
  return h.innerHTML;
};

export const isHTMLEmpty = (text: string) => {
  const h = document.createElement("div");
  h.innerHTML = text;
  if (h.querySelector("img") || h.innerText.trim() !== "") {
    return false;
  } else {
    return true;
  }
};

export const isSigEqToText = ({
  text,
  signature,
}: {
  text: string;
  signature: string;
}) => {
  let sigChanged = false;
  const signatureDiv = document.createElement("div");
  signatureDiv.innerHTML = signature;
  const h = document.createElement("div");
  h.innerHTML = text;
  h.querySelectorAll(".email-signature").forEach((ele: any) => {
    if (ele?.outerHTML + "" !== signatureDiv.innerHTML) {
      sigChanged = true;
    }
    if (ele?.parentElement) {
      ele.parentElement.removeChild(ele);
    }
  });
  if (sigChanged) {
    return true;
  }
  if (h.querySelector("img") || h.innerText.trim() !== "") {
    return true;
  } else {
    return false;
  }
};

export const isSigEdited = ({
  text,
  signature,
}: {
  text: string;
  signature: string;
}) => {
  let sigChanged = false;
  const signatureDiv = document.createElement("div");
  signatureDiv.innerHTML = signature;
  const h = document.createElement("div");
  h.innerHTML = text;
  const ele = h.querySelector(".email-signature");
  if (ele?.outerHTML + "" !== signatureDiv.innerHTML) {
    sigChanged = true;
  }
  if (sigChanged) {
    return true;
  } else {
    return false;
  }
};

export function checkBrandSignature({
  brands,
  message,
  signature,
  isNote = false,
}: {
  brands: Array<BrandAndSignature> | null;
  message: string;
  signature?: string;
  isNote?: boolean;
}) {
  if (brands === null || signature == undefined || signature.trim() === "") {
    return message;
  }
  const sig = brands
    .map((value) => value.signature)
    .filter((value) => message.includes(value));
  if (sig.length) {
    return message.replace(sig[0], isNote ? "" : signature);
  }
  const ret = isNote ? message : message + signature;
  return ret;
}

export const removeMarginInEachPTag = (text: string) => {
  const d = document.createElement("div");
  d.innerHTML = text;
  d.querySelectorAll("p").forEach((ele) => {
    ele.setAttribute("style", "margin: 0;");
  });
  return d.innerHTML;
};

export const replacePTagWithSpan = (text: string) => {
  const d = document.createElement("div");
  text = text.replace(/(<p)/gim, "<span").replace(/<\/p>/gim, "</span>");
  d.innerHTML = text;

  return d.innerHTML;
};

export const removeImagesNSeeMoreFromMessage = (messageText: string) => {
  const element = document.createElement("div");
  element.innerHTML = messageText;

  // all elements with .more-content class
  let moreContents = element.querySelectorAll(".more-content");
  moreContents.forEach((moreContent) => {
    moreContent.remove();
  });

  // remove all embedded images
  let images = element.querySelectorAll("img");
  images.forEach((image) => {
    image.remove();
  });

  let withoutMoreContentsAndEmbeddedImages = element.innerHTML.replaceAll(
    "div",
    "p",
  );
  return withoutMoreContentsAndEmbeddedImages ?? "";
};

export const removeEmbeddedImgFromHTML = ({
  html,
  contentIds,
}: {
  html: string;
  contentIds: Array<string>;
}) => {
  const d = document.createElement("div");
  d.innerHTML = html;
  d.querySelectorAll("img").forEach((ele) => {
    if (
      !(
        contentIds.includes(ele.getAttribute("content-id") + "") ||
        contentIds.includes(ele.getAttribute("id") + "")
      )
    ) {
      if (ele.src.includes("https://")) {
        if (
          (ele.getAttribute("content-id") || ele.getAttribute("id")) &&
          ele.getAttribute("public-img") === "true"
        ) {
          ele.parentElement?.removeChild(ele);
        }
      } else {
        ele.parentElement?.removeChild(ele);
      }
    }
  });
  return d.innerHTML;
};

export const replaceImgTagWithFileName = ({
  html,
  attachments,
}: {
  html: string;
  attachments: Array<any>;
}) => {
  if (attachments?.length) {
    const at: any = {};
    attachments.forEach((file) => {
      if (file.contentId) {
        at[file.contentId] = file;
      }
    });
    const ele = document.createElement("div");
    ele.innerHTML = html;
    let images = ele.querySelectorAll("img[src]");
    images.forEach((image) => {
      try {
        let contentId = image.getAttribute("src");
        if (contentId?.includes("cid")) {
          contentId = contentId.split(":")[1];
          const imgT = document.createElement("span");
          imgT.innerHTML = `<i class="fa-solid fa-images"></i> ${
            at[contentId]?.attachmentName ?? "Undefined Attachment"
          } `;
          image.parentNode?.replaceChild(imgT, image);
        }
      } catch (e) {
        console.error(e);
      }
    });
    return ele.innerHTML;
  } else {
    return html;
  }
};

export const convertHTMLToExcerpt = (html: string) => {
  const ele = document.createElement("div");
  ele.innerHTML = html;
  const div = document.createElement("div");
  Object.values(ele.children).forEach((n) => {
    if (
      n.innerHTML.toLowerCase().includes("</i>") ||
      n.innerHTML.toLowerCase().includes("</span>")
    ) {
      div.innerHTML += n.innerHTML + " ";
    } else {
      div.innerHTML += (n as any).innerText.trim() + " ";
    }
  });
  return div.innerHTML;
};

export const dataURLtoFile = (dataurl: string) => {
  let arr = dataurl.split(","),
    mime = arr[0].slice(arr[0].indexOf(":") + 1, arr[0].indexOf(";")),
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${uuid()}.${mime.split("/")[1]}`, { type: mime });
};

export interface TempPasteIdFile extends File {
  tempPasteId?: string;
}

export const findImgInHTMLToFile = (ele: any) => {
  const imgs = ele.querySelectorAll("img[src]");
  const files: Array<TempPasteIdFile> = [];
  imgs.forEach((img: any) => {
    const src = img.getAttribute("src");
    if (
      src?.includes("data:") &&
      img.getAttribute("id") === null &&
      img.getAttribute("content-id") === null
    ) {
      const file = dataURLtoFile(src);
      const id = file.name.split(".")[0];
      (file as TempPasteIdFile).tempPasteId = id;
      files.push(file);
      img.setAttribute("id", id);
      img.setAttribute("style", `width:250px;`);
    }
  });
  return files;
};

// to remove see full thread from messages with thread
export const removeSeeFullThreadFromMessage = (messageText: string) => {
  const element = document.createElement("div");
  element.innerHTML = messageText;

  // all elements with .more-content class
  let moreContents = element.querySelectorAll(".more-content");
  moreContents.forEach((moreContent) => {
    moreContent.remove();
  });

  let withoutSeeFullThread = element.innerHTML;

  return withoutSeeFullThread ?? "";
};

export const arrayMoveIndex = ({
  arr,
  oldIndex,
  newIndex,
}: {
  arr: Array<any>;
  oldIndex: number;
  newIndex: number;
}) => {
  const data = arr[oldIndex];
  const d = [...arr.slice(0, oldIndex), ...arr.slice(oldIndex + 1)];
  return [...d.slice(0, newIndex), data, ...d.slice(newIndex)];
};

// function to return a string after truncating and placing prefix and suffix if any
export const getTruncatedStringWithPrefixAndSuffix = ({
  inputString,
  limit,
  prefix = "",
  suffix = "",
}: {
  inputString: string;
  limit: number;
  prefix?: string;
  suffix?: string;
}) => {
  let stringToRet = inputString;
  if (inputString.length >= limit) {
    stringToRet = inputString.substring(0, limit);
    stringToRet += "...";
  }
  if (prefix !== undefined && prefix !== "") {
    stringToRet = prefix + stringToRet;
  }
  if (suffix !== undefined && suffix !== "") {
    stringToRet = stringToRet + suffix;
  }
  return stringToRet;
};
//replace brand signature placeholder with label
export const replacePlaceHolderWithLabel = (
  value: string,
  brandPlaceholderOptions: Array<BrandSignaturePlaceholder>,
  styles?: string,
) => {
  let newString = value;
  brandPlaceholderOptions.forEach((placeholder) => {
    newString = newString.replaceAll(
      placeholder.placeholder,
      `<span contenteditable="false" ${
        styles ? `style="${styles}"` : ""
      } class="quill-mention ${
        quillPlaceholderStyles.quillMention
      }" data-name="${"{" + placeholder.label + "}"}" data-id="${
        placeholder.id
      }">${"{" + placeholder.label + "}"}</span>`,
    );
    //  newString = newString.replaceAll(placeholder.placeholder, `{${placeholder.label}}`);
  });

  return newString;
};

export const replaceLabelWithPlaceholder = (
  value: string,
  brandPlaceholderOptions: Array<BrandSignaturePlaceholder>,
  dataAttribute = "data-id",
) => {
  const element = document.createElement("div");
  element.innerHTML = value;

  // all elements with .quill-mention content class
  let moreContents = element.querySelectorAll(".quill-mention");

  moreContents.forEach((moreContent) => {
    // console.log(placeholderLables.includes(moreContent.innerHTML.trim()), moreContent);
    //   moreContent.remove();
    // }
    let placeholder = brandPlaceholderOptions.filter(
      (placeholder) =>
        moreContent.getAttribute(dataAttribute) == placeholder.id,
    );

    if (placeholder.length !== 0) {
      moreContent.replaceWith(placeholder[0].placeholder);
    }
  });

  return element.innerHTML;
};

// Replaces Variable key with tiny mce span
export const replaceKeyWithSpan = (
  value: string,
  variables: {
    [key: number]: IVariable;
  },
  styles?: string,
) => {
  let newString = value;
  // Iterate over all variables
  Object.values(variables).forEach((variable) => {
    // Replace the variable key with the span tag and styles applied
    newString = newString.replaceAll(
      variable.variableKey,
      `<span contenteditable="false" style="${
        styles ? styles : "display: inline-block;color: #0B68BB;"
      }" class="quill-mention ${
        quillPlaceholderStyles.quillMention
      }" data-name="${"{" + variable.variableName + "}"}" data-id="${
        variable.id
      }">${variable.variableKey}</span>`,
    );
  });

  return newString;
};

// Replaces tiny mce span with variable key
export const replaceSpanWithKey = (
  value: string,
  variables: {
    [key: number]: IVariable;
  },
) => {
  // Temporary div to work with html string
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = value;

  // Get all spans from the string
  let spans = tempDiv.querySelectorAll(".quill-mention");
  spans.forEach((span) => {
    // Select the variable to update
    const dataId = parseInt(span.getAttribute("data-id") ?? "");
    const variable = variables[dataId];

    // Once variable is found update the variable to include value
    if (variable) {
      span.replaceWith(variable.variableKey);
    }
  });

  return tempDiv.innerHTML;
};

export const findScrollContainer = (element: any) => {
  if (!element) {
    return undefined;
  }

  let parent = element.parentElement;
  while (parent) {
    const { overflow } = window.getComputedStyle(parent);
    if (overflow.split(" ").every((o) => o === "auto" || o === "scroll")) {
      return parent;
    }
    parent = parent.parentElement;
  }

  return document.documentElement;
};

interface PlayNotificationAudioI {
  soundType: "NewMessageSound" | "NewChatSound" | "Default";
  tag?: string;
}

let isNotificationPlaying = false;

export const playNotificationAudio = ({
  soundType,
  tag,
}: PlayNotificationAudioI) => {
  let tagData = null as null | string;
  if (tag) {
    tagData = window.localStorage.getItem(`HELPDESK_NOTIFICATION_TAG_ID${tag}`);
  }
  if (!isNotificationPlaying && tagData === null) {
    isNotificationPlaying = true;
    const sound =
      soundType === "NewMessageSound"
        ? NewMessageSound
        : soundType === "NewChatSound"
          ? NewChatSound
          : DMNotificationSound;
    const audio = new Audio(sound);
    if (tag) {
      window.localStorage.setItem(`HELPDESK_NOTIFICATION_TAG_ID${tag}`, tag);
      setTimeout(() => {
        window.localStorage.removeItem(`HELPDESK_NOTIFICATION_TAG_ID${tag}`);
        try {
          audio.pause();
          audio.remove();
        } catch {}
      }, 10000);
    }
    audio
      .play()
      .then(() => {})
      .catch((err) => {
        isNotificationPlaying = false;
      });

    audio.onended = () => {
      isNotificationPlaying = false;
      try {
        audio.pause();
        audio.remove();
      } catch {}
    };
  }
};

interface PushNotificationProps extends PlayNotificationAudioI {
  title: string;
  options?: NotificationOptions;
  callback?: () => void;
}

export function pushNotification({
  title,
  options,
  callback = () => {},
  soundType,
}: PushNotificationProps) {
  //checking if we can show notification
  playNotificationAudio({ soundType, tag: options?.tag });
  if (Notification?.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const notification = new Notification(title, { ...options, icon: logo });
    notification.onclick = (event: any) => {
      window.focus();
      callback();
    };
  }
}

interface PlayNotificationAudioInterval extends PlayNotificationAudioI {
  interval: number;
}

let recurringNotificationInterval: any = null; // Store the interval ID
export const startNotificationAudioWithInterval = ({
  soundType,
  tag,
}: PlayNotificationAudioInterval) => {
  if (recurringNotificationInterval) {
    clearInterval(recurringNotificationInterval);
  }

  recurringNotificationInterval = setInterval(() => {
    playNotificationAudio({ soundType, tag });
  }, 10000);
};

export const stopNotificationAudioInterval = () => {
  if (recurringNotificationInterval) {
    clearInterval(recurringNotificationInterval);
    recurringNotificationInterval = null;
  }
};

export const isNotificationAudioIntervalRunning = () => {
  // Check if the interval is running
  return recurringNotificationInterval;
};

export const getInnerText = (html: string) => {
  const d = document.createElement("div");
  d.innerHTML = html;
  return d.innerText;
};

export const showIth = (number: number) => {
  let num = `${number}`;
  const ele = num[num.length - 1];
  const ele2 = parseInt(`${num[num.length - 2]}${num[num.length - 1]}`);
  if (ele2 > 10 && ele2 < 14) {
    return "th";
  } else if (ele === "1") {
    return "st";
  } else if (ele === "2") {
    return "nd";
  } else if (ele === "3") {
    return "rd";
  } else if (ele) {
    return "th";
  } else {
    return "";
  }
};

export const trimHTML = (html: string, searchText: string) => {
  while (true) {
    if (html.indexOf(searchText) === 0) {
      html = html.slice(html.indexOf(searchText) + searchText.length);
    } else if (
      html.lastIndexOf(searchText) ===
      html.length - searchText.length
    ) {
      html = html.slice(0, html.lastIndexOf(searchText));
    } else {
      break;
    }
  }
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.innerHTML;
};

export const trimPDIVBR = (html: string) => {
  // Create a temporary div element
  const tempDiv = document.createElement("div");

  // Set the HTML content of the div with the input string
  tempDiv.innerHTML = html;

  // Remove <br> tags and empty HTML tags at the start
  while (
    tempDiv.firstChild &&
    !isExcludedTag(tempDiv.firstChild as Element) &&
    isEmptyNode(tempDiv.firstChild) &&
    (tempDiv.firstElementChild?.innerHTML.includes("<img") ? false : true)
  ) {
    tempDiv.removeChild(tempDiv.firstChild);
  }

  // Remove <br> tags and empty HTML tags at the end
  while (
    tempDiv.lastChild &&
    !isExcludedTag(tempDiv.lastChild as Element) &&
    isEmptyNode(tempDiv.lastChild) &&
    (tempDiv.lastElementChild?.innerHTML.includes("<img") ? false : true)
  ) {
    tempDiv.removeChild(tempDiv.lastChild);
  }

  // Check and remove empty child nodes at the start
  if (tempDiv.firstChild && tempDiv.firstChild.hasChildNodes()) {
    const firstChild = tempDiv.firstChild;

    while (
      firstChild.firstChild &&
      isEmptyNode(firstChild.firstChild) &&
      (tempDiv?.firstElementChild?.firstElementChild?.innerHTML.includes("<img")
        ? false
        : true)
    ) {
      firstChild.removeChild(firstChild.firstChild);
    }
  }

  // Check and remove empty child nodes at the end
  if (tempDiv.lastChild && tempDiv.lastChild.hasChildNodes()) {
    const lastChild = tempDiv.lastChild;

    while (
      lastChild.lastChild &&
      isEmptyNode(lastChild.lastChild) &&
      (tempDiv?.lastElementChild?.lastElementChild?.innerHTML.includes("<img")
        ? false
        : true)
    ) {
      lastChild.removeChild(lastChild.lastChild);
    }
  }

  // Return the trimmed and cleaned HTML
  return tempDiv.innerHTML.trim();
};

/**
 * Check if an element is an excluded tag.
 * @param {Element} element - The HTML element to check.
 * @returns {boolean} Returns true if the element is excluded; otherwise, false.
 */
function isExcludedTag(element: Element): boolean {
  const excludedTags = ["IMG"];
  return excludedTags.includes(element.tagName);
}

/**
 * Check if a node is empty (text node or element).
 * @param {Node} node - The DOM node to check.
 * @returns {boolean} Returns true if the node is empty; otherwise, false.
 */
function isEmptyNode(node: Node): boolean {
  if (node.nodeType === Node.TEXT_NODE) {
    return node.textContent?.trim() === "";
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    const element = node as Element;
    return (
      !isExcludedTag(element) &&
      !element.innerHTML.includes("img") &&
      (element.childNodes.length === 0 || element.textContent?.trim() === "")
    );
  }
  return false;
}

/**
 * Convert BASE64 to BLOB
 * @param base64Image Pass Base64 image data to convert into the BLOB
 */
export const convertBase64ToBlob = (base64Image: string) => {
  // Split into two parts
  const parts = base64Image.split(";base64,");

  // Hold the content type
  const imageType = parts[0].split(":")[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
};

export const sanatizeSignature = (text: string) => {
  const d = document.createElement("div");
  d.innerHTML = text;
  d.querySelectorAll(`img[public-img]`).forEach((img) => {
    img.removeAttribute("id");
  });
  return d.innerHTML;
};

export const showLastPassIcons = (hide: boolean) => {
  if (hide) {
    const styleTag = document.createElement("style");
    styleTag.setAttribute("id", "last-pass-styles");
    styleTag.innerHTML = `
      *[id*=lpform],
      *[id*=lastpass],
      *[class*=lpform],
      *[class*=lastpass],
      *[data-lastpass-icon-root],
      *[data-lastpass-root]
        {
        display: none !important;
      }
    `;
    document.querySelector("head")?.appendChild(styleTag);
  } else {
    const styleTag = document.getElementById("last-pass-styles");
    if (styleTag) {
      document.querySelector("head")?.removeChild(styleTag);
    }
  }
};

export const getTypeFromFileName = (
  fileName: string,
  captureMultiPartExtensions: Array<string> = ["tar.gz"],
) => {
  const fileExtArr = fileName.split(".");
  if (fileExtArr.length > 2) {
    // Check for specific multi-part extensions
    const lastTwo = fileExtArr.slice(-2).join(".");
    if (captureMultiPartExtensions?.includes(lastTwo)) {
      return lastTwo;
    }
  }
  const ext = fileExtArr[fileExtArr.length - 1];
  return (ext ?? "").toLowerCase();
};

let fbScript = null as any;

export const removeFBScript = () => {
  if (fbScript) {
    fbScript.parentElement.removeChild(fbScript);
    fbScript = null;
  }
};

export const generateFBScript = () => {
  const fbScript = document.createElement("script");
  fbScript.setAttribute("async", "");
  fbScript.setAttribute("defer", "");
  fbScript.setAttribute("crossorigin", "anonymous");
  fbScript.setAttribute(
    "src",
    "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v16.0&appId=441092627542981&autoLogAppEvents=1",
  );
  fbScript.setAttribute("nonce", "8DF3IP0X");
  document.querySelector("body")?.appendChild(fbScript);
};

export const getFilterValues = (
  filterValues: any,
  filterOnly: "viewFilter" | "otherFilter" = "otherFilter",
) => {
  //by defult saving the filter values in local variable
  let filter: any = filterValues;
  //checking the filter value type is array
  if (Array.isArray(filterValues)) {
    //iterating over each value and filtering the view filter
    if (filterOnly === "otherFilter") {
      filter = filterValues.filter((filter) => !filter?.viewFilter);
    } else {
      filter = filterValues.filter((filter) => filter?.viewFilter === true);
    }
  } else if (typeof filterValues === "object") {
    //values is object, checking if it has viewFilter property set to true if yes returing empty object
    filter = !filterValues?.viewFilter ? filterValues : {};
  }

  return filter;
};
// method to validate list of emails
export const isEmailsValid = (emailList: string[]) => {
  for (let i = 0; i < emailList.length; i += 1) {
    const regEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if (!regEx.test(emailList[i].trim())) {
      return false;
    }
  }
  return true;
};

export const validateURL = (url: string): string | null => {
  if (!url || url.trim() === "") {
    return "URL cannot be empty.";
  }

  // Check for spaces in the URL
  if (/\s/.test(url)) {
    return "URL cannot contain spaces.";
  }

  try {
    new URL(url.trim());
  } catch (error) {
    return "URL must start with http:// or https:// and be a valid URL format.";
  }

  return null; // No errors
};

export function isInViewport(element: any) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const getComputedStyle = (ele: any, cssPoperty: string) => {
  const styles = window.getComputedStyle(ele);
  return styles.getPropertyValue(cssPoperty);
};

export const getComputedZIndex = (ele: any) => {
  const styles = window.getComputedStyle(ele);
  const value = parseInt(styles.getPropertyValue("z-index"));
  if (isNaN(value)) {
    return 0;
  } else {
    return value;
  }
};

export const removeClassNames = (htmlString: string) => {
  // Create a temporary div element to parse the HTML string
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  // Remove all the class attributes
  tempElement.querySelectorAll(`[class]`).forEach((ele) => {
    ele.removeAttribute("class");
  });
  return tempElement.innerHTML;
};

/**
 * Replaces URLs in an HTML string with anchor tags.
 */
export const replaceURLWithAnchorTag = (htmlString: string) => {
  // return htmlString;
  // Create a temporary div element to parse the HTML string
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Regular expression to match URLs in the content
  const urlRegex =
    /(?:[A-Za-z][A-Za-z\d.+-]{0,14}:\/\/(?:[-.~*+=!&;:'%@?^${}(),\w]+@)?|www\.|[-;:&=+$,.\w]+@)[A-Za-z\d-]+(?:\.[A-Za-z\d-]+)*(?::\d+)?(?:\/(?:[-.~*+=!;:'%@$(),\/\w]*[-~*+=%@$()\/\w])?)?(?:\?(?:[-.~*+=!&;:'%@?^${}(),\/\w]+))?(?:#(?:[-.~*+=!&;:'%@?^${}(),\/\w]+))?/g;

  // Initialize the replacements object outside of the function
  const replacements: { [key: string]: string } = {};
  // Traverse the DOM and replace URLs with anchor tags
  replaceURLsInDOM(tempElement, urlRegex, replacements);

  // Perform the replacements after processing all child nodes
  for (const placeholder in replacements) {
    const anchorTag = replacements[placeholder];
    tempElement.innerHTML = tempElement.innerHTML.replace(
      placeholder,
      anchorTag,
    );
  }
  // Return the modified HTML string
  return tempElement.innerHTML;
};

/**
 * Recursively traverses the DOM and replaces URLs with anchor tags.
 */
function replaceURLsInDOM(
  element: Element,
  pattern: RegExp,
  replacements: { [key: string]: string },
) {
  // Placeholder prefix to identify matches
  const placeholderPrefix = "__URL_PLACEHOLDER_";

  // Get the child nodes of the current element
  const childNodes = element.childNodes;

  // Loop through the child nodes
  for (const childNode of childNodes) {
    // If the child node is a text node and contains text content
    if (childNode.nodeType === Node.TEXT_NODE && childNode.textContent) {
      let replacedContent = childNode.textContent;

      // Check if the parent element is not an <a> or <img> tag
      const parentTagName = childNode.parentElement?.tagName?.toLowerCase();
      if (parentTagName !== "a" && parentTagName !== "img") {
        // Replace URLs in the text content with anchor tags using the pattern directly
        replacedContent = replacedContent.replace(pattern, (match) => {
          const placeholder = `${placeholderPrefix}${
            Object.keys(replacements).length
          }_`;
          replacements[placeholder] =
            `<a href="${match}" target="_blank">${match}</a>`;
          return placeholder;
        });
      }

      if (replacedContent !== childNode.textContent) {
        childNode.textContent = replacedContent;
      }
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      // If the child node is an element and not <a> or <img>, recursively traverse it
      const element = childNode as Element; // Type cast to Element
      const tagName = element.tagName?.toLowerCase();
      if (tagName !== "a" && tagName !== "img") {
        replaceURLsInDOM(element, pattern, replacements);
      }
    }
  }
}

export function validateRange(minValue: string, maxValue: string) {
  // Check if both values are numeric and non-negative
  if (
    isNaN(parseInt(minValue)) ||
    isNaN(parseInt(maxValue)) ||
    parseInt(minValue) < 0 ||
    parseInt(maxValue) < 0
  ) {
    return false;
  }

  // Check if both values are non-negative decimal values with up to two decimal places
  const decimalRegex = /^\d+(\.\d{1,2})?$/;
  if (!decimalRegex.test(minValue) || !decimalRegex.test(maxValue)) {
    return false;
  }

  // Check if the first value is smaller than the second value and they are not the same
  if (parseFloat(minValue) >= parseFloat(maxValue)) {
    return false;
  }

  return true;
}

// Replaces Variable key with tiny mce span
export const replaceKeyWithSpanB = (
  value: string,
  variables: {
    [key: number]: IVariableB;
  },
  styles?: string,
) => {
  let newString = value;
  // Iterate over all variables
  Object.values(variables).forEach((variable) => {
    const variableKey = removeBracesFromVariableKey(variable.variableKey);
    const msg = `${variableKey} Eg: ${variable.example}`;
    // Replace the variable key with the span tag and styles applied
    newString = newString.replaceAll(
      variable.variableKey,
      `<span contenteditable="false" style="${
        styles ? styles : "display: inline-block;color: #0B68BB;"
      }" class="quill-mention ${
        quillPlaceholderStyles.quillMention
      }" data-name="${"{" + variable.variableName + "}"}" data-id="${
        variable.id
      }">${msg}</span>`,
    );
  });

  return newString;
};

// Replaces tiny mce span with variable key
export const replaceSpanWithKeyB = (
  value: string,
  variables: {
    [key: string]: IVariableB;
  },
) => {
  // Temporary div to work with html string
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = value;

  // Get all spans from the string
  let spans = tempDiv.querySelectorAll(".quill-mention");
  spans.forEach((span) => {
    // Select the variable to update
    const dataId = span.getAttribute("data-id");
    const variable = variables[dataId ?? -1];
    // Once variable is found update the variable to include value
    if (variable) {
      span.replaceWith(variable.variableKey);
    }
  });

  return tempDiv.innerHTML;
};

export const replaceKeyWithVariableName = (
  htmlString: string,
  variables: { [key: string]: IVariableB },
): string => {
  // Temporary div to work with html string
  const value = replaceKeyWithSpanB(htmlString, variables);

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = value;

  // Get all spans with the quill-mention class
  let spans = tempDiv.querySelectorAll(".quill-mention");

  spans.forEach((span) => {
    // Remove any inline styles
    span.removeAttribute("style");

    // Select the variable to update
    const dataId = span.getAttribute("data-id");
    if (dataId) {
      const variable = variables[dataId];
      // Once variable is found, replace the span with the variable name
      if (variable) {
        // span.replaceWith(variable.variableName);
        span.replaceWith(variable.example);
      }
    }
  });

  // Return the updated HTML string from the tempDiv
  return tempDiv.innerHTML;
};

export const replaceKeyWithVariableValue = (
  htmlString: string,
  variables: { [key: number]: IVariable },
): string => {
  // Temporary div to work with html string
  const value = replaceKeyWithSpan(htmlString, variables);

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = value;

  // Get all spans with the quill-mention class
  let spans = tempDiv.querySelectorAll(".quill-mention");

  spans.forEach((span) => {
    // Remove any inline styles
    span.removeAttribute("style");

    // Select the variable to update
    const dataId = span.getAttribute("data-id");
    if (dataId) {
      const variable = variables[parseInt(dataId)];
      // Once variable is found, replace the span with the variable name
      if (variable) {
        span.replaceWith(variable.variableValue);
      }
    }
  });

  // Return the updated HTML string from the tempDiv
  return tempDiv.innerHTML;
};

export const removeBracesFromVariableKey = (inputString: string) => {
  if (inputString.startsWith("{{") && inputString.endsWith("}}")) {
    return "{" + inputString.slice(2, -2) + "}";
  } else {
    return inputString;
  }
};

/**
 * Deep equality check for objects by value (recursively).
 * @param obj1 - The first object to compare.
 * @param obj2 - The second object to compare.
 * @returns true if the objects are deeply equal by value, false otherwise.
 */
export function deepCheckEqualObject(obj1: any, obj2: any): boolean {
  // Check if the references are equal, indicating the same object.
  if (obj1 === obj2) {
    return true;
  }

  // Check if either obj1 or obj2 is not an object, and if so, compare them by value.
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 === obj2;
  }

  // Get the keys of each object.
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same for both objects.
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate over the keys of the first object.
  for (const key of keys1) {
    // Check if the second object has the current key.
    if (!keys2.includes(key)) {
      return false;
    }

    // Recursively compare the values of the current key in both objects.
    if (!deepCheckEqualObject(obj1[key], obj2[key])) {
      return false;
    }
  }

  // If all checks pass, the objects are considered deeply equal.
  return true;
}

export function sanitizeHTML(html: string) {
  // Regular expression to remove all <script> tags
  let sanitizedHTML = html.replace(
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
    "",
  );
  // Additional sanitization steps can be added here if needed

  return sanitizedHTML;
}

export function deepCompare(
  obj1: any,
  obj2: any,
  ignoreKeys = [] as Array<string>,
) {
  // Check for strict equality first
  if (obj1 === obj2) {
    return true;
  }

  // If one is null/undefined and the other isn't, they're not equal
  if (obj1 == null || obj2 == null) {
    return false;
  }

  // If their types differ, they're not equal
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // If they're both Date objects, compare their time values
  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }

  // If they're both arrays, compare their lengths and elements
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let i = 0; i < obj1.length; i++) {
      if (!deepCompare(obj1[i], obj2[i])) {
        return false;
      }
    }

    return true;
  }

  // If they're both objects, compare their keys and values
  if (typeof obj1 === "object" && typeof obj2 === "object") {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (ignoreKeys.length) {
        if (ignoreKeys.includes(key)) {
          continue;
        }
      }
      if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  // If they're primitive types or anything else, use strict equality
  return false;
}

const EXCLUDED_DOMAINS = ["saufter.io"]; // Add any other domains you want to exclude

async function hmac(
  secretKey: string,
  message: string,
  algorithm: string = "SHA-256",
): Promise<string> {
  // Convert the message and secretKey to Uint8Array
  const encoder = new TextEncoder();
  const messageUint8Array = encoder.encode(message);
  const keyUint8Array = encoder.encode(secretKey);

  // Import the secretKey as a CryptoKey
  const cryptoKey = await window.crypto.subtle.importKey(
    "raw",
    keyUint8Array,
    { name: "HMAC", hash: { name: algorithm } },
    false,
    ["sign"],
  );

  // Sign the message with HMAC and the CryptoKey
  const signature = await window.crypto.subtle.sign(
    "HMAC",
    cryptoKey,
    messageUint8Array,
  );

  // Convert the signature ArrayBuffer to a hex string
  const hashArray = Array.from(new Uint8Array(signature));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
}

async function proxyImageSignedURL(url: string): Promise<string> {
  if (!(window as any).SAUFTER_IMAGE_PROXY_SIGN_KEY) {
    return url;
  }

  const hmacSignature = await hmac(
    (window as any).SAUFTER_IMAGE_PROXY_SIGN_KEY,
    url,
  );
  const base64Signature = base64.encode(hmacSignature);
  return base64Signature;
}

function isExcludedDomain(url: string): boolean {
  const parsedUrl = new URL(url);
  return EXCLUDED_DOMAINS.some((domain) => parsedUrl.hostname.endsWith(domain));
}

function shouldIgnoreURL(url: string): boolean {
  return (
    url.startsWith("data:") || url.startsWith("blob:") || isExcludedDomain(url)
  );
}

export const proxifyURL = async (targetUrl: string): Promise<string> => {
  if (!(window as any).SAUFTER_IMAGE_PROXY_URL) {
    return targetUrl;
  }

  if (shouldIgnoreURL(targetUrl)) {
    return targetUrl;
  }

  const url = new URL(targetUrl);
  const escapedURL = `${url.origin}${url.pathname}${url.search}`;
  const signedURL = await proxyImageSignedURL(escapedURL);
  return `${(window as any).SAUFTER_IMAGE_PROXY_URL}${signedURL}/${escapedURL}`;
};

export const parseMedia = async (html: string): Promise<string> => {
  const handledTags = ["img"];
  if (!handledTags.some((tag) => html.includes(`<${tag} `))) {
    return html;
  }

  if (!(window as any).SAUFTER_IMAGE_PROXY_URL) {
    return html;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const images = doc.querySelectorAll("img");
  for (const img of images) {
    const src = img.getAttribute("src");
    if (src && !shouldIgnoreURL(src)) {
      const proxifiedSrc = await proxifyURL(src);
      img.setAttribute("src", proxifiedSrc);
    }
  }

  return doc.body ? doc.body.innerHTML : doc.documentElement.outerHTML;
};
/**
 * Retrieves the currency symbol based on the given currency code.
 *
 * @param {string} currencyCode - The 3-letter currency code (e.g., "USD", "EUR").
 * @returns {string} - The corresponding currency symbol. If the code is not found, returns "Unknown currency code".
 *
 * @example
 * getCurrencySymbol("AFN"); // Returns "؋"
 * getCurrencySymbol("USD"); // Returns "Unknown currency code"
 */
export function getCurrencySymbol(currencyCode: string) {
  const currencySymbols: Record<string, string> = {
    AFN: "؋", // Afghanistan Afghani
    ARS: "$", // Argentine Peso
    AWG: "ƒ", // Aruban Florin
    AUD: "A$", // Australian Dollar
    AZN: "₼", // Azerbaijani Manat
    BSD: "$", // Bahamas Dollar
    BBD: "$", // Barbados Dollar
    BDT: "৳", // Bangladeshi Taka
    BYN: "Br", // Belarus Ruble
    BZD: "$", // Belize Dollar
    BMD: "$", // Bermudian Dollar
    BOP: "$b", // Boliviano
    BAM: "KM", // Bosnia-Herzegovina Convertible Marka
    BWP: "P", // Botswana Pula
    BGN: "лв", // Bulgarian Lev
    BRL: "R$", // Brazilian Real
    BND: "$", // Brunei Dollar
    KHR: "៛", // Cambodian Riel
    CAD: "C$", // Canadian Dollar
    KYD: "$", // Cayman Islands Dollar
    CLP: "$", // Chilean Peso
    CNY: "¥", // Chinese Yuan Renminbi
    COP: "$", // Colombian Peso
    CRC: "₡", // Costa Rican Colón
    HRK: "kn", // Croatian Kuna
    CUP: "₱", // Cuban Peso
    CZK: "Kč", // Czech Koruna
    DKK: "kr", // Danish Krone
    DOP: "$", // Dominican Peso
    XCD: "$", // Eastern Caribbean Dollar
    EGP: "£", // Egyptian Pound
    SVC: "$", // Salvadoran Colón
    EEK: "kr", // Estonian Kroon (discontinued)
    EUR: "€", // Euro
    FKP: "£", // Falkland Islands Pound
    FJD: "$", // Fijian Dollar
    GHC: "₵", // Ghanaian Cedi
    GIP: "£", // Gibraltar Pound
    GTQ: "Q", // Guatemalan Quetzal
    GGP: "£", // Guernsey Pound
    GYD: "$", // Guyanese Dollar
    HNL: "L", // Honduran Lempira
    HKD: "HK$", // Hong Kong Dollar
    HUF: "Ft", // Hungarian Forint
    ISK: "kr", // Icelandic Króna
    INR: "₹", // Indian Rupee
    IDR: "Rp", // Indonesian Rupiah
    IRR: "﷼", // Iranian Rial
    IMP: "£", // Manx Pound
    ILS: "₪", // Israeli Shekel
    JMD: "J$", // Jamaican Dollar
    JPY: "¥", // Japanese Yen
    JEP: "£", // Jersey Pound
    KZT: "₸", // Kazakhstani Tenge
    KPW: "₩", // North Korean Won
    KRW: "₩", // South Korean Won
    KGS: "лв", // Kyrgyzstani Som
    LAK: "₭", // Lao Kip
    LVL: "Ls", // Latvian Lats (discontinued)
    LBP: "£", // Lebanese Pound
    LRD: "$", // Liberian Dollar
    LTL: "Lt", // Lithuanian Litas (discontinued)
    MKD: "ден", // Macedonian Denar
    MYR: "RM", // Malaysian Ringgit
    MUR: "₨", // Mauritian Rupee
    MXN: "$", // Mexican Peso
    MNT: "₮", // Mongolian Tögrög
    MZN: "MT", // Mozambican Metical
    NAD: "$", // Namibian Dollar
    NPR: "₨", // Nepalese Rupee
    ANG: "ƒ", // Netherlands Antillean Guilder
    NZD: "NZ$", // New Zealand Dollar
    NIO: "C$", // Nicaraguan Córdoba
    NGN: "₦", // Nigerian Naira
    NOK: "kr", // Norwegian Krone
    OMR: "﷼", // Omani Rial
    PKR: "₨", // Pakistani Rupee
    PAB: "B/.", // Panamanian Balboa
    PYG: "Gs", // Paraguayan Guaraní
    PEN: "S/", // Peruvian Sol
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Złoty
    QAR: "﷼", // Qatari Riyal
    RON: "lei", // Romanian Leu
    RUB: "₽", // Russian Ruble
    SHP: "£", // Saint Helena Pound
    SAR: "﷼", // Saudi Riyal
    RSD: "дин", // Serbian Dinar
    SCR: "₨", // Seychellois Rupee
    SGD: "S$", // Singapore Dollar
    SBD: "$", // Solomon Islands Dollar
    SOS: "S", // Somali Shilling
    ZAR: "R", // South African Rand
    LKR: "₨", // Sri Lankan Rupee
    SEK: "kr", // Swedish Krona
    CHF: "CHF", // Swiss Franc
    SRD: "$", // Surinamese Dollar
    SYP: "£", // Syrian Pound
    TWD: "NT$", // New Taiwan Dollar
  };

  return currencySymbols[currencyCode] || "$";
}

/**
 * This utility function is the utility to find the currewntly being used automation function.
 */
export const findAutomationContextFromURLPath = () => {
  const pathNames = window.location.pathname.split("/");

  let value: AllAutomationContexts | "general" = "general";

  if (pathNames[1] === "automations") {
    const v = (pathNames[2] ?? "return") as AllAutomationContexts;
    if (v === "survey") {
      value = "survey";
    } else {
      value = "return";
    }
  }
  return value;
};
