import { memo, useCallback, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Dropdown } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import dots from "src/assets/images/sixDots.png";
import { FormElementOption } from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItemsFormElementOptions.service";
import {
  FollowUpReturnReason,
  ReasonFollowUpQuestion,
} from "../../../../store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";
import styles from "./QuestionModal.module.scss";

export interface QuestionModalProps {
  reason: FollowUpReturnReason;
  onHide: () => void;
  reasonTypeId: string;
  pushCurrentHash: any; //added to show save button on main modal
  saveMoreQuestionHandler: (reasonId: string, reasonTypeId: string) => void;
  reorderMoreQuestionHandler: (endIndex: number, startIndex: number) => void;
  removeMoreQuestionHandler: (moreQuestionId: string) => void;
  updateMoreQuestionTypeHandler: (
    questionId: string,
    type: string,
    isAdditionalOptions: boolean | undefined,
  ) => void;
  updateMoreQuestionValueHandler: (questionId: string, value: string) => void;
  removeMoreQuestionOptionHandler: (
    questionId: string,
    optionIndex: number,
  ) => void;
  updateMoreQuestionOptionHandler: (
    option: string,
    questionId: string,
    optionIndex: number,
  ) => void;
  addMoreQuestionOptionHandler: (option: string, questionId: string) => void;
  addMoreQuestionHandler: () => void;
  formElementOptions: FormElementOption[];
  followUpQuestions: ReasonFollowUpQuestion[];
}

const QuestionModal = ({
  reason,
  onHide,
  reasonTypeId,
  pushCurrentHash,
  formElementOptions,
  followUpQuestions,
  saveMoreQuestionHandler,
  reorderMoreQuestionHandler,
  removeMoreQuestionHandler,
  updateMoreQuestionTypeHandler,
  updateMoreQuestionValueHandler,
  removeMoreQuestionOptionHandler,
  updateMoreQuestionOptionHandler,
  addMoreQuestionOptionHandler,
  addMoreQuestionHandler,
}: QuestionModalProps) => {
  // const dispatch = useAppDispatch();
  const [showError, setShowError] = useState(false);

  const { value: reasonValue } = useMemo(() => reason.reason, [reason]);

  // Function to handle form submission
  const handleSubmit = useCallback(() => {
    let hasError = false;

    // Iterate through each question in the moreQuestions array
    for (const question of followUpQuestions) {
      // const elementIndex = formElementOptions.findIndex(
      //   (option) => option.elementId === Number(question.elementTypeId),
      // );

      // Check if question is empty
      if (!question.elementValue || question.elementValue.trim().length === 0) {
        hasError = true;
        break;
      }
      const optionsRequired = formElementOptions.find(
        (option) => option.elementId?.toString() === question?.elementTypeId,
      )?.optionsRequired;

      // Check if option or question is empty in case of multiple choice/dropdown
      if (
        optionsRequired &&
        (question.additionalOptions.length === 0 ||
          question.additionalOptions.some((option) => !option.value.trim()))
      ) {
        hasError = true;
        break;
      }
    }

    // Show error if there is any
    if (hasError) {
      setShowError(true);
    } else {
      // After all fields are filled, save and hide the modal
      saveMoreQuestionHandler(reason.id, reasonTypeId);
      pushCurrentHash(); //added to show save button on change in main modal
      onHide();
    }
  }, [
    followUpQuestions,
    saveMoreQuestionHandler,
    reason.id,
    reasonTypeId,
    pushCurrentHash,
    onHide,
  ]);

  return (
    <>
      <div
        className={`d-flex align-items-start w-100 ${styles.contentWrapper}`}
      >
        {/* Back Arrow */}
        <div>
          <span
            className={`pe-3 me-1 d-block ${styles.leftArrow}`}
            onClick={onHide}
          >
            <i className="fa-solid fa-angle-left"></i>
          </span>
        </div>

        <div className="w-100">
          {/* Header */}
          <h3 className={`d-flex mb-0 align-items-center ${styles.heading}`}>
            <span className="pe-3 d-block">Add more questions :</span>
          </h3>
          <div>
            {/* Title */}
            <p className={`${styles.para}`}>
              Return/exchange reason : {reasonValue} :
            </p>

            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) return;
                reorderMoreQuestionHandler(
                  result.destination.index,
                  result.source.index,
                );
              }}
            >
              <>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {/* Question */}
                      {followUpQuestions &&
                        followUpQuestions.length > 0 &&
                        followUpQuestions.map((question, idx) => {
                          const elementIndex = formElementOptions.findIndex(
                            (option) =>
                              option.elementId ===
                              Number(question.elementTypeId),
                          );
                          const optionsRequired = formElementOptions.find(
                            (option) =>
                              option.elementId?.toString() ===
                              question?.elementTypeId,
                          )?.optionsRequired;

                          return (
                            <Draggable
                              key={question.elementId}
                              draggableId={question.elementId}
                              index={idx}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className={`pe-3 py-3 mb-3 ${styles.shadowBox}`}
                                  >
                                    <div className="d-flex justify-content-between mb-3">
                                      <div>
                                        <img
                                          src={dots}
                                          className={`${styles.dots}`}
                                          role="button"
                                          alt=""
                                        />
                                        <span
                                          className={`ps-2 ${styles.shadowText}`}
                                        >
                                          {`${formElementOptions[elementIndex].elementLabel} type question`}
                                        </span>
                                      </div>
                                      <span
                                        className={`${styles.delIcon}`}
                                        role="button"
                                        onClick={() => {
                                          removeMoreQuestionHandler(
                                            question.elementId,
                                          );
                                          setShowError(false);
                                        }}
                                      >
                                        <i className="fa-solid fa-trash"></i>
                                      </span>
                                    </div>
                                    <div className="mt-2">
                                      {/* Dec 20 Changed dropdown as Dropdown as previous one is not customizable as per xd and z-index issue */}
                                      <Dropdown
                                        bsPrefix="W-100"
                                        onSelect={(
                                          selectedKey: string | null,
                                        ) => {
                                          if (selectedKey) {
                                            const selectedElementId =
                                              selectedKey;
                                            updateMoreQuestionTypeHandler(
                                              question.elementId,
                                              selectedElementId.toString(),
                                              optionsRequired,
                                            );
                                            setShowError(false);
                                            // pushCurrentHash();
                                          }
                                        }}
                                      >
                                        <Dropdown.Toggle
                                          as="div"
                                          id="dropdown-basic"
                                          bsPrefix={`dropdown-toggle ${styles.dropBtn}`}
                                          role="button"
                                        >
                                          {
                                            formElementOptions[elementIndex]
                                              ?.elementLabel
                                          }
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                          bsPrefix={`dropdown-menu ${styles.dropMenu}`}
                                          defaultValue={
                                            formElementOptions[elementIndex]
                                              ?.elementId
                                          }
                                        >
                                          {formElementOptions.map((option) => (
                                            <Dropdown.Item
                                              key={option.elementId}
                                              eventKey={option.elementId.toString()}
                                              className={`p-2 ${
                                                option.elementId.toString() ===
                                                formElementOptions[
                                                  elementIndex
                                                ]?.elementId.toString()
                                                  ? "selected"
                                                  : ""
                                              }`}
                                            >
                                              {option.elementLabel}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <div className="mt-2">
                                        <Form.Control
                                          type="text"
                                          placeholder="Was the order properly packed at the time of delivery? "
                                          className={`${styles.formSelect}`}
                                          value={question.elementValue}
                                          onChange={(event) => {
                                            const selectedValue =
                                              event.target.value;
                                            updateMoreQuestionValueHandler(
                                              question.elementId,
                                              selectedValue,
                                            );
                                            setShowError(false);
                                            // pushCurrentHash(); //added to show save button on change in main modal
                                          }}
                                        />
                                        {/* Added to show error message when feild is empty */}
                                        {showError &&
                                          question.elementValue.length ===
                                            0 && (
                                            <div
                                              className={`${styles.dangerText}`}
                                            >
                                              *Field cannot be empty
                                            </div>
                                          )}
                                        {/* Options */}
                                        {optionsRequired && (
                                          <>
                                            {/* Added validation when no additional option is added */}
                                            {showError &&
                                              question.elementValue.length >
                                                0 &&
                                              question.additionalOptions
                                                .length === 0 && (
                                                <div
                                                  className={`${styles.dangerText}`}
                                                >
                                                  *Add Atleast one option
                                                </div>
                                              )}
                                            {question.additionalOptions &&
                                              question.additionalOptions
                                                .length > 0 &&
                                              question.additionalOptions.map(
                                                (option, idx) => {
                                                  return (
                                                    <div className="mb-2 ms-3 mt-3">
                                                      <div className="d-flex justify-content-between">
                                                        <Form.Label
                                                          className={`${styles.formLabel}`}
                                                        >
                                                          Answer option{" "}
                                                          {idx + 1}
                                                        </Form.Label>
                                                        <span
                                                          className={`${styles.delIcon}`}
                                                          role="button"
                                                          onClick={() => {
                                                            removeMoreQuestionOptionHandler(
                                                              question.elementId,
                                                              idx,
                                                            );
                                                            setShowError(false);
                                                          }}
                                                        >
                                                          <i className="fa-solid fa-trash"></i>
                                                        </span>
                                                      </div>
                                                      <div className="d-flex align-items-center">
                                                        <div
                                                          className={`${styles.line}`}
                                                        ></div>
                                                        <Form.Control
                                                          type="text"
                                                          placeholder={`Option ${
                                                            idx + 1
                                                          }`}
                                                          className={`${styles.formSelect}`}
                                                          value={option.value}
                                                          onChange={(event) => {
                                                            updateMoreQuestionOptionHandler(
                                                              event.target
                                                                .value,
                                                              question.elementId,
                                                              idx,
                                                            );
                                                            setShowError(false);
                                                            // pushCurrentHash(); //added to show save button on change in main modal
                                                          }}
                                                        />
                                                      </div>
                                                      <div>
                                                        {/* Added to show error message when feild is empty */}
                                                        {showError &&
                                                          option.value
                                                            .length === 0 && (
                                                            <span
                                                              className={`${styles.dangerText} mx-3`}
                                                            >
                                                              *Field cannot be
                                                              empty
                                                            </span>
                                                          )}
                                                      </div>
                                                    </div>
                                                  );
                                                },
                                              )}
                                            <div
                                              className={`d-flex align-items-center pt-2 ${styles.addWrapper}`}
                                              role="button"
                                              onClick={() => {
                                                addMoreQuestionOptionHandler(
                                                  "",
                                                  question.elementId,
                                                );
                                                setShowError(false);
                                              }}
                                            >
                                              <div
                                                className={`${styles.addBtn}`}
                                              >
                                                <span>+</span>
                                              </div>
                                              <span
                                                className={`ps-2 ${styles.addText}`}
                                              >
                                                more options
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                {/* Add More Question Button */}
                <div
                  className="d-flex align-items-center pt-2"
                  role="button"
                  onClick={() => {
                    addMoreQuestionHandler();
                    setShowError(false);
                  }}
                >
                  <div className={`${styles.addBtn}`}>
                    <span>+</span>
                  </div>
                  <span className={`ps-2 ${styles.addText}`}>
                    Add more question
                  </span>
                </div>
              </>
            </DragDropContext>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className={`d-flex justify-content-end ${styles.btnWrapper}`}>
        <button
          className={`${styles.cancelBtn}`}
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className={`ms-3 ${styles.saveBtn}`}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default memo(QuestionModal);
