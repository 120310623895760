import {
  SegmentMessage,
  SegmentMessageType,
  SendNewMessageParams,
} from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import { SendMessageState } from "./useSendMessageContext";

/**
 * Generates a SegmentMessage based on the provided message type.
 *
 * @param type - The type of message to generate (either "chat" or "email").
 * @returns A SegmentMessage object corresponding to the specified type.
 * @throws Error if the type is unrecognized or not handled.
 */
export const generateSegmentMessage = (
  type: SegmentMessageType,
): SegmentMessage => {
  switch (type) {
    // Handle the case for chat messages
    case SegmentMessageType.Chat:
      return { type, message: "" }; // Returns a chat message structure

    // Handle the case for email messages
    case SegmentMessageType.Email:
      return {
        type,
        emailHeading: "", // Placeholder for email heading
        logoAttachment: "", // Placeholder for logo attachment
        from: "", // Placeholder for the sender's email address
        message: "", // Placeholder for the email body message
        subject: "", // Placeholder for the email subject line
      };

    // Default case to ensure all types are handled
    default:
      const exhaustiveCheck: never = type; // Ensures type is exhaustive
      throw new Error(`Unhandled message type: ${exhaustiveCheck}`); // Throws an error for unhandled types
  }
};

/**
 * Validates a SegmentMessage to ensure all required fields are filled based on the message type.
 *
 * @param segmentMessage - The SegmentMessage object to validate.
 * @returns A boolean indicating whether the SegmentMessage is valid (true) or not (false).
 * @throws Error if the message type is unrecognized or not handled.
 */
const validateSegmentMessage = (segmentMessage: SegmentMessage): boolean => {
  const messageType = segmentMessage.type;

  switch (messageType) {
    case SegmentMessageType.Email:
      // Check if required fields for email are non-empty
      if (
        !segmentMessage.message.trim() || // Validate message body
        !segmentMessage.emailHeading?.trim() || // Validate email heading
        !segmentMessage.from?.trim() || // Validate sender's address
        !segmentMessage.subject?.trim() || // Validate subject
        !segmentMessage.logoAttachment.trim() // Validate Logo attachment
      ) {
        return false;
      }

      return true;
    case SegmentMessageType.Chat:
      // For chat, only the message field is required
      if (!segmentMessage.message.trim()) {
        return false;
      }

      return true;
    // Default case to ensure all types are handled
    default:
      const exhaustiveCheck: never = messageType; // Ensures type is exhaustive
      throw new Error(`Unhandled message type: ${exhaustiveCheck}`); // Throws an error for unhandled types
  }
};

/**
 * Generates and validates a payload based on the current SendMessageState.
 *
 * @param state - The current SendMessageState object containing messages and segment IDs.
 * @returns A SendNewMessageParams object containing validated messages and segment IDs, or null if validation fails.
 */
export const generateAndValidatePayload = (
  state: SendMessageState,
): SendNewMessageParams | null => {
  const res: SendNewMessageParams = { messages: [], segmentIds: [] };

  // Validate segmentIds - if no segments are selected, return null
  if (!state.segmentIds || state.segmentIds.length === 0) {
    return null;
  }

  // Populate segmentIds in the payload
  res.segmentIds = [...state.segmentIds];

  // Validate messages - if there are no messages, return null
  if (!state.messages || state.messages.length === 0) {
    return null;
  }

  // Filter out messages based on selectedTypes
  res.messages = state.messages.filter((val) =>
    state.selectedTypes.includes(val.type),
  );

  // Ensure all selected message types have corresponding messages
  if (state.selectedTypes.length !== res.messages.length) {
    return null;
  }

  // Validate each message in the payload
  for (let i = 0; i < res.messages.length; i++) {
    const message = res.messages[i];

    // Validate message using validateSegmentMessage
    if (!validateSegmentMessage(message)) {
      return null; // If any message fails validation, return null
    }

    // Clean up empty attachmentBatchNumber if present but empty
    if (message.attachmentBatchNumber?.length === 0) {
      delete message.attachmentBatchNumber;
    }
  }

  return res; // Return the validated payload
};
