import { useState } from "react";
import styles from "./EmailPreview.module.scss";
import DesktopView from "../DesktopView/DesktopView";
const EmailPreview = () => {
  const [view, setView] = useState<"Desktop" | "Mobile">("Desktop");
  return (
    <div>
      <div
        className={`${styles.emailPreviewWrapper} d-flex justify-content-center align-item-center flex-column mt-4 pb-2`}
      >
        <div>
          <div
            className={`d-flex flex-md-row align-items-md-center justify-content-center ${styles.btnWrapper}`}
          >
            <button
              className={`mb-2 mb-md-0 ${styles.tabsBtn} ${
                view == "Desktop" && styles.active
              }`}
              onClick={() => setView("Desktop")}
            >
              <span className="pe-1"></span>
              Desktop
            </button>
            <button
              className={`mb-2 mb-md-0 ${styles.mobileTabsBtn} ${
                view == "Mobile" && styles.active
              }`}
              onClick={() => setView("Mobile")}
            >
              <span className="pe-1"></span>
              Mobile
            </button>
          </div>
        </div>
        <div
          className={`${styles.previewWrapper} ${
            view == "Mobile" && styles.mobilePreviewSection
          }`}
        >
          <div className={`py-4 px-4 ${styles.previewSection} `}>
            <DesktopView />
          </div>
          <div className="mt-2 d-none">
            <div className="mb-1">
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label mb-1 ${styles.emailLabel}`}
              >
                Enter email address to see preview.
              </label>
              <input
                type="email"
                className={`form-control ${styles.emailInput}`}
                id="exampleFormControlInput1"
                placeholder="Example@gmail.com"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <button className={`px-1 ${styles.sendBtn}`}>
                Send test email
              </button>
              <button className={`${styles.saveBtn}`}>Save and next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailPreview;
