import styles from "./Upload.module.scss";
import logo from "src/assets/images/dummyLogo.png";
import imgUpload from "src/assets/images/imgUpload.png";
const Upload = () => {
  return (
    <div className={`${styles.uploadWrapper}`}>
      <h5 className={`${styles.heading}`}>Upload your logo:</h5>
      <div className={`p-2 ${styles.uploadBox}`}>
        <div className="d-none">
          <span className={`d-block ${styles.removeText}`}>Remove logo</span>
          <div className="text-center">
            <img
              src={logo}
              alt="logo"
              width={61}
              height={59}
              className="mx-auto"
            />
          </div>
        </div>
        <div className="text-center">
          <img
            src={imgUpload}
            alt="logo"
            width={71}
            className="mx-auto"
          />
          <p className={`mb-1 mt-1 ${styles.dropText}`}>
            Drop your logo here, or browse
          </p>
          <span className={`d-block ${styles.sizeLimit}`}>
            Maximum image size 5Mb 200 x 230 pixels
          </span>
          <h6 className={`${styles.supports}`}>Supports: JPG,JPEG,PNG</h6>
        </div>
      </div>
      <button className={`mt-2 ${styles.changeBtn}`}>Change logo</button>
    </div>
  );
};

export default Upload;
