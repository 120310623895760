import { useCallback } from "react";
import Loader from "src/components/Loader";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import useFetchConditionOptions from "../../hooks/useFetchConditionOptions";
import { useUpdateSegmentDetails } from "../../hooks/useUpdateSegmentDetails";
import styles from "../../UpdateSegmentDetails.module.scss";
import Condition from "../Condition/Condition";
import ConditionOptions from "../ConditionOptions/ConditionOptions";
import LogicalOperator from "../LogicalOperator/LogicalOperator";

const SegmentGroup = () => {
  const { segmentDataForUpdate } = useCustomerSegments();
  const { updateSegmentDetails, dispatch } = useUpdateSegmentDetails();
  const {
    conditionOptions,
    conditionOptionsStatus,
    hasNextPage,
    fetchNextPage,
  } = useFetchConditionOptions(
    segmentDataForUpdate?.segmentType ?? "",
    segmentDataForUpdate?.segmentId,
  );

  const fetchMoreConditionOptions = useCallback(() => {
    if (hasNextPage && conditionOptionsStatus !== "pending") {
      fetchNextPage();
    }
  }, [conditionOptionsStatus, fetchNextPage, hasNextPage]);

  return (
    <div>
      <p className={`${styles.conditionText}`}>Conditions</p>
      {(conditionOptionsStatus === "pending" && !conditionOptions) ||
      updateSegmentDetails.fetchingSegmentDetails === "pending" ? (
        <Loader />
      ) : conditionOptionsStatus === "rejected" ? (
        <div className="text-danger align-items-center justify-content-center d-flex">
          Error loading content...
        </div>
      ) : updateSegmentDetails.fetchingSegmentDetails === "rejected" ? (
        <div className="text-danger align-items-center justify-content-center d-flex">
          Error fetching segment details...
        </div>
      ) : (
        updateSegmentDetails.segmentDetails.groupConditions?.map(
          (group, groupIndex) => {
            const isError =
              updateSegmentDetails.showErrors && group.conditions.length === 0;
            return (
              <div key={group.groupUUID}>
                {/* Conditions */}
                <div
                  className={`d-flex flex-column flex-md-row mb-2 ${
                    styles.conditionBox
                  } ${isError && styles.errBorder}`}
                >
                  <div className={`${styles.leftSection}`}>
                    {conditionOptions?.defaultConditions &&
                      conditionOptions?.defaultConditions?.length !== 0 && (
                        <ConditionOptions
                          conditionOptionType={"default"}
                          groupIndex={groupIndex}
                          conditionOptions={conditionOptions.defaultConditions}
                        />
                      )}
                    {conditionOptions?.customeConditions &&
                      conditionOptions?.customeConditions?.length !== 0 && (
                        <ConditionOptions
                          conditionOptionType={"custom"}
                          groupIndex={groupIndex}
                          conditionOptions={conditionOptions.customeConditions}
                          fetchMoreConditionOptions={fetchMoreConditionOptions}
                          conditionOptionsStatus={conditionOptionsStatus}
                          hasNextPage={hasNextPage}
                        />
                      )}
                  </div>
                  <div
                    className={`${styles.rightSection} ${
                      (updateSegmentDetails.segmentDetails.groupConditions
                        ?.length ?? 0) > 1
                        ? "p-0"
                        : "p-2"
                    }`}
                  >
                    {(updateSegmentDetails.segmentDetails.groupConditions
                      ?.length ?? 0) > 1 && (
                      <div className="d-flex align-items-center justify-content-end">
                        <span
                          className={`${styles.closeBtn} d-flex align-items-center justify-content-center`}
                          onClick={() => {
                            dispatch("deleteSegmentGroup", group.groupUUID);
                          }}
                          id={`close_${group.groupUUID}`}
                        >
                          <i className="fa fa-solid fa-xmark" />
                        </span>
                      </div>
                    )}
                    {group.conditions.map((condition, conditionIndex) => {
                      return (
                        <Condition
                          key={condition.conditionUUID}
                          condition={condition}
                          conditionIndex={conditionIndex}
                          groupIndex={groupIndex}
                        />
                      );
                    })}
                  </div>
                </div>
                {/* Group related operations */}
                {isError && (
                  <div className={styles.errText}>
                    {"Please fill the condition details."}
                  </div>
                )}
                <div className="text-center my-3">
                  {group.nextGroupUUID && group.logicalOperator ? (
                    <div
                      className={`d-flex align-items-center justify-content-center ms-5 mb-2`}
                    >
                      <LogicalOperator
                        selectedValue={group.logicalOperator}
                        onChange={(value) => {
                          dispatch("updateSegmentGroup", {
                            data: { logicalOperator: value },
                            index: groupIndex,
                          });
                        }}
                      />
                    </div>
                  ) : updateSegmentDetails.segmentDetails.groupConditions
                      ?.length ? (
                    <button
                      className={`${styles.addGroupBtn}`}
                      onClick={(e) => {
                        e.preventDefault();
                        if (group.conditions.length > 0) {
                          dispatch("addSegmentGroup", groupIndex);
                        } else {
                          dispatch("setUpdateSegmentDetails", {
                            showErrors: true,
                          });
                        }
                      }}
                      id={`${group.groupUUID}`}
                    >
                      Add group condition
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          },
        )
      )}
    </div>
  );
};

export default SegmentGroup;
