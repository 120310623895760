import { useEffect, useMemo, useState } from "react";
import AttrTableHeader from "./AttrTableHeader/AttrTableHeader";
import AttrTableRows from "./AttrTableRows/AttrTableRows";
import styles from "./MapAttribute.module.scss";
import {
  ConfigureCSVTableMappedAttributes,
  useUploadNewCsvModalContext,
} from "../Hooks/useUploadNewCsvModalContext";
import { useQuery } from "@tanstack/react-query";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import Loader from "src/components/Loader";
import { useSegmentView } from "../../../hooks/useSegmentView";
import getAllCustomers, {
  GetAllCustomersParams,
} from "src/services/CustomerSegments/UploadCSV/getAllCustomers.service";

/**
 * Custom hook to fetch and format Cutomers information for the modal.s
 *
 * This hook uses `useQuery` to fetch Cutomers data and formats it into a structured
 * object suitable for displaying product details in the modal.
 *
 * @param {GetAllCustomersParams} params - Parameters required to fetch the Cutomers information.
 *
 * @returns object - An object containing the formatted customer details, loading state, and error state.
 */
const useGetAllCustomers = (
  params: GetAllCustomersParams,
  activeSegmentType: string,
) => {
  const { data, isLoading, isError } =
    useQuery<ConfigureCSVTableMappedAttributes>({
      queryKey: [
        "getAllCutomers",
        params.start,
        params.limit,
        activeSegmentType,
      ],
      queryFn: () =>
        getAllCustomers({
          params: { ...params },
          activeSegmentType: activeSegmentType,
        }),
    });

  return {
    data: data,
    isLoading,
    isError,
  };
};

const MapAttribute = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { state, updateState } = useUploadNewCsvModalContext();
  const limit = 8;
  const { activeSegmentDetails } = useSegmentView();

  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return limit * currentPage - limit;
  }, [currentPage]);

  const params: GetAllCustomersParams = {
    start: start,
    limit: limit,
    attachmentId: state?.uploadCSV?.attachmentId ?? "",
  };

  const { isError, isLoading, data } = useGetAllCustomers(
    params,
    activeSegmentDetails.activeSegmentType ?? "",
  );
  useEffect(() => {
    if (data) {
      updateState({
        mapAttributes: {
          ...data,
          updatedTableHeadingIds: state.mapAttributes?.updatedTableHeadingIds
            ? state.mapAttributes?.updatedTableHeadingIds
            : data.updatedTableHeadingIds,
        },
      });
    }
  }, [data]);

  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return data?.totalCustomers ? Math.ceil(data?.totalCustomers / limit) : 0;
  }, [data?.totalCustomers]);

  return (
    <div>
      <h4 className={`${styles.heading}`}>Map attributes</h4>
      <p className={`${styles.subHeading}`}>
        Please review matching column titles and make necessary changes using
        the dropdowns.
      </p>
      {isLoading ? (
        <div className={`p-3 ${styles.settingWrap}`}>
          <Loader />
        </div>
      ) : isError ? (
        <div className={`p-3 ${styles.settingWrap}`}>
          <div className="d-flex align-items-center justify-content-center text-danger">
            {"Something went wrong!"}
          </div>
        </div>
      ) : (
        <div>
          <div className={`mt-4 mb-1 ${styles.container}`}>
            <div className="w-100">
              <div className={`d-flex`}>
                <AttrTableHeader />
              </div>
              <AttrTableRows
                customers={data?.customers ?? {}}
                customerIds={data?.customerIds ?? []}
              />
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default MapAttribute;
