import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { ActionDispatch, AJAXSTATUS } from "src/globals/constants";
import {
  SegmentMessageType,
  SendNewMessageParams,
} from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import { generateSegmentMessage } from "./sendMessage.helpers";
import useFetchMessageTypes from "./useFetchMessageTypes";
import { VariablesData } from "src/services/CustomerSegments/NewMessage/getMessageTypes.service";

export interface SendMessageState extends SendNewMessageParams {
  selectedTypes: SegmentMessageType[];
  showErrors: boolean;
  sendMessageStatus: AJAXSTATUS;
  allMessageTypes: SegmentMessageType[];
  messageTypesFetchStatus: AJAXSTATUS;
  variables: VariablesData[];
}

const initialState: SendMessageState = {
  messages: [],
  segmentIds: [],
  selectedTypes: [],
  showErrors: false,
  sendMessageStatus: "idle",
  allMessageTypes: [],
  messageTypesFetchStatus: "pending",
  variables: [],
};

/**
 * Reducer function to manage the state for sending messages.
 *
 * @param state - The current state of SendMessageState.
 * @param action - An object containing the action type and optional payload.
 *    - type: A key from SendMessageAction representing the action to perform.
 *    - payload: Optional data needed to perform the action.
 *
 * @returns The updated state after applying the action.
 */
const reducerMethod = (
  state: SendMessageState,
  action: { type: keyof typeof SendMessageAction; payload?: any },
): SendMessageState => {
  // Retrieve the action method based on the action type
  const actionMethod = SendMessageAction[action.type];

  // If the action method exists, call it with the current state and payload
  if (actionMethod) {
    actionMethod(state, action.payload);
  }

  // Spread state to ensure immutability before returning the updated state
  return { ...state };
};

/**
 * Create a context for sending messages with the current state and dispatch function.
 */
const SendMessageContext = createContext<
  [SendMessageState, ActionDispatch<typeof SendMessageAction>]
>([initialState, () => {}]);

/**
 * Custom hook to provide state and dispatch function for sending messages.
 *
 * @returns An array containing the current state, a modified dispatch function, and the SendMessageContext provider.
 */
export const useCreateSendMessageProvider = () => {
  const { messageTypes, messageTypesFetchStatus, variables } =
    useFetchMessageTypes();

  const [state, dispatch] = useReducer(reducerMethod, { ...initialState });

  // Create a modified dispatch function that wraps the original dispatch with action and payload
  const modifiedDispatch: ActionDispatch<typeof SendMessageAction> =
    useCallback(
      (action, payload) => {
        dispatch({ type: action, payload }); // Dispatch the action with the payload
      },
      [dispatch],
    );

  useMemo(() => {
    modifiedDispatch("updateState", {
      allMessageTypes: messageTypes,
      messageTypesFetchStatus,
      variables,
    });
  }, [messageTypes, messageTypesFetchStatus, modifiedDispatch, variables]);

  return [state, modifiedDispatch, SendMessageContext.Provider] as [
    SendMessageState,
    ActionDispatch<typeof SendMessageAction>,
    typeof SendMessageContext.Provider,
  ];
};

/**
 * Custom hook to access the SendMessageContext.
 *
 * @throws Will throw an error if used outside of a SendMessageContext.Provider.
 * @returns The context value, which includes the current state and dispatch function.
 */
export const useSendMessage = () => {
  const context = useContext(SendMessageContext);

  if (!context) {
    throw new Error(
      "useSendMessageContext must be used within a MessageModalContext",
    );
  }
  return { state: context[0], dispatch: context[1] };
};

const SendMessageAction = {
  /**
   * Resets the state of messages, segment IDs, and selected message types to their initial values.
   *
   * @param state - The current state of SendMessageState.
   */
  resetState: (state: SendMessageState) => {
    state.messages = [];
    state.segmentIds = [];
    state.selectedTypes = [];
    state.showErrors = false;
    state.sendMessageStatus = "idle";
  },

  updateState: (
    state: SendMessageState,
    payload: Partial<SendMessageState>,
  ) => {
    if (payload.showErrors !== undefined) {
      state.showErrors = payload.showErrors;
    }

    if (payload.sendMessageStatus !== undefined) {
      state.sendMessageStatus = payload.sendMessageStatus;
    }
  },

  /**
   * Updates the segment IDs based on the specified action.
   *
   * @param state - The current state of SendMessageState.
   * @param payload - An object containing the data and action type.
   *    - data: An array of segment IDs to be processed.
   *    - action: A string that can be "create", "update", or "delete".
   */
  updateSegmentId: (
    state: SendMessageState,
    payload: { data: string[]; action: "create" | "update" | "delete" },
  ) => {
    const { data, action } = payload;

    switch (action) {
      case "create":
        // Replace the current segment IDs with unique values from the provided data
        state.segmentIds = [...new Set(data)];
        break;
      case "update":
        // Update the existing segment IDs with unique values from the provided data
        state.segmentIds = [...new Set([...state.segmentIds, ...data])];
        break;
      case "delete":
        // Remove any segment IDs present in the provided data from the current state
        state.segmentIds = state.segmentIds.filter(
          (val) => !data.includes(val),
        );
        break;
    }
  },

  /**
   * Updates the selected message type and handles the addition/removal of messages accordingly.
   *
   * @param state - The current state of SendMessageState.
   * @param payload - An object containing the message type to update and an optional flag to remove the message on deselection.
   *    - messageType: The type of message to update (either "chat" or "email").
   *    - removeOnDeselect: A boolean indicating whether to remove the message if the type is deselected (default is false).
   */
  updateMessageType: (
    state: SendMessageState,
    {
      messageType,
      removeOnDeselect = false,
    }: { messageType: SegmentMessageType; removeOnDeselect?: boolean },
  ) => {
    const messageExists = state.messages.some(
      (val) => val.type === messageType,
    );

    if (state.selectedTypes.includes(messageType)) {
      // Deselect the message type if it is already selected
      state.selectedTypes = state.selectedTypes.filter(
        // Remove the message type from selected types
        (val) => val !== messageType,
      );

      // If the message type is deselected, you might want to delete its message.
      if (messageExists && removeOnDeselect) {
        state.messages = state.messages.filter(
          // Remove the message of the deselected type
          (val) => val.type !== messageType,
        );
      }
    } else {
      // Select the message type if it is not already selected
      state.selectedTypes = [...state.selectedTypes, messageType];

      // Generate and add the message to the state if it doesn't exist
      if (!messageExists) {
        state.messages = [
          ...state.messages,
          // Create a new message based on the message type
          generateSegmentMessage(messageType),
        ];
      }
    }
  },
} as const;
