import { useCallback, useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IVariableB } from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";
import { isHTMLEmpty, replaceSpanWithKeyB, trimPDIVBR } from "src/utils/utils";

interface Props {
  referenceId: number;
  data: {
    salutation: string;
    introduction: string;
    questionRegardingResolution: string;
  };
  handleEmailModalDataChange: (key: string, value: any) => void;
  variablesList?: {
    [x: string]: IVariableB;
  };
}

/**
 * Custom Hook for managing Configure Email Modal
 */
const useConfigureEmailTemplate = ({
  referenceId,
  data,
  handleEmailModalDataChange,
  variablesList,
}: Props) => {
  // Save Modal States
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState<
    "Save" | "Saved!" | "Saving..."
  >("Save");
  const [showError, setShowError] = useState(false);

  const [editedData, setEditedData] = useState(
    data ?? {
      salutation: "",
      introduction: "",
      questionRegardingResolution: "",
    },
  );

  /**
   * Effect for initializing edited data
   */
  useEffect(() => {
    if (data) {
      setEditedData(data);
    }
  }, [data]);

  /**
   * Handler for saving modal
   */
  const handleSubmit = useCallback(async () => {
    // Check if introduction or salutation or questionRegardingResolution is empty
    // If yes , then show error and return
    if (
      isHTMLEmpty(trimPDIVBR(editedData?.introduction?.trim() ?? "")) ||
      isHTMLEmpty(trimPDIVBR(editedData?.salutation?.trim() ?? "")) ||
      isHTMLEmpty(
        trimPDIVBR(editedData?.questionRegardingResolution?.trim() ?? ""),
      )
    ) {
      setShowError(true);
      return;
    }
    // If all feilds are filled, check if bot profile id is valid
    if (referenceId) {
      try {
        // Format payload -replace spans with keys
        const modalData = {
          introduction: replaceSpanWithKeyB(
            editedData?.introduction ?? "",
            variablesList ?? {},
          ),
          salutation: replaceSpanWithKeyB(
            editedData?.salutation ?? "",
            variablesList ?? {},
          ),
        };

        //Show loader and set saving.. text
        setShowSaveLoader(true);
        setSaveButtonText("Saving...");
        // Api call
        // await handleSave({
        //   id: referenceId,
        //   email_configure_model: {
        //     introduction: modalData.introduction,
        //     salutation: modalData.salutation,
        //     questionRegardingResolution:
        //       editedData?.questionRegardingResolution ?? "",
        //   },
        // });
        setSaveButtonText("Saved!");
        pushTheToast({
          position: "top-right",
          text: "Saved Successfully !",
          type: "success",
        });
        handleEmailModalDataChange("emailConfigureModel", {
          ...editedData,
        });
        // change Updated Status to show tick mark once it is saved
        handleEmailModalDataChange("emailConfigureModelUpdated", true);
      } catch (e) {
        pushTheToast({
          position: "top-right",
          text: "Something went wrong !",
          type: "danger",
        });
        setSaveButtonText("Save");
        console.error(e);
      } finally {
        setShowSaveLoader(false);
      }
    }
  }, [editedData, handleEmailModalDataChange, referenceId, variablesList]);

  /**
   * Handler to update editedData on change of any field
   */
  const onChange = useCallback(
    (
      key:
        | "salutation"
        | "introduction"
        | "questionRegardingResolution"
        | "heading"
        | "body",
      value: string,
    ) => {
      setEditedData((prevData) => {
        if (prevData) {
          return {
            ...prevData,
            [key]: value,
          };
        }
        return prevData;
      });
      setSaveButtonText("Save");
    },
    [],
  );

  return {
    handleSubmit,
    showSaveLoader,
    saveButtonText,
    data,
    onChange,
    showError,
    editedData,
  };
};

export default useConfigureEmailTemplate;
