import { useCallback, useEffect, useRef } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import createSegmentService, {
  SegmentDetails,
} from "src/services/CustomerSegments/createSegment.service";
import updateSegmentService from "src/services/CustomerSegments/updateSegment.service";
import { deepCompare } from "src/utils/utils";
import { useSegmentView } from "../../../SegmentView/hooks/useSegmentView";
import styles from "../../UpdateSegmentDetails.module.scss";
import { useUpdateSegmentDetails } from "../../hooks/useUpdateSegmentDetails";
import { validateUpdatePayloadConditions } from "../../utils/validations";

/**
 * Custom hook for saving or updating segment details.
 *
 * @returns Object containing segment details and functions to handle discard and save actions.
 */
const useSaveUpdateSegment = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { fetchMoreSegmentView, dispatch: dispatchSV } = useSegmentView();

  // Access segment data and dispatch functions from the useCustomerSegments hook.
  const {
    segmentDataForUpdate,
    onHideUpdateSegmentModal,
    dispatch: csDispatch,
  } = useCustomerSegments();

  // Access segment update details and dispatch function from the useUpdateSegmentDetails hook.
  const { updateSegmentDetails, dispatch } = useUpdateSegmentDetails();

  /**
   * Validates the payload for updating the segment.
   *
   * @returns Validated payload or null if validation fails.
   */
  const validatePayload = useCallback(() => {
    // Create a payload object excluding 'segmentId' and 'segmentType'.
    const payload: Omit<SegmentDetails, "segmentId" | "segmentType"> = {
      segmentName: updateSegmentDetails.segmentDetails.segmentName ?? "",
      segmentDescription:
        updateSegmentDetails.segmentDetails.segmentDescription ?? "",
      groupConditions: JSON.parse(
        JSON.stringify(
          updateSegmentDetails.segmentDetails.groupConditions ?? [],
        ),
      ),
    };

    let error = false;

    // Validate the segment name.
    if (!payload.segmentName.trim()) {
      error = true;
    }

    // Validate the segment description.
    if (!payload.segmentDescription.trim()) {
      error = true;
    }

    // Validate each group condition.
    for (let i = 0; i < payload.groupConditions.length; i++) {
      const group = payload.groupConditions[i];

      // Check if the group has any conditions.
      if (group.conditions.length) {
        for (let j = 0; j < group.conditions.length; j++) {
          const condition = group.conditions[j];

          // Validate condition's compare type.
          if (!condition.compareType) {
            error = true;
          } else {
            // Validate condition values.
            const ret = validateUpdatePayloadConditions(condition);
            if (ret?.error) {
              error = true;
            }
            if (ret?.value !== undefined) {
              condition.value = ret.value;
            }

            // Remove unnecessary fields.
            delete condition.fieldDataType;
            delete condition.valuesOfSelectKeys;
            delete condition.valueIndex;
            delete condition.maxLimit;
            delete condition.minLimit;
          }
        }
      } else {
        error = true;
      }
    }

    // If there are any errors, dispatch to show errors.
    if (error) {
      dispatch("setUpdateSegmentDetails", { showErrors: true });
      return null;
    } else {
      return payload;
    }
  }, [dispatch, updateSegmentDetails]);

  /**
   * Handles the discard action.
   */
  const handleDiscard = useCallback(() => {
    // Hide the update segment modal.
    onHideUpdateSegmentModal();
    // Reset the update segment state.
    dispatch("resetState");
  }, [dispatch, onHideUpdateSegmentModal]);

  /**
   * Fetches segment view data after saving the edited segment data.
   */
  const fetchAfterSaving = useCallback(() => {
    // Resets segment view and fetches updated data
    dispatchSV("setSegmentView", {
      segmentValues: {},
      segmentValueIds: [],
      hasMoreSegmentView: false,
    });

    // Need to put it in set time out because untill this function will stop execution the state will be stale.
    setTimeout(() => {
      fetchMoreSegmentView();
    }, 0);
  }, [dispatchSV, fetchMoreSegmentView]);

  useEffect(() => {
    const timeout = timerRef.current;

    // Cleanup timeout on unmount or re-render
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const resetSegmentDetailsStatus = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      dispatch("setUpdateSegmentDetails", {
        savingSegmentDetails: "idle",
        showErrors: false,
      });
    }, 3000);
  }, [dispatch]);

  /**
   * Handles the save action for the segment.
   */
  const handleSave = useCallback(() => {
    if (
      // Check if saving is not already in progress.
      updateSegmentDetails.savingSegmentDetails !== "pending" &&
      // Check if segment data is available.
      segmentDataForUpdate &&
      // Check if segment type is defined.
      segmentDataForUpdate.segmentType &&
      // Check if an action is specified.
      segmentDataForUpdate.action
    ) {
      // Validate the payload.
      const payload = validatePayload();
      if (payload !== null) {
        if (
          !deepCompare(
            payload,
            updateSegmentDetails.segmentDetailsSaved,
            // Keys to ignore in comparison.
            [
              "segmentId",
              "segmentType",
              "valuesOfSelectKeys",
              "fieldDataType",
              "valueIndex",
            ],
          )
        ) {
          if (
            // Check if the action is update.
            segmentDataForUpdate.action === "update" &&
            // Check if the segment ID is available.
            segmentDataForUpdate.segmentId
          ) {
            dispatch("setUpdateSegmentDetails", {
              savingSegmentDetails: "pending",
              showErrors: false,
            });
            updateSegmentService({
              segmentType: segmentDataForUpdate.segmentType,
              segmentId: segmentDataForUpdate.segmentId,
              ...payload,
            })
              .then((res) => {
                // First update the segment data.
                dispatch("setUpdateSegmentDetails", {
                  segmentDetails: res,
                  segmentDetailsSaved: JSON.parse(JSON.stringify(res)),
                });

                // Unshift the segment name in sidebar.
                csDispatch("unshiftCustomSegment", {
                  segmentType: res.segmentType,
                  segment: {
                    segmentId: res.segmentId + "",
                    name: res.segmentName,
                    description: res.segmentDescription,
                  },
                });

                // Convert the action to update.
                csDispatch("updateUpdateSegmentDetails", {
                  segmentId: res.segmentId,
                  action: "update",
                });

                // Stop the loader
                dispatch("setUpdateSegmentDetails", {
                  savingSegmentDetails: "fulfilled",
                  showErrors: false,
                });

                resetSegmentDetailsStatus();

                fetchAfterSaving();
              })
              .catch((err) => {
                console.error(err);

                // Update state for showing errors
                dispatch("setUpdateSegmentDetails", {
                  savingSegmentDetails: "rejected",
                });

                pushTheToast({
                  type: "danger",
                  position: "top-right",
                  text: err?.message ?? "Something went wrong!",
                });
              });
          } else if (
            // Check if the action is create.
            segmentDataForUpdate.action === "create" ||
            // Check if the action is duplicate.
            segmentDataForUpdate.action === "duplicate"
          ) {
            dispatch("setUpdateSegmentDetails", {
              savingSegmentDetails: "pending",
              showErrors: false,
            });
            createSegmentService({
              segmentType: segmentDataForUpdate.segmentType,
              ...payload,
            })
              .then((res) => {
                // First update the segment data.
                dispatch("setUpdateSegmentDetails", {
                  segmentDetails: res,
                  segmentDetailsSaved: JSON.parse(JSON.stringify(res)),
                });

                // Unshift the segment name in sidebar.
                csDispatch("unshiftCustomSegment", {
                  segmentType: res.segmentType,
                  segment: {
                    segmentId: res.segmentId + "",
                    name: res.segmentName,
                    description: res.segmentDescription,
                  },
                });

                // Convert the action to update.
                csDispatch("updateUpdateSegmentDetails", {
                  segmentId: res.segmentId,
                  action: "update",
                });

                // Stop the loader
                dispatch("setUpdateSegmentDetails", {
                  savingSegmentDetails: "fulfilled",
                  showErrors: false,
                });

                resetSegmentDetailsStatus();
              })
              .catch((err) => {
                console.error(err);

                // Update state for showing errors
                dispatch("setUpdateSegmentDetails", {
                  savingSegmentDetails: "rejected",
                  showErrors: true,
                });

                pushTheToast({
                  type: "danger",
                  position: "top-right",
                  text: err?.message ?? "Something went wrong!",
                });
              });
          }
        }
      }
    }
  }, [
    updateSegmentDetails,
    segmentDataForUpdate,
    validatePayload,
    dispatch,
    csDispatch,
    resetSegmentDetailsStatus,
    fetchAfterSaving,
  ]);

  // Return the segment details, discard handler, and save handler.
  return { updateSegmentDetails, handleDiscard, handleSave };
};

/**
 * Functional component for rendering action buttons.
 *
 * @returns JSX.Element containing the action buttons.
 */
function ActionBtns() {
  // Use the custom hook to get segment details and handlers.
  const { updateSegmentDetails, handleDiscard, handleSave } =
    useSaveUpdateSegment();

  return (
    <div
      className={`px-3 pt-2 ${styles.btnWrapper} mb-2 ${
        updateSegmentDetails.showErrors ? styles.failWrapper : ""
      }`}
    >
      {/* Button container */}
      <div className="d-flex align-items-center mt-2">
        {/* Discard button */}
        <button
          className={`me-2 ${styles.discardBtn}`}
          onClick={handleDiscard}
          id="segmentDiscardBtn"
        >
          Discard changes
        </button>
        {/* Save button */}
        <button
          className={`${styles.saveBtn}`}
          onClick={handleSave}
          id="segmentSaveBtn"
          disabled={updateSegmentDetails.savingSegmentDetails === "pending"}
        >
          {updateSegmentDetails.savingSegmentDetails === "fulfilled"
            ? "Saved!"
            : updateSegmentDetails.savingSegmentDetails === "pending"
              ? "Saving..."
              : updateSegmentDetails.showErrors ||
                  updateSegmentDetails.savingSegmentDetails === "rejected"
                ? "Save failed"
                : "Save"}
        </button>
      </div>
      {/* Error message */}
      {updateSegmentDetails.showErrors && (
        <div className={`d-flex mb-2 mt-1 ${styles.errText}`}>
          Saving failed. Please review the errors
        </div>
      )}
    </div>
  );
}

export default ActionBtns;
