import styles from "./CernThread.module.scss";
import closeBtn from "src/assets/images/close(3).png";
import InternalNote from "./Children/InternalNote/InternalNote";
import EditEscalation from "./Children/EditEscalation/EditEscalation";
import AvailableTask from "./Children/AvailableTask/AvailableTask";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { Editor as TinyMceEditor } from "tinymce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import sendImg from "src/assets/images/sendIcon.svg";
import ViewTemplateButtonV2 from "src/components/ViewTemplateButtonV2";
import AddNoteActiveIcon from "src/assets/images/note-add-active.png";
import saufter from "src/assets/images/saufter.png";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  setIsCernTyping,
  setShowCernThread,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useParams } from "react-router-dom";
import useFetchCernThread from "./usefetchCernThread";
import { useMutation } from "@tanstack/react-query";
import {
  ISendMessageService,
  sendMessageService,
} from "src/services/SendMessageService";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useCustomMentions from "src/hooks/TinyMCEContainer/useCustomMentions";
import MentionBox from "../SendMessage/children/MentionBox/MentionBox";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import { useSyncedState } from "src/hooks/useSyncedState";
import { Editor } from "tinymce";
import { sendCernThreadMessage } from "src/services/Campaign/sendCernThreadMessage.service";

const CernThread = ({
  messageId,
  ticket_from,
  isCampaign,
  ticketId,
}: {
  messageId?: number | null;
  ticket_from?: string;
  isCampaign?: boolean;
  ticketId?: string;
}) => {
  const editorRef = useRef<TinyMceEditor | null>(null);
  // const [canDeleteInline, setCanDeleteInline] = useState(true);
  const [err, setErr] = useState(false);
  // const [message, setMessage] = useState("");
  const [message, messageRef, setMessage] = useSyncedState("");
  const [showingWhichModal, setShowingWhichModal] = useState<string>(
    "" as "template" | "escalateDraft",
  );
  const { messageData: messageList, isCernTyping } = useAppSelector(
    (state) => state.innerTicket,
  );
  const dispatch = useAppDispatch();

  //attachement state
  const [attachmentUploadData, setAttachmentUploadData] =
    useState<UploadAttachmentRes>();
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const scrollToBottomMsg = useRef<HTMLDivElement>(null);
  const prevScrollHeightRef = useRef(0);
  const [addNewMsg, setAddNewMsg] = useState(false); // to check new message is added or not. If added then scroll div to bottom
  const { cernThread, isLoading, setCernThread, hasNextPage, changePage } =
    useFetchCernThread(messageId);
  const [editorObj, setEditorObj] = useState<Editor>();

  const { fetchAllMentions } = useCustomMentions(ticket_from);

  const customAutoCompleteOptions = useMemo(() => {
    return {
      delimiters: {
        "@": {
          fetchOptions: async () => {
            const data = await fetchAllMentions();
            return data?.filter(
              (mention) => mention.mentionCategoryDetails.key !== "slack",
            );
          },
          renderComponent: (props: any) => (
            <MentionBox
              {...props}
              showCernInstruction={true}
            />
          ),
        },
      },
    };
  }, []);

  useEffect(() => {
    if (editorObj && !messageId) {
      if (customAutoCompleteOptions && customAutoCompleteOptions?.delimiters) {
        editorObj.fire("OpenCustomMentionPlugin", {
          enabled: true,
          autoCompletedData: customAutoCompleteOptions,
        });
      }
    }
  }, [editorObj]);

  // handle comment scroll. Add a new comment will show the latest comment and at the time of open scroll will be bottom
  useEffect(() => {
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTop =
        scrollableDiv.scrollHeight - prevScrollHeightRef.current;
    }
    if (addNewMsg && scrollableDiv) {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      setAddNewMsg(false);
    }
  }, [cernThread]);

  const [isLoadingCernSend, setIsLoadingCernSend] = useState(false);

  const sendMessageHandler = useCallback(
    async (payload: ISendMessageService) => {
      try {
        setIsLoadingCernSend(true);
        const res = await sendCernThreadMessage(payload);
        setCernThread((prev) => {
          return {
            ...prev,
            [res.messageId]: res,
          };
        });
        setSelectedFiles([]); // clear the attachments selected
        setCurrentAttachmentBatchNumber("");
        setMessage("");
        setAddNewMsg(true);
      } catch (e) {
        const err = e as Error;
        pushTheToast({
          text: typeof err === "string" ? err : err.message,
          type: "danger",
          position: "top-right",
        });
      } finally {
        setIsLoadingCernSend(false);
      }
    },
    [setCernThread],
  );

  // api call to add a new comment
  const { mutate, isLoading: sendMsgLoading } = useMutation({
    mutationFn: sendMessageService,
    onSuccess: (data) => {
      setCernThread((prev) => {
        return {
          ...prev,
          [data.messageId]: data,
        };
      });
      setSelectedFiles([]); // clear the attachments selected
      setCurrentAttachmentBatchNumber("");
      setAddNewMsg(true);
    },
    onError: () => {
      dispatch(setIsCernTyping({ isCernTyping: false }));
    },
  });

  // fetch next page data for comment and handle div scroll
  const fetchNextPageHanlder = useCallback(() => {
    changePage();
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      prevScrollHeightRef.current = scrollableDiv.scrollHeight;
    }
  }, [cernThread]);

  const sendMessage = useCallback(() => {
    if (ticketId) {
      if (selectedFiles.length > 0 || message.length > 0) {
        const messageUuid = uuid();
        const payload: ISendMessageService & { attachments?: Array<any> } = {
          ticketId,
          message: message,
          messageType: "NoteBotAiQuery",
          sendAs: "Open",
          parentMessageId: messageId ?? undefined,
          attachmentBatchNumber: selectedFiles
            ? selectedFiles.map(function (value: any, index: any) {
                return value.batchNumber;
              })
            : [],
          uuid: messageUuid,
          channel: "Email",
        };
        dispatch(setIsCernTyping({ isCernTyping: true }));
        setMessage("");
        if (isCampaign) {
          sendMessageHandler(payload);
        } else {
          mutate(payload);
        }
      } else {
        pushTheToast({
          text: "Please add text or attachment!",
          type: "warning",
          position: "top-right",
        });
      }
    }
  }, [
    ticketId,
    selectedFiles,
    message,
    messageId,
    isCampaign,
    sendMessageHandler,
    mutate,
  ]);

  return (
    <div
      className={`${styles.cernThread} ${
        isCampaign ? styles.campaignWrapper : ""
      }`}
    >
      <div
        className={`d-flex justify-content-between align-items-center mb-3 p-3`}
      >
        <p className={`mb-0 ${styles.heading}`}>Cern's thread</p>
        {!isCampaign && (
          <img
            src={closeBtn}
            alt="close button"
            className={`${styles.closeBtn} cursor-pointer`}
            onClick={() => dispatch(setShowCernThread({ show: false }))}
          />
        )}
      </div>
      <div
        className={`${styles.msgContainer} ${
          isCampaign ? styles.campaignContainer : ""
        } d-flex flex-column`}
      >
        {isLoading && Object.keys(cernThread).length === 0 ? (
          <Loader />
        ) : !messageId && Object.keys(cernThread).length === 0 ? (
          <div
            className={`${styles.loaderContainer} d-flex justify-content-center align-items-center`}
          >
            <span className={styles.noCernMsg}>
              There are no messages in the Cern's thread
            </span>
          </div>
        ) : (
          <InfiniteScroll
            initialDataLoaded={true}
            // hasMoreBottom={hasNextPage || isLoading}
            hasMoreTop={hasNextPage || isLoading}
            // loadMoreFromBottom={!isLoading ? changePage : () => {}}
            loadMoreFromTop={!isLoading ? fetchNextPageHanlder : () => {}}
            className={`${styles.chatContainer} p-3`}
            root={scrollToBottomMsg}
            infiniteLoaderTop={
              <div
                className={`mb-2`}
                onClick={fetchNextPageHanlder}
              >
                <Spinner
                  size="sm"
                  animation="border"
                />
              </div>
            }
          >
            <div>
              {messageId &&
                messageList[messageId] &&
                !hasNextPage &&
                !isLoading && <InternalNote msg={messageList[messageId]} />}
              {Object.entries(cernThread).map(([msgId, msg]) => {
                if (
                  msg.messageType === "BotAiNote" &&
                  msg.escalateDetails?.isEscalateMsgExist &&
                  (msg.escalateDetails?.escalateMsgStatus === "pending" ||
                    msg.escalateDetails?.escalateMsgStatus === "paused" ||
                    msg.escalateDetails?.escalateMsgStatus === "cancelled")
                ) {
                  return (
                    <div key={msgId}>
                      <InternalNote msg={msg} />
                      <EditEscalation msg={msg} />
                    </div>
                  );
                } else if (
                  msg.messageType === "NoteBotAiQuery" ||
                  msg.messageType === "BotAiNote"
                ) {
                  return (
                    <InternalNote
                      msg={msg}
                      key={msgId}
                    />
                  );
                } else if (
                  msg.messageType === "NoteBotAiMissingDetails" ||
                  msg.messageType === "NoteBotAiOutOfScope"
                ) {
                  return (
                    <div key={msgId}>
                      <InternalNote msg={msg} />
                      <AvailableTask selectInstruction={setMessage} />
                    </div>
                  );
                } else if (msg.messageType === "BotAiEscalatedNote") {
                  return (
                    <InternalNote
                      msg={msg}
                      key={msgId}
                    />
                  );
                }

                return null;
              })}
              {isCernTyping.status &&
              messageId === isCernTyping.parentMessageId ? (
                <div
                  className={`d-flex justify-content-end mt-3 ${styles.cernTypingText}`}
                >
                  <div className={styles.loader}></div>
                  <span className="mx-2">Cern AI is Typing..</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </InfiniteScroll>
        )}

        <div className={`${styles.textEditorContainer} mt-auto px-2`}>
          <div className={`d-flex justify-content-end align-items-center mt-2`}>
            {/* <span className={`${styles.templetesBtn}`}>
              {" "}
              <ViewTemplateButtonV2
                selectedTemplate={(templateText: string) => {
                  setMessage((msg) => templateText + msg);
                }}
                showingWhichModal={showingWhichModal}
                setShowingWhichModal={setShowingWhichModal}
              />
            </span> */}
            <div className={`d-flex ms-3 p-1 ${styles.grayBox}`}>
              <div
                className={`d-flex justify-content-center align-items-centerpx-1 ${styles.active}`}
              >
                <div className={`px-1 ${styles.note} cursor-pointer`}>
                  <span className={`${styles.noteText} ${styles.activeText}`}>
                    +{" "}
                  </span>
                  {/* Conditionally display active note icon */}
                  <img
                    src={AddNoteActiveIcon}
                    className={`${styles.noteIcon}`}
                    alt="note"
                  />
                  <span className={`${styles.noteText} ${styles.activeText}`}>
                    {" "}
                    Internal note
                  </span>
                </div>
                <div
                  className={`${styles.note}  ${styles.cernText} ms-2 d-flex align-items-center cursor-pointer`}
                >
                  <img
                    src={saufter}
                    className={`${styles.noteIcon} me-1`}
                    alt="note"
                  />
                  <span>Cern</span>
                </div>
              </div>
            </div>
          </div>
          <TinyMCEContainer
            name="message"
            tinymceEditorRef={editorRef}
            canDeleteInline={true}
            className={`${styles.textEditor} ${err && "border-danger"}`}
            value={messageRef.current}
            mentionsEnabled={false}
            customAutoCompleteOptions={customAutoCompleteOptions}
            onChange={(value) => {
              setMessage(value);
              setErr(false);
            }}
            options={{
              placeholder: "Please type a message....",
              max_height: 250,
              min_height: 110,
            }}
            // enable the attachment picker
            disableAttachment={false}
            attachmentUploadData={attachmentUploadData}
            setAttachmentUploadData={setAttachmentUploadData}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            currentAttachmentBatchNumber={currentAttachmentBatchNumber}
            setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
            isFileUploading={isFileUploading}
            setIsFileUploading={setIsFileUploading}
            areAttachmentsPublic={true}
            setCurrentEditorObj={setEditorObj}
            uniqueID={uuid()}
            buttonContainerClass={`${styles.sendMessageBtn}`}
            buttons={
              <div className={`d-flex me-2 ps-1`}>
                <Button
                  type="submit"
                  className={`d-flex align-items-center my-auto mx-1 ${styles.sendBtn}`}
                  disabled={sendMsgLoading || isLoadingCernSend}
                  onClick={sendMessage}
                >
                  <span className={`my-auto ms-1`}>Send</span>
                  {sendMsgLoading || isLoadingCernSend ? (
                    <Spinner
                      className="ms-1"
                      size="sm"
                    />
                  ) : (
                    <img
                      src={sendImg}
                      alt=""
                      className={`my-auto ${styles.sendImg}`}
                    />
                  )}
                </Button>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CernThread;
