import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./AttributeDetailExpanded.module.scss";
import { TimelineEvent } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

const AttributeDetailExpanded = ({
  event,
  majarEventExist,
  position,
}: {
  event: TimelineEvent & {
    type: "minor" | "major";
    attribute?: TimelineEvent;
  };
  majarEventExist?: boolean;
  position?: "top" | "bottom";
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  if (!event.attribute) {
    return null;
  }
  const subEvents = event.attribute.details[0].subEvents;

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="right"
      show={showTooltip}
      onToggle={(nextShow) => setShowTooltip(nextShow)}
      overlay={
        <Tooltip
          className={`${styles.toolTipCustom}`}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {event.attribute.details[0].subEvents.map((attributeData, index) => {
            return (
              <div
                key={index}
                className={`${styles.overlay} px-2 pt-1`}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className={`d-flex align-items-center justify-content-center ${styles.stageImg}`}
                    >
                      <span className={`${styles.leftText}`}>U</span>
                      <div className="d-flex flex-column justify-content-center">
                        <span className={`${styles.rightText}`}>
                          {attributeData.name[0] || "n"}
                        </span>
                        <span className={`${styles.rightText}`}>
                          {attributeData.id || 1}
                        </span>
                      </div>
                    </div>
                    <span className={`ps-1 ${styles.stageHead}`}>
                      {attributeData.name}
                    </span>
                  </div>
                  <p className={`mb-0 ${styles.stageDescription}`}>
                    {attributeData.description}
                  </p>
                </div>
              </div>
            );
          })}
        </Tooltip>
      }
    >
      <div
        className={`d-flex flex-column aling-items-center ${
          styles.expandedStageBox
        } ${majarEventExist ? styles.expandedMarginTop : ""} ${
          position === "bottom" ? styles.bottom : ""
        }`}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div
          className={`d-flex align-items-center justify-content-center mx-auto ${styles.stageImg}`}
        >
          <span className={`${styles.leftText}`}>U</span>
          <div className="d-flex flex-column">
            <span className={`${styles.rightText}`}>
              {subEvents[0].name[0] ?? "n"}
            </span>
            <span className={`${styles.rightText}`}>
              {subEvents[0].id ?? 1}
            </span>
          </div>
        </div>
        {subEvents && subEvents.length > 1 ? (
          <>
            <span className={`${styles.separator}`}></span>
            <div
              className={`d-flex align-items-center justify-content-center mx-auto ${styles.stageImg}`}
            >
              <span className={`${styles.leftText}`}>U</span>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span className={`${styles.rightText}`}>
                  {subEvents[1].name[0] ?? "n"}
                </span>
                <span className={`${styles.rightText}`}>
                  {subEvents[1].id ?? 1}
                </span>
              </div>
            </div>
          </>
        ) : null}
        {subEvents && subEvents.length > 2 ? (
          <>
            <span className={`${styles.separator}`}></span>
            <span className={`${styles.remainingCount}`}>{`+${
              subEvents.length - 2
            }`}</span>
          </>
        ) : null}
      </div>
    </OverlayTrigger>
  );
};

export default AttributeDetailExpanded;
