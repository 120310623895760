import { Editor } from "@tinymce/tinymce-react";
import { Editor as IEditor, EditorOptions, RawEditorOptions } from "tinymce";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";
// DOM model
import "tinymce/models/dom/model";
// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/directionality";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/image";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/preview";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";
// import 'tinymce/plugins/paste';

// importing plugin resources
import "tinymce/plugins/emoticons/js/emojis";

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TinyMCEEditorProps } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import styles from "./TinyMCEEditor.module.css";
import ParseHTML from "src/components/ParseHTML";
import { renderToString } from "react-dom/server";
import { hydrate } from "react-dom";
import useEmailThreadHandler from "src/hooks/TinyMCEContainer/useEmailThreadHandler";
import useEmailSignatureHandler from "src/hooks/TinyMCEContainer/useEmailSignatureHandler";
import { showAttachment } from "src/services/Attachment/showAttachment";
import { createRoot } from "react-dom/client";
import useAutoCompleteHandler from "src/hooks/TinyMCEContainer/useAutoCompleteHandler";
import useMentionsHandler from "src/hooks/TinyMCEContainer/useMentionsHandler";
import {
  findScrollContainer,
  removeClassNames,
  replaceURLWithAnchorTag,
} from "src/utils/utils";
import useSaveDraft from "src/hooks/TinyMCEContainer/useSaveDraft";
import "../../Plugins/CustomAutocomplete";

export const deleteTinyMCEDraft = (autosavePrefix: string) => {
  window.localStorage.removeItem(autosavePrefix + "draft");
  window.localStorage.removeItem(autosavePrefix + "time");
};

const ShowThread = ({
  text = "",
  editor,
}: {
  text: string;
  editor: React.MutableRefObject<IEditor | null>;
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const textArea = editor.current?.getContentAreaContainer();
    if (textArea && show) {
      textArea.scrollTop += 15;
    }
  }, [show]);

  return (
    <div className="d-flex flex-column py-0 mb-1 mx-3">
      <div className="d-flex">
        <div
          className={`me-0 my-auto d-flex p-1 ${styles.tdbg} ${
            show ? "" : styles.hidden
          }`}
          role="button"
          onClick={() => {
            setShow((value) => !value);
          }}
        >
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
      {show ? (
        <ParseHTML
          className={`mt-2 ${styles.threadData}`}
          html={text}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
const EMAIL_THREAD_CLASSNAME = "ticket-email-thread";

//main tinyMCE editor component
export function TinyMCEEditor({
  name = "editor", //{form name} of the textarea
  options = {}, //editor settings for overiding default
  value, //will contain the value of editor inner html
  onChange, //this method will be called on change of editor content
  showToolbar = true, //by default toolbar will be shown, using this prop it can be overwritten
  uniqueID,
  attachmentUploadData,
  threadData, //email thread data
  sendThread = false,
  setSendThread = (value) => {},
  showThread = false,
  setShowThread = (value) => {},
  editorRef, //useRef for storing the instance of editor, which can be used later
  inline = false, //which toggles editor more "inline" | "standard"
  toolbar = "emoticons bold italic underline link bullist numlist removeformat", //default toolbar buttons
  onDrop = (event: any, editor: any) => {
    //preventing the browser moving to separate page on file drop
    event.preventDefault();
  },
  signatureText = "",
  readonly, //boolean flag to set editor read only
  editorClassName = "",
  className = "",
  hidePlusIcon = false,
  onFocus,
  onBlur,
  autoCompleteMenuConfigs,
  autosavePrefix,
  mentionsEnabled = false,
  setSigEdited,
  sigEdited = false,
  onDragOver = (event: any, editor: any) => {
    event.preventDefault();
  },
  customAutoCompleteOptions,
  setCurrentEditorObj,
}: TinyMCEEditorProps) {
  const config = useMemo(() => {
    const configRet: RawEditorOptions = {
      ...defaultConfig, //adding the default config to editor config variable
      ...options, //overring the default config if any given
      skin: false,
      content_css: false,
      content_style: [mceIframeStyles, contentCss, contentUiCss].join("\n"),
      quickbars_insert_toolbar: false,
      mentions: customAutoCompleteOptions,
    };
    // if (autosavePrefix && typeof configRet.plugins === "object") {
    //   configRet.plugins.push("autosave");
    //   configRet.autosave_prefix = autosavePrefix;
    // }
    return configRet;
  }, [options, customAutoCompleteOptions]);
  const [editorObj, setEditorObj] = useState<IEditor>();

  useEffect(() => {
    if (editorRef.current) {
      if (customAutoCompleteOptions && customAutoCompleteOptions?.delimiters) {
        editorRef.current.fire("ToggleCustomMentionPlugin", {
          enabled: true,
          autoCompleteData: customAutoCompleteOptions,
        });
      } else {
        editorRef.current.fire("ToggleCustomMentionPlugin", {
          enabled: false,
          autoCompleteData: customAutoCompleteOptions,
        });
      }
    }
  }, [editorObj, customAutoCompleteOptions]);

  useSaveDraft({ autosavePrefix, value, onChange });

  const { handleEditorInit } = useEmailSignatureHandler({
    signatureText: signatureText,
    setSigEdited,
    sigEdited,
    autosavePrefix,
  });

  // Feb 20 2023 commenting the email thread support as it was not completed
  const { threadText } = useEmailThreadHandler({ editorRef, threadData });

  const currentState = useRef({ value: value });

  useMemo(() => {
    currentState.current.value = value;
  }, [value]);

  const autoCompleteHandler = useAutoCompleteHandler({
    editorRef,
    autoCompleteMenuConfigs,
    styleClassName: styles.autoCompleteDiv,
  });

  const mentionNULL = useMentionsHandler({
    editorRef,
    classNameProfileImg: styles.mceMentionsProfileImg,
    mentionsEnabled,
  });

  const tinyMCE = useCallback(() => {
    return tinymce.init({});
  }, []);

  useEffect(() => {
    //Note: This code is required because Bootstrap blocks all focusin calls from elements outside the dialog.
    const handler = (e: any) => {
      if (
        e.target.closest(
          ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root",
        ) !== null
      ) {
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener("focusin", handler);
    return () => document.removeEventListener("focusin", handler);
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      const container = editorRef.current.getContainer();
      const threadElement = container?.getElementsByClassName(
        EMAIL_THREAD_CLASSNAME,
      );

      // Create a separate element to render the React component into
      const reactContainer = document.createElement("div");

      if (threadElement && threadElement.length) {
        if (threadText.trim().length === 0) {
          threadElement[0].innerHTML = "";
        } else {
          reactContainer.className = EMAIL_THREAD_CLASSNAME;
          // Render the React component into the separate element
          const root = createRoot(reactContainer);
          root.render(
            <ShowThread
              text={threadText}
              editor={editorRef}
            />,
          );

          // Insert the resulting DOM element into the editor
          threadElement[0].innerHTML = "";
          threadElement[0].appendChild(reactContainer);
        }
      } else {
        if (threadText.trim().length) {
          const textArea = editorRef.current?.getContentAreaContainer();
          if (textArea && textArea.parentElement) {
            const root = createRoot(reactContainer);
            // Render the React component into the separate element
            root.render(
              <ShowThread
                text={threadText}
                editor={editorRef}
              />,
            );

            // Create a new element to hold the React component and insert it after the editor
            const threadContainer = document.createElement("div");
            threadContainer.className = EMAIL_THREAD_CLASSNAME;
            threadContainer.appendChild(reactContainer);
            textArea?.appendChild(threadContainer);
          }
        }
      }
    }
  }, [editorRef.current, threadText]);

  //useEffect to render private attachments
  useEffect(() => {
    if (editorObj && editorObj.getDoc() !== undefined) {
      if (
        currentState.current.value.trim() &&
        Array.isArray(attachmentUploadData)
      ) {
        editorObj.getBody().innerHTML = showAttachment({
          html: currentState.current.value,
          root: editorObj.getDoc(),
        });
      }
    }
  }, [editorObj, attachmentUploadData]);

  //resizing editor
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.execCommand("mceAutoResize");
    }
  }, [options.max_height, options.min_height, config.placeholder]);

  useEffect(() => {
    const resizeHandler = () => {
      if (editorRef.current) {
        editorRef.current.execCommand("mceAutoResize");
      }
    };
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [false]);

  useEffect(() => {
    if (
      currentState.current.value.trim() === "" &&
      editorRef.current &&
      config.placeholder?.trim()
    ) {
      editorRef.current.contentDocument?.body.setAttribute(
        "data-mce-placeholder",
        config.placeholder,
      );
    }
  }, [config]);

  return (
    <div className={`${className}`}>
      <div className={`${editorClassName}`}>
        <Editor
          textareaName={name}
          onInit={(evt, editor) => {
            editorRef.current = editor;
            setEditorObj(editor);
            if (setCurrentEditorObj){
              setCurrentEditorObj(editor);
            }
            handleEditorInit(editor);
            editor.getContainer().classList.add(styles.tinymceContainerDiv);
            if (!hidePlusIcon) {
              // Get the toolbar element
              let toolbar: any = editor
                .getContainer()
                .querySelector(".tox-toolbar__group");

              if (toolbar) {
                //adding space for + button
                toolbar.childNodes[0].style.marginLeft = "25px";
              }
            } //if (!hidePlusIcon) {

            // Add custom class to toolbar container element
            const toolbarElem = editorRef.current
              .getContainer()
              .querySelector(".tox-editor-header");
            if (toolbarElem) {
              toolbarElem.classList.add(styles.toolbarBg);
              const toolbarPrimary = editorRef.current
                .getContainer()
                .querySelector(".tox-toolbar__primary");
              if (toolbarPrimary) {
                toolbarPrimary.classList.add(styles.toolbarBg);
              }
              const toolbarGroup = editorRef.current
                .getContainer()
                .querySelector(".tox-toolbar-overlord");

              if (toolbarGroup) {
                toolbarGroup.classList.add(styles.toolbarBg);
              }
            } // if (toolbarElem) {

            //get container
            const textArea = editor.getContentAreaContainer();
            if (textArea) {
              textArea.style.display = "block";
              textArea.style.flex = "unset";
              textArea.style.overflow = "auto";
              textArea.style.height = "100%";
              textArea.style.width = "100%";
              textArea.style.minHeight = "100%";
              textArea.style.maxHeight = "100%";
              textArea.style.minWidth = "100%";
              textArea.style.maxWidth = "100%";
              textArea.addEventListener("click", (e: any) => {
                const ifr = e.currentTarget?.querySelector("iframe");
                if (ifr?.contentDocument) {
                  ifr.contentDocument.querySelector("body").focus();
                }
              });
              //get container
              const iframe = textArea.querySelector("iframe");
              // console.log(iframe?.style)
              if (iframe) {
                iframe.style.backgroundColor = "transparent";
                if (iframe.contentWindow) {
                  const observer = new MutationObserver(
                    (mutationsList, observer) => {
                      if (iframe.contentWindow) {
                        iframe.style.height =
                          iframe.contentWindow.document.body.scrollHeight +
                          16 +
                          "px";
                        // iframe.style.width =
                        //   iframe.contentWindow.document.body.scrollWidth + "px";
                      }
                    },
                  );
                  observer.observe(iframe.contentWindow.document.body, {
                    attributes: true,
                    childList: true,
                    subtree: true,
                  });
                  iframe.contentWindow.document.body.style.backgroundColor =
                    "transparent";
                  iframe.setAttribute("scrolling", "no");
                  iframe.setAttribute("frameborder", "0");
                  // iframe.style.minHeight = "calc(100% - 15px)";
                  iframe.style.minWidth = "100%";
                  iframe.style.position = "static";
                  iframe.contentWindow.document.body.style.marginBottom = "0px";
                  iframe.style.height =
                    iframe.contentWindow.document.body.scrollHeight + 16 + "px";
                  // iframe.style.width =
                  //   iframe.contentWindow.document.body.scrollWidth + "px";
                }
              }
            } //if (textArea) {
            editor.on("ScrollIntoView", function (e) {
              //preventing the default scroll behaviour
              e.preventDefault();
            });
            editor.on("keydown", (e: any) => {
              const selection = editor.contentWindow.getSelection();
              const nodePoint: any = selection?.anchorNode;
              const ele = nodePoint?.parentElement;
              if (editorRef.current && config.placeholder?.trim()) {
                if (e.key?.length === 1) {
                  editorRef.current.contentDocument.body.setAttribute(
                    "data-mce-placeholder",
                    "",
                  );
                } else if (
                  e.currentTarget.innerHTML.trim() === "" ||
                  e.currentTarget.innerHTML.trim() ===
                    `<p><br data-mce-bogus="1"></p>` ||
                  e.currentTarget.innerHTML.trim() === `<p><br></p>`
                ) {
                  editorRef.current.contentDocument.body.setAttribute(
                    "data-mce-placeholder",
                    config.placeholder,
                  );
                } else {
                  editorRef.current.contentDocument.body.setAttribute(
                    "data-mce-placeholder",
                    "",
                  );
                }
              }
              if (e.key === "Enter") {
                const textArea = editor.getContentAreaContainer();
                const nodeArea = nodePoint?.tagName ? nodePoint : ele;
                const nodeOffset =
                  (nodeArea?.offsetTop ?? 0) + (nodeArea?.offsetHeight ?? 0);
                if (
                  textArea.scrollTop < nodeOffset - textArea.offsetHeight &&
                  nodeOffset > textArea.offsetHeight
                ) {
                  textArea.scrollTop = nodeOffset - textArea.offsetHeight;
                }
              }
              const sigEle = ele?.closest(".email-signature");
              if (
                sigEle &&
                ele &&
                nodePoint &&
                selection?.toString().length === 0
              ) {
                let firstBR = sigEle.firstElementChild;
                let insertedOnTop = false;
                if (e.key === "Enter" && firstBR) {
                  for (let i = 0; i < 5; i++) {
                    if (firstBR) {
                      if (firstBR.tagName === "BR") {
                        if (sigEle && firstBR.parentElement?.parentElement) {
                          firstBR.parentElement.parentElement.removeChild(
                            firstBR.parentElement,
                          );
                          sigEle.insertAdjacentHTML(
                            "beforebegin",
                            `<p><br data-mce-bogus="1"></p>`,
                          );
                          insertedOnTop = true;
                        }
                        break;
                      } else if (firstBR.firstElementChild) {
                        firstBR = firstBR.firstElementChild;
                      } else {
                        break;
                      }
                    } else {
                      break;
                    }
                  }
                  if (
                    sigEle.previousElementSibling?.classList.contains(
                      "email-signature",
                    )
                  ) {
                    sigEle.previousElementSibling?.classList.remove(
                      "email-signature",
                    );
                  }
                }
                if (!insertedOnTop && setSigEdited) {
                  setSigEdited(true);
                }
              }
            });
            //Code for inline toolbar for links
            editor.ui.registry.addContextToolbar("link", {
              predicate: (node) =>
                node.nodeName.toLowerCase() === "a" &&
                editor.selection.getContent().trim() === "",
              items: "quicklink",
              position: "node",
              scope: "node",
            });
            //Code for inline toolbar for images
            editor.ui.registry.addContextToolbar("imagealignment", {
              predicate: (node) => node.nodeName.toLowerCase() === "img",
              items: "alignleft aligncenter alignright",
              position: "node",
              scope: "node",
            });
            //Code for inline toolbar for text selection
            editor.ui.registry.addContextToolbar("textselection", {
              predicate: (node) =>
                !editor.selection.isCollapsed() &&
                node.nodeName.toLowerCase() != "img",
              items: "bold italic underline forecolor backcolor quicklink",
              position: "selection",
              scope: "node",
            });
          }} // onInit={(evt, editor) => {
          toolbar={showToolbar === false ? false : toolbar}
          inline={inline}
          value={value}
          onEditorChange={(newValue, editor) => {
            if (!newValue.includes(`class="email-signature"`) && setSigEdited) {
              setSigEdited(false);
            }
            onChange(newValue);
          }} //onEditorChange={(newValue, editor) => {
          init={config as any}
          onDrop={onDrop}
          onDragOver={onDragOver}
          disabled={readonly}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
}

const defaultConfig: RawEditorOptions = {
  toolbar_location: "bottom", // toolbar position default position
  menubar: false, //disabling the "file", "edit", etc menu's
  statusbar: false, //disabling the statusbar which includes the elementpath, wordcount and editor resize.
  plugins: [
    "emoticons", //emoji
    "lists", //ul ol tags
    "link", //embbed link
    "image", //embbed image
    "table",
    "autolink", // automatically creates hyperlinks when user types www.example.com it converts to http://www.example.com.
    "autoresize", //this plugin allows you set max_height to editor
    "media", //embbed media
    "visualblocks", //which will show border on each element if you set visualblocks_default_state: true
    "wordcount",
    "fullscreen",
    "mention",
  ],
  autosave_interval: "20s",
  autosave_ask_before_unload: true,
  autosave_restore_when_empty: true,
  autosave_retention: "10080m",
  placeholder: "Enter your message here....", //default placeholder
  paste_data_images: true, //This option specifies whether data:url images (inline images) should be removed or not from the pasted contents.
  object_resizing: true, //turn on/off the resizing handles on images, tables or media objects.
  min_height: 120, //defualt max editor height
  max_height: 300, //default max editor height
  // forced_root_block: "div", //defult p tag, forcing the editor to use div instead
  convert_urls: false, //disabling convert urls as we don't need to trancate image url's domain name on save
  relative_urls: false, //disabling the relative urls , it is can be used for URLs with the same domain but we may have multiple domain urls
  link_default_target: "_blank", //by default link will open in new tab
  remove_script_host: false,
  automatic_uploads: true, //
  //by default tinymce removes elements artibutes by default set of rules, using this property we can add the allowed attributes
  extended_valid_elements:
    "img[content-id|!src|border:0|alt|title|width|height|style|public-img],video[data-id|border:0|alt|title|width|height|style|controls]",
  block_unsupported_drop: false, //by default image only allowed on drop, forcing it to allow all the files and filter it on handler
  media_live_embeds: true, //which lets users media on editor itself
  // height: "100%",
  autoresize_bottom_margin: 20,
  color_default_background: "transparent",
  text_patterns: [
    //keeping the default text pattern array here and disabling the # which intruputs canned response in editor prefix
    { start: "*", end: "*", format: "italic" },
    { start: "**", end: "**", format: "bold" },
    // { start: '#', format: 'h1' },// format: 'h1' disabling this prefix as it is used in canned response
    { start: "##", format: "h2" },
    { start: "###", format: "h3" },
    { start: "####", format: "h4" },
    { start: "#####", format: "h5" },
    { start: "######", format: "h6" },
    // The following text patterns require the `lists` plugin
    { start: "1. ", cmd: "InsertOrderedList" },
    { start: "* ", cmd: "InsertUnorderedList" },
    { start: "- ", cmd: "InsertUnorderedList" },
  ],
  paste_preprocess: function (editor, args) {
    args.content = removeClassNames(replaceURLWithAnchorTag(args.content));
  },
  table_default_styles: {
    border: "1px solid black",
    width: "100%",
  },
  table_cell_styles: {
    border: "1px solid black",
  },
  // contextmenu: "copy paste link",
  contextmenu: "",
  formats: {
    // Changes the default format for h1 to have a class of heading
    p: {
      block: "p",
      classes: "hl-p-tag",
      styles: { margin: "0", padding: "0" },
    },
  },
  images_file_types: "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg", //added default and additionally svg added to image file type support, got the default value from https://www.tiny.cloud/docs/tinymce/latest/file-image-upload/#images_file_types
};

const mceIframeStyles = `
/* Scrollbar override */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  color: #707070;
  font-family: "Poppins", "Roboto", sans-serif;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0px;
  tab-size: 4;
  font-stretch: 100%;
}

a {
  color: rgb(0, 0, 238);
}

*:hover {
  scrollbar-color: #ff849988 rgba(145, 145, 145, 0.5);
}

*::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.5rem;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
  outline: none;
}

*:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(145, 145, 145, 0.5);
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #ff849988;
}

p {
  margin:0;
  padding:0;
}

.quill-mention{
  color: #0B68BB !important;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  cursor: text;
}

`;
