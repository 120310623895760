import styles from "./DesktopView.module.scss";
import logo from "src/assets/images/dummyLogo.png";
import discountCode from "src/assets/images/discountCode.png";
import orderImg from "src/assets/images/order2.png";
import imgUpload from "src/assets/images/imgUpload.png";
const DesktopView = () => {
  const text = `{26/05/2024}`;
  const info = `{1 business days after order placed}`;
  return (
    <div className="">
      <div className={`text-center p-3 ${styles.headerWrapper}`}>
        <img
          src={imgUpload}
          alt="logo"
          width={50}
          height={50}
          className="mx-auto text-center"
        />
        <h4 className={`mt-2 ${styles.heading}`}>Heading</h4>
      </div>
      <div className={`${styles.mailPreview}`}>
        <p className={`${styles.mailText}`}>Dear John,</p>
        <p className={`${styles.mailText}`}>
          we’re continuously working to improve your experience and bring you
          the best service possible. We have some exciting updates and important
          information to share with you:
        </p>
        <p className={`${styles.mailText}`}>
          <span className="fw-bold">Announcement:</span> We’ve introduced. This
          will help you benefit and enhance your overall experience.
        </p>
        <p className={`${styles.mailText}`}>
          <span className="fw-bold">Upcoming Event: </span>Don’t miss out on our
          upcoming, starting from 22nd September. We’ve lined up just for you.
        </p>
        <p className={`${styles.mailText}`}>
          <span className="fw-bold">Customer Support Information:</span>
          Should you have any questions, our support team is here to assist you
          24/7. You can reach us at support@saufter.com
        </p>
        <p className={`${styles.mailText}`}>
          Best Regards,
          <br />
          Team Acme
        </p>
      </div>
      <div>
        <hr />
      </div>
      <div className={`text-center ${styles.footer}`}>
        <p className={`mb-2 ${styles.footerText}`}>
          To stop receiving Update emails.
        </p>
        <p className={`mb-0 ${styles.footerText}`}>
          Copyright © 2024 "Company name" <br /> All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default DesktopView;
