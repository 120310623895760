import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./CampaignBox.module.scss";
import editIcon from "src/assets/images/editIcon.png";
import merge from "src/assets/images/merge.png";
import SendingOption from "../SendingOption/SendingOption";

interface CampaignBoxProps {
  description: string;
  header: string;
  triggerAfter: number;
  handleCampaignClick: Function;
  channel: string;
}

const CampaignBox: React.FC<CampaignBoxProps> = ({
  triggerAfter,
  header,
  description,
  channel,
  handleCampaignClick,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableDay, setEditableDay] = useState(triggerAfter);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Use a regular expression to allow only numbers
    const numericValue = value.replace(/[^0-9]/g, "");
    if (numericValue === "NaN" || !numericValue) {
      setEditableDay(0);
    } else {
      setEditableDay(parseInt(numericValue));
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className={`mb-3 ${styles.campaignBox}`}>
      <div className="d-flex justify-content-start w-100">
        <div className={`d-flex me-1 ${styles.dayWrap}`}>
          <span className={`pe-1 ${styles.dayText}`}>Day</span>
          {isEditing ? (
            <div className={`position-relative`}>
              <input
                type="text"
                value={editableDay}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className={`px-1 ${styles.inputChange}`}
                autoFocus
              />
              <div className={`${styles.checkBox}`}>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
            </div>
          ) : (
            <span className="">{editableDay}</span>
          )}
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleEditClick();
            }}
            className={`ps-1 cursor-pointer ${
              isEditing ? "d-none" : "d-block"
            }`}
          >
            <img
              src={editIcon}
              alt="edit"
              width={12}
            />
          </span>
        </div>
        <div
          className={`me-1 pt-2 ${styles.msgWrap}`}
          onClick={() => handleCampaignClick()}
        >
          <h6 className={`${styles.msgHead}`}>{header}</h6>
          <p className={`${styles.msgSDesc}`}>{description}</p>
        </div>
        <div className="d-flex me-1 d-none">
          <span className={`me-1`}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className={` ${styles.toolTipCustom}`}>Snooze</Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  {...triggerHandler}
                  ref={ref}
                  className={`${styles.snoozeImg}`}
                >
                  <i className={`material-icons  ${false ? styles.blue : ""}`}>
                    &#xe046;
                  </i>
                </div>
              )}
            </OverlayTrigger>
          </span>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className={`override-white ${styles.toolTipCustom}`}>
                Merge Other tickets here
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <div
                className={`${styles.mergeIconSpan} ms-0 me-0`}
                {...triggerHandler}
                ref={ref}
              >
                <img
                  src={merge}
                  className={`${styles.mergeIcon}`}
                  alt="merge"
                  width={12}
                  height={15}
                />
              </div>
            )}
          </OverlayTrigger>
        </div>
        <div className={`${styles.sendingOption}`}>
          <SendingOption value={channel} />
        </div>
      </div>
    </div>
  );
};

export default CampaignBox;
